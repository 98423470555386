import './authenticator-verification-style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react';
import FormControl from '../FormControls';
import StoreIcon from '../../assets/images/app_store.png'
import CheckIcon from '@mui/icons-material/Check';
import labels from '../../config/localization';
import Notification from '../Notification';
import Loader from '../Loader';
import googleAuthQR from  '../../assets/images/google_auth_qr.png'

const ByteCodeImage = ({ byteCode }) => {
  // Assuming byteCode is a base64 string
  const src = `data:image/jpeg;base64,${byteCode}`;

  return <img src={src} alt="Byte Code Image" className="block w-40 h-40 object-contain" />;
};
const VerifyQR = (props) => {
  return (
    <div id="myModal" className="modal fade show" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pl-4">
            <h4 className="modal-title">{labels.user_labels.connect_your_auth_app}</h4>
            <button type="button" className="close" onClick={ () => props.onClose()}>
              &times;
            </button>
          </div>
          <div className="modal-body pl-2 pb-4">
            <div className='col-12 no-gutters'>
              <div className='label-1'>{labels.user_labels.use_authenticator_app}</div>
              <button className='btn link' onClick={() => props.onDownloadApp()}> 
                  {labels.user_labels.dont_have_app}
              </button>
            </div>
            <div className="d-flex justify-center pt-4 pb-4">
              { props.qrcodeDTO && (
                <ByteCodeImage byteCode={props.qrcodeDTO && props.qrcodeDTO.qrImageData ? props.qrcodeDTO.qrImageData : '' } />
              )}
            </div> 
            <div className="d-flex justify-center ">
              <button className='btn link p-0' onClick={()=> props.onEnterManually()}>  {labels.user_labels.enter_the_code_manually}</button>
            </div>
  
          </div>
          <div className="modal-footer">
            <button type="button" className="btn cancle-btn" onClick={() =>{ props.onDone()}}>
            {labels.user_labels.cancel}
            </button>
            <button type="button" className="btn btn-primary" onClick={() =>{ props.onNext()}}>
            {labels.user_labels.next}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ManuallyCodeEnter = (props) => {
  return (
    <div id="myModal" className="modal fade show" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pl-4">
            <h4 className="modal-title">{labels.user_labels.connect_your_auth_app}</h4>
            <button type="button" className="close" onClick={ () => props.onClose()}>
              &times;
            </button>
          </div>
          <div className="modal-body pl-2 pb-4">
            <div className='col-12 no-gutters'>
              <div className='label-1'> {labels.user_labels.open_your_auth_app_select_to_manually}</div>
              <button className='btn link' onClick={() => props.onDownloadApp()}> 
                {labels.user_labels.dont_have_app}
              </button>
            </div>
            <div className="pt-4 pb-4 col-12 no-gutters">
              <div className='label-2 pb-2'> 
                {labels.user_labels.enter_your_full_email_address_as_your}
              </div>
              <FormControl
                    type='text'
                    className='form-control col-10'
                    value={props.qrcodeDTO && props.qrcodeDTO.secret ? props.qrcodeDTO.secret : ''} 
                    disabled={true}
                />

            </div> 
            <div className="d-flex justify-center ">
              <button className='btn link p-0' onClick={()=> props.onScanQR()}> {labels.user_labels.scan_qr_code_insted}</button>
            </div>
  
          </div>
          <div className="modal-footer">
            <button type="button" className="btn cancle-btn" onClick={() =>{ props.onDone()}}>
            {labels.user_labels.cancel}
            </button>
            <button type="button" className="btn btn-primary" onClick={() =>{ props.onNext()}}>
            {labels.user_labels.next}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DownloadApp = (props) => {
  return (
    <div id="myModal" className="modal fade show" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pl-4">
            <h4 className="modal-title"> {labels.user_labels.get_the_google_auth_app}</h4>
            <button type="button" className="close" onClick={ () => props.onClose()}>
              &times;
            </button>
          </div>
          <div className="modal-body pl-2 pb-4">
            <div className='col-12 no-gutters'>
              <div className='label-1'> {labels.user_labels.download_the_google_auth_app_by_scanning}</div>
            </div>
            <div className="pt-5 pb-5 col-12 no-gutters d-flex">
              <div className='col-6 justify-content-center d-flex'>
              <img
                  className="block w-60 object-contain"
                  src={googleAuthQR}
                  alt="qrcode url"
              />
              </div>
              <div className='col-6 justify-content-center d-flex'>
                <img  className="block w-60  object-contain"
                  src={StoreIcon}
                  alt="Google store and Apple store"
                 />
              </div>

            </div>
  
          </div>
        </div>
      </div>
    </div>
  );
};

const InterOpt = (props) => {
  let inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []); 
  return (
    <div id="myModal" className="modal fade show" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pl-4">
            <h4 className="modal-title">{labels.user_labels.two_factor_auth_required}</h4>
            <button type="button" className="close" onClick={ () => props.onClose()}>
              &times;
            </button>
          </div>
          <div className="modal-body pl-2 pb-4">
              <div className='col-12 no-gutters pb-4'>
                <div className='label-1'> {labels.user_labels.enter_the_code_gen_by_auth} </div>
              </div>

              <div className='col-12 no-gutters'>
                  {props.isNotify && props.notifications.title == 'VerifyOTP' && <div className="col-8"><Notification {...props.notifications} /> </div>}
                  <div>
                    <input
                        ref={inputRef}
                        autoComplete="on"
                        type='text'
                        className='form-control col-8'
                        id='otp'
                        placeholder={'Enter the code'}
                        value={props.otp} 
                        onChange={(e) => { props.setOTP(e.target.value); props.toggleNotification(false)}}
                        required={true}
                    />
                  </div>
              </div>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button type="button" className="btn cancle-btn" onClick={ () => { props.onPrevious(); props.toggleNotification(false)}}>
              {labels.user_labels.previous}
            </button>
            <button type="button" className="btn btn-primary" onClick={() => props.onValidateOTP()}>
              {labels.user_labels.done}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Success = (props) => {
  return (
    <div id="myModal" className="modal fade show" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pl-4">
            {/* <button type="button" className="close" onClick={ () => props.onClose()}>
              &times;
            </button> */}
          </div>
          <div className="modal-body">
            <div className="pt-5 pb-5 col-12 no-gutters d-flex justify-content-center align-items-center">
                <div className='text-center'>
                    <CheckIcon sx={{
                          color: 'green',
                          fontSize: '50px',
                          paddingBottom: '10px'
                        }} />
                    <div className='label-3'>
                    {labels.user_labels.success_auth_app}
                    </div>  
                </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={() =>{ props.handleSuceessDone(); props.onDone()}}>
            {labels.user_labels.done}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const AuthenticatorVerification = (props) => {
  const [step, setStep] = useState(0);
  const [isEnterManually, setIsEnterManually] = useState(false);
  const [isScanQR, setIsScanQR] = useState(true);
  const [isDownloadApp, setIsDownloadApp] = useState(false);
  const [otp, setOTP] = useState('');
  
  useEffect(() => {
    props.toggleNotification(false)
    const keyPressHandler = (event) => handleKeyPress(event, step);
    document.addEventListener('keydown', keyPressHandler);
    return () => { document.removeEventListener('keydown', keyPressHandler)}
  }, [step, otp]);

  const handleKeyPress = (event, step) => {
    if (event.keyCode === 13 && step == 1) {
      onValidateOTP()
    }
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleEnterManually = () => {
    if (!isEnterManually) {
      setIsEnterManually(true);
      setIsScanQR(false);
    }
  };

  const handleScanQR = () => {
    if (!isScanQR) {
      setIsScanQR(true);
      setIsEnterManually(false);
    }
  };

  const handleDownloadApp = () => {
    if (!isDownloadApp) {
      setIsDownloadApp(true)
      setIsScanQR(false);
      setIsEnterManually(false);
    }
  };

  const onValidateOTP = () => {
    props.validateOTP('authenticator', otp, true, false)
  };


  return (
    <div className='verification'>
      {props.isLoading ? <Loader /> : null}
      {step === 0 && isScanQR && <VerifyQR {...props} onNext={handleNext} onEnterManually={handleEnterManually} onDownloadApp={handleDownloadApp}/>}
      {step === 0 && isEnterManually && <ManuallyCodeEnter {...props} onNext={handleNext} onScanQR={handleScanQR} onDownloadApp={handleDownloadApp}/>}
      {step === 0 && isDownloadApp && <DownloadApp {...props} />}
      {step === 1 && !props.verification && <InterOpt {...props} onPrevious={handlePrevious} setOTP={setOTP} otp={otp} onValidateOTP={onValidateOTP} />}
      {step === 1 && props.verification && <Success {...props} />}
    </div>
  );
};

export default AuthenticatorVerification;

//handleSuceessDone
