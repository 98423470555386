import axiosInstance from 'axios';
import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import moment from 'moment';
import _, { includes } from 'lodash';
import { toggleLoading, toggleNotification, setPageTitle, setBreadCrumbs, handleErrors, setLoggedInUser, setPatientReportStatus, handleStatusCodes, toggleReportLoading } from '../../../reducers/global-reducer';
import labels from '../../../config/localization';
import messages from '../../../config/messages';
import { datasetSelector, gpaSelector } from '../../Analytics/selectors/datasetSelector';
import constants from '../../../config/constants';
import reportStyles from '../components/ReportsStyles';
import logo from '../../../assets/images/physioage-report-logo.jpg';
import poweredByLogo from '../../../assets/images/powered_by_physioage_web.png';
//import baselineGraph from '../../../assets/images'
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import { isFirefox, b64toBlob, isIE, isSafari, getValidSafariDate, downloadExcel, downloadExcelImproved } from '../../../utils/download-utils'
import en_labels from '../../../config/en_labels';
import { getLocalizedDate } from '../../../utils/reports-date-localization';
import demoData from '../../Analytics/demoAnalyticsApi';
import { Typeahead } from 'react-bootstrap-typeahead';
import report_constants from '../../../config/reportConstants';
import { returnLocalizedDate } from '../../../utils/resolve-timer'
import { closeSideBar } from '../../Analytics/modules/analytics';
import { downloadCSV } from '../../../utils/download-csv-table';
import { IsInRange, getDateFormat, roundNumber, roundNumberFixedToOne } from '../../Analytics/tools/helpers';
import cloneDeep from 'lodash/cloneDeep';


const dateFormats = {
    dmy: 'D MMM YYYY',
    mdy: 'll',
    ymd: 'YYYY MMM D'
};
const visitDateFormat = {
    dmy: 'DD/MM/YYYY',
    mdy: 'MM/DD/YYYY',
    ymd: constants.dateFormats.ymd
};
//actions
const SET_PROPS = 'SET_PROPS';


function handleLoading() {
    return (dispatch, getState) => {
        let isResultsSet = getState().visits.isResultsSet
        let isResultsPage = getState().visits.isResultsPage
        if ((isResultsPage && isResultsSet) || !isResultsPage) {
            let allPromises = promisesList.filter(p => p)
            Promise.all(allPromises).then(function (values) {
                if (allPromises.length && allPromises.length == values.length) {
                    clearPromises()
                    dispatch(toggleLoading(false))
                }
            });
        }
    }
}
export function toggleVisitModal(modalMode, id) {
    return (dispatch, getState) => {
        if (modalMode == constants.popup_labels.addTest) {
            dispatch(hasActiveElk())
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    modalMode,
                    orderError: null
                }
            })
        }
        let order = getState().visits.ordersList.find((ol) => ol.id == id)
        dispatch({
            type: SET_PROPS,
            payload: {
                isVisitModal: getState() && getState().visits ? !getState().visits.isVisitModal : false,
                updateVisitError: null,
                deleteOrderId: (id != null) ? id : null,
                order_panelName: order && order.panelName ? order.panelName : ""

            }
        })


    }
}
export function updateVisit(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        if (getState().visits && getState().visits.selectedVisitDate) {
            let date = getState().visits.currentVisit.createdAt;
            let validateCreatedDate = moment(date).add(1, 'month').format(constants.dateFormats.ymd)
            let currentDate = moment(new Date()).format(constants.dateFormats.ymd)
            let validateDate = moment(new Date()).add(1, 'month').format(constants.dateFormats.ymd)
            var selectedVisitDate = moment(getState().visits.selectedVisitDate).format(constants.dateFormats.ymd)
            var updateVisitError
            if (validateCreatedDate > currentDate) {
                if (validateDate >= selectedVisitDate) {
                    let currentVisit = getState().visits.currentVisit
                    let visitId = currentVisit.id
                    let params = { Id: visitId, PatientId: currentVisit.patientId, Date: moment(getState().visits.selectedVisitDate).format('L') }
                    promisesList.push(axios.put(API_URL.VISIT_URL, { ...params }).then((response) => {
                        dispatch(handleLoading())
                        dispatch(toggleNotification(true, constants.notification_type.success, '',
                            [labels.visit_page_labels.visit_update_success]
                            , false))
                        history.push(`/visits/${visitId}`)
                        dispatch(toggleVisitModal())
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                selectedVisitDate: null
                            }
                        })
                        dispatch(setCurrentVisit(visitId, history))
                    }).catch(errors => {
                        let visitError = errors.response && errors.response.data ? errors.response.data : ''
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                updateVisitError: visitError ? typeof (visitError) == 'string' ? visitError : visitError.value ? visitError.value : null : null,
                                selectedVisitDate: null
                            }
                        })
                        dispatch(toggleLoading(false))
                        if (errors && errors.response && errors.response.status == constants.status_codes.unauthorized)
                            dispatch(handleErrors(errors))
                    }))
                }
                else {
                    selectedVisitDate = null
                    updateVisitError = `${labels.visit_page_labels.visit_date} ${getState().visits.selectedVisitDate.toDateString()} ${labels.visit_page_labels.future_date}`
                    dispatch(toggleLoading(false))
                }
            }
            else {
                selectedVisitDate = null
                updateVisitError = labels.visit_page_labels.visit_creation_error
                dispatch(toggleLoading(false))
            }
            // dispatch(toggleLoading(false))
        }
        else {
            selectedVisitDate = null
            updateVisitError = messages.select_visit_date
            dispatch(toggleLoading(false))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                selectedVisitDate: new Date(selectedVisitDate),
                updateVisitError
            }
        })
        // dispatch(toggleLoading(false))
    }
}
function editVisit(eve) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                selectedVisitDate: eve
            }
        })
    }
}
function AddOrder(eve) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                selectedOrder: _.head(eve) ? _.head(eve).id : 0,
                orderError: null,
                multiSelectSuites: '',
                multiSelectOrders: ''
            }
        })
    }
}
function deleteVisit(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let deleteId = getState() && getState().visits ? getState().visits.currentVisit.id : 0
        let patientId = getState().visits.currentVisit.patientId
        promisesList.push(axios.delete(`${API_URL.VISIT_URL}/${deleteId}`).then(response => {
            history.push(`/patients/${patientId}`)
            dispatch(handleLoading())
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [messages.help_add_success]
                , false))
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
        dispatch(toggleVisitModal())
    }
}
export function setCurrentOrder(visitId, orderId, history, path) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                updatedPanels: []
            }
        })
        var historyPath = history
        dispatch(toggleLoading(true))

        dispatch({
            type: SET_PROPS,
            payload: {
                currentOrder: {},
                // openObsModal: false,
                isImporting: false,
                isSearchable: true,
                isVisitModal: false,
                isNoRecommendations: false,
                currentMessages: [],
                accessionSearch: {},
                customFieldsError: ''
            }
        })
        if (history && path) {
            history.push(path)
        }
        dispatch(orderResults(orderId))
        promisesList.push(axios.get(`${API_URL.VISIT_URL}/${visitId}/Order/${orderId}`).then((response) => {
            dispatch(toggleNotification(false))
            var orderDetails = response.data;
            dispatch(toggleLoading(true))
            let currentOrder = { ...orderDetails }
            let panels = [];
            if (currentOrder.panels) {
                currentOrder.panels.map(p => {
                    let referenceRangesSet = p.referenceRangesSet ? p.referenceRangesSet : p.enumerableRange
                    p = { ...p, ...{ original_units: p.units, original_high: p.high, original_low: p.low, referenceRangesSet: referenceRangesSet, oiginal_referenceRangesSet: referenceRangesSet } }
                    if (p.valueType && p.valueType == 'NM' && p.value &&  p.value.includes(",")){
                        p.value = p.value.replace(/,/g, "")
                        p.orginalValue = p.orginalValue.replace(/,/g, "")
                    }
                    panels.push(p)
                })
            }
            currentOrder.panels = panels

            dispatch({
                type: SET_PROPS,
                payload: {
                    currentOrder,
                    observationError: [],
                    activeTab: 'manual-entry',
                    updatedPanels: [],
                    currentMessages: []
                }
            })
            if (getState().visits && getState().visits.currentVisit
                && Object.keys(getState().visits.currentVisit).length) {
                dispatch(setHeader())
                if (currentOrder && currentOrder.panelName != "Patient Survey")
                    dispatch(getCustomFields())
            }
        }).catch((error) => {
            if (error.response && error.response.status == 404) {
                dispatch(handleStatusCodes(error))
            }
            else {
                historyPath.push(`/visits/${visitId}`)
                dispatch(handleErrors(error))
            }
        }))
    }
}

export function setCalledSetCurrentOrder(value) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isCalledSetCurrentOrder: value
            }
        })
    }
}
export function orderResults(orderId) {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                orderResult: []
            }
        })
        promisesList.push(axios.get(`${API_URL.ORDER_RESULTS_URL}/${orderId}`).then((response) => {
            let orderResult = response.data
            dispatch({
                type: SET_PROPS,
                payload: {
                    orderResult
                }
            })
            // dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))

    }
}

function setCurrentResult(resultId, visitId, history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentResult: {},
                isResultsSet: false,
                isResultsPage: true
            }
        })

        promisesList.push(axios.get(`${API_URL.RESULT_URL}/${visitId}/Results/${resultId}`).then((response) => {
            var resultDetails = response.data
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentResult: resultDetails
                }
            })
            dispatch(orderDetails(resultId))
            // dispatch(handleLoading())
            if (getState().visits && getState().visits.currentVisit
                && Object.keys(getState().visits.currentVisit).length)
                dispatch(setHeader())
        })
            .catch(error => {
                history.push(`/visits/${visitId}`)
                dispatch(handleErrors(error))
            }))
    }
}
function orderDetails(resultId) {
    return (dispatch, getState) => {
        var results
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                results: {}
            }
        })
        let panelName = getState().visits.currentResult.panelName
        promisesList.push(axios.get(`${API_URL.RESULT_URL}/${resultId}`).then((response) => {
            results = response.data
            results = { ...results, panelName }
            dispatch({
                type: SET_PROPS,
                payload: {
                    results,
                    isResultsSet: true
                }
            })
            // dispatch(handleLoading())

            if (getState().visits && getState().visits.currentResult
                && Object.keys(getState().visits.currentResult).length && getState().visits.currentVisit
                && Object.keys(getState().visits.currentVisit).length)
                dispatch(setHeader())
            dispatch({
                type: SET_PROPS,
                payload: {
                    isResultsPage: false
                }
            })
        }).catch(error => {
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [`${error.response ? error.response.data : ''}`]
                , false))
            if (error.response && error.response.status == constants.status_codes.unauthorized)
                dispatch(handleErrors(error))
        }))

    }
}
export function setHeaderType(headerType, loadHeader = false) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                headerType
            }
        })
        loadHeader && [constants.visit_labels.new_order, constants.visit_labels.new_questionnaire].includes(headerType) ?
            dispatch(setHeader()) : ''
    }
}
function toDataURL(url, name) {
    return (dispatch) => {

        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        [name]: reader.result
                    }
                })
                return (reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
}
function handleRecommendationsData(data) {
    return (dispatch, getState) => {
        let currentVisit = getState().visits.currentVisit
        dispatch({
            type: SET_PROPS,
            payload: {
                currentVisit: { ...currentVisit, recommendations: data }
            }
        })
    }
}
function setRecommendations(visitId) {
    return (dispatch) => {
        promisesList.push(axios.get(`${API_URL.RECOMMENDATION_URL}/${visitId}`).then((response) => {
            dispatch(handleRecommendationsData(response.data))

        }).catch((error) => {
            dispatch(handleErrors(error))
        }))

    }
}
function handleCurrentVisit(data, history, isDownload, locale, loadDocuments, loadHistoricData, reportType) {
    return (dispatch, getState) => {
        // dispatch(toggleNotification(false))
        var visitDetails = data;
        let currentVisit = { ...visitDetails }
        dispatch(toDataURL(`${currentVisit.patientLanguagePref}-baseline.JPG`, 'baselineGraph'))
        let tp = currentVisit.timePoint
        let currentTimepoint = parseInt(tp.replace(/^\D+/g, ''))
        let currentSite = { id: visitDetails.siteId, name: visitDetails.siteName }
        visitDetails = fillPatient(currentVisit)
        currentVisit = { ...currentVisit, ...visitDetails }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentVisit,
                currentSite,
                activeTab: 'manual-entry',
                currentTimepoint,
            }
        })
        if (reportType == "patient"){
            dispatch(getPatientReportRecommendations(false));
        }    
        if (reportType == "patientPast"){
            dispatch(getPatientReportRecommendations(true));
        }    
        if (currentVisit.id) {
            if (loadDocuments) {
                dispatch(getCommonDocuments(currentVisit.siteId, currentVisit.id))
            }
            if (getState().visits.headerType == constants.visit_labels.upload_document) {
                dispatch(setHeader())
            }
            if (getState().visits.headerType == constants.visit_labels.visit) {
                (reportType == "patient" || reportType == "patientPast" || reportType == "physician" || reportType == "physicianPast") ? null : dispatch(getDocumentsWithVisitId(currentVisit.id));
            }
            dispatch(setRecommendations(currentVisit.id))
            dispatch(getOrders(currentVisit.id))
            let visits = getState().visits;
            if (visits.headerType == constants.visit_labels.visit || visits.headerType == constants.visit_labels.result) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentOrder: {}
                    }
                })
            }
            if (isDownload || visits.headerType == constants.visit_labels.visit || loadHistoricData || reportType
            ) {
                if (!isDownload) {
                    dispatch(FollowUpData(currentVisit.patientId, locale ? locale : !getState().visits.isIndividualVisitPage && reportType != null ? currentVisit.patientLanguagePref : currentVisit.reportPreference,
                        !loadHistoricData && !isDownload && visits.headerType == constants.visit_labels.visit ? currentVisit.id : (reportType == "patientPast" || reportType == "physicianPast" || reportType == "patientReportDownloadTemplatePast" || reportType == "physicianReportDownloadTemplatePast" ? null : currentVisit.id)))
                }
                dispatch(getAllCategories(
                    currentVisit.id, isDownload, reportType,
                    locale ? locale : !getState().visits.isIndividualVisitPage && reportType != null ? currentVisit.patientLanguagePref : currentVisit.reportPreference, null,
                    (!loadHistoricData && !isDownload && visits.headerType == constants.visit_labels.visit) ? false : (reportType == "physician" || reportType == "patient" || reportType == "patientReportDownloadTemplate" || reportType == "physicianReportDownloadTemplate" ? false : true))
                )
            }
            if (visits) {
                if ((visits.headerType == constants.visit_labels.visit)
                    || (visits.headerType == constants.visit_labels.new_order)
                    || (visits.headerType == constants.visit_labels.new_questionnaire)
                    || visits.currentResult
                    && Object.keys(visits.currentResult).length
                    || visits.currentOrder
                    && Object.keys(visits.currentOrder).length) {
                    dispatch(setHeader(reportType))
                }

            }
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentVisit: { ...getState().visits.currentVisit, id: 26 }
                }
            })
            dispatch(toggleLoading(true))
            dispatch(handleRecommendationsData(demoData.recommendationsInfo))
            dispatch(handleOrdersData(demoData.ordersInfo))
            dispatch(setCategoriesData(demoData.categoriesInfo))
            dispatch(setTermsData(demoData.termTranslations))
            dispatch(setAllSections(demoData.passagesData))
            dispatch(setHistoricCategoryData(demoData.historicCategoryInfo))
            dispatch(dataSelector())
            //for demo I was selecting upto follow-up 5
            dispatch(handleFollowUpData(demoData.followUpData, 5))
            setTimeout(() => {
                dispatch(demoPatientReportDownload())
                dispatch(toggleLoading(false))
            });
        }

        // dispatch(handleLoading())
    }
}
function setAllSections(passagesData) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                passagesData
            }
        })
    }
}
export function setCurrentVisit(visitId, history, isDownload, locale, loadDocuments, loadHistoricData, reportType, isAnalytics) {
    return (dispatch, getState) => {
        // if (isDownload)
        //     document.body.style.overflow = 'hidden'
        dispatch(toggleLoading(true))
        if (reportType) {
            dispatch(toggleReportLoading(true))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentVisit: {},
                // openObsModal: false,
                isImporting: false,
                isSearchable: true,
                isVisitModal: false,
                isNoRecommendations: false,
                common_documents: null,
                currentDoc: {},
                patientReportRecommendations: null,
                patientReportCurrentRecommendations: null,
                patientReportStoppedRecommendations: null,
                patientReportType: reportType,
                isAnalytics: isAnalytics,
                patientReportProblems: null,
            }
        })
        dispatch(toDataURL(logo, 'physioageLogo'))
        dispatch(toDataURL(poweredByLogo, 'poweredByLogo'))
        !getState().visits.isIndividualVisitPage && reportType != "patient" && reportType != "patientPast" ? dispatch(getTerms(locale)) : null;
        //dispatch(getTerms(locale))
        if (visitId) {
            promisesList.push(axios.get(`${API_URL.VISIT_URL}/${visitId}`).then((response) => {
                dispatch(handleCurrentVisit(response.data, history, isDownload, locale, loadDocuments, loadHistoricData, reportType))
            })
                .catch((error) => {
                    dispatch(handleErrors(error))
                }))
        }
        else {
            dispatch(handleCurrentVisit(demoData.latestVisitInfo))
        }

    }
}
function handleOrdersData(data) {
    return (dispatch, getState) => {
        var orderDetails = [];
        orderDetails.push(...data.filter(res => res.panelName == constants.visit_labels.patient_survey))
        orderDetails.push(...data.filter(res => res.panelName != constants.visit_labels.patient_survey))
        // let orderDetails = response.data
        var shouldLoadOrder = getState().visits.shouldLoadOrders
        var intervalForOrders
        if (shouldLoadOrder && getState().visits.currentVisit && getState().visits.currentVisit.id) {
            intervalForOrders = setTimeout(() => {
                dispatch(getOrders(getState().visits.currentVisit.id, false, null, true))
            }, 3000);
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                ordersList: orderDetails,
                selectedOrder: 0,
                no_order_data: orderDetails.length ? false : true,
                intervalForOrders
            }
        })
        // dispatch(handleLoading())
    }
}
function getOrders(visitId, isImportRoute, messages, disableLoader) {
    return (dispatch, getState) => {
        !disableLoader ? dispatch(toggleLoading(true)) : ''
        visitId ?
            promisesList.push(axios.get(`${API_URL.VISIT_ORDER_URL}/${visitId}`).then(response => {
                if (isImportRoute) {
                    let panelName = safeWeb(_.head(response.data.filter(s => s.id == _.head(messages).orderId)).panelName)
                    window.location.href = (`/visits/${visitId}/${panelName}/${_.head(messages).orderId}`)
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        shouldLoadOrders: response.data && response.data.length && response.data.filter(s => s.enableLoader == true).length
                            ? true : false
                    }
                })
                dispatch(handleOrdersData(response.data))

            }).catch(error => {
                dispatch(handleErrors(error))
            })) : ''
    }
}
function getBiomarkersAgeData(biomarkers, prefetchVals) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            ageWeightsGraph: null
        })
        // dispatch(toggleLoading(true))
        var ageWeights = {}
        var ageWeightAges = [['FollowUp', 'Preset', 'Gap', 'Unfavourable']]
        if (Object.keys(biomarkers).indexOf(constants.physioAge) != -1 && biomarkers[constants.physioAge]) {
            Object.keys(biomarkers).map(s => {
                // s != constants.epigenAge && s != constants.glycanAge && 
                if (s != constants.physioAge && biomarkers[s] != 0) {
                    // var age = Math.floor(getState().visits.currentVisit.patientVisitWithMonths)
                    var age = roundNumber(getState().visits.currentVisit.patientVisitWithMonths, 1)
                    var ageGap = parseFloat((Math.pow(((Math.abs(biomarkers[s] - age)) * 0.1), 1.07)).toFixed(3))
                    var unFavourableAge = (biomarkers[s] > age && s != 'cutoAge') ? 0.5 : 0
                    var preset = prefetchVals[s]
                    ageWeightAges[s] = [constants.biographical_labels.ageLabels[s], preset, ageGap, unFavourableAge]
                }
            })
        }
        let total = ageWeightAges
        ageWeights = total
        var sample = []
        var ageWeightsGraph = Object.keys(ageWeights).map(s => {
            sample = ageWeights[s]
            return sample
        })
        // dispatch(handleLoading())
        dispatch(setPatientReportStatus(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                biomarkers,
                ageWeightsGraph
            }
        })
    }
}

function fillPatient(visitDetails) {
    let dateFormat = visitDetails.datePreference;
    return {
        patientAge: Math.abs(moment().diff(visitDetails.dob, 'years')),
        // patientVisitAge: Math.floor(visitDetails.patientVisitAge),
        patientVisitAge: roundNumber(visitDetails.patientVisitAge, 1),
        patientVisitWithMonths: parseFloat((Math.abs(moment(visitDetails.date).diff(visitDetails.dob, 'years', true))).toFixed(1)),
        visitDate: moment(visitDetails.date).format(visitDateFormat[dateFormat] ? visitDateFormat[dateFormat] : constants.dateFormats.ymd),
        currentVisitDate: moment(visitDetails.date).format(dateFormats[dateFormat] ? dateFormats[dateFormat] : constants.dateFormats.ymd),
        name: `${visitDetails.firstname} ${visitDetails.lastname}`,
        displayDate: (dateFormat == constants.safari_invalid_date_format && isSafari())
            ? moment(getValidSafariDate(visitDetails.dob)).format(dateFormats[dateFormat] ? dateFormats[dateFormat] : constants.dateFormats.ymd)
            : moment(visitDetails.dob).format(dateFormats[dateFormat] ? dateFormats[dateFormat] : constants.dateFormats.ymd)
    }
}
export function setHeader(isReportDownload) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentVisit = getState().visits.currentVisit
        let currentOrder = getState().visits.currentOrder
        let currentResult = getState().visits.currentResult
        let loggedInUser = getState().global.loggedInUser
        let results = getState().visits.results
        let breadCrumbs = [
            { text: labels.physioage_label, path: '/patients/list' },
            { text: currentVisit ? currentVisit.siteName : '', path: loggedInUser.role == constants.logged_roles.CG ? '/patients/list' : `/sites/${currentVisit.siteId}` },
            { text: currentVisit ? `${currentVisit.lastname}, ${currentVisit.firstname}` : '', path: `/patients/${currentVisit.patientId}` },
        ]
        switch (getState().visits.headerType) {
            case constants.visit_labels.visit:
                dispatch(setPageTitle(`${currentVisit.timePoint} for ${currentVisit ? `${currentVisit.lastname}, ${currentVisit.firstname}` : ''}`))
                breadCrumbs.push({ text: currentVisit ? currentVisit.timePoint : '', path: '' })
                dispatch(setBreadCrumbs(breadCrumbs))
                document.title = `${labels.physioage_label}: ${currentVisit.timePoint} for ${currentVisit ? currentVisit.name : ''}`
                break;
            case constants.visit_labels.order:
                if (Object.keys(currentOrder).length) {
                    dispatch(setPageTitle(`${currentOrder.panelName} for ${currentVisit ? currentVisit.name : ''}`))
                    breadCrumbs.push({ text: currentVisit ? currentVisit.timePoint : '', path: `/visits/${currentVisit.id}` },
                        { text: currentOrder ? currentOrder.suiteName : '', path: '' })
                    dispatch(setBreadCrumbs(breadCrumbs))
                    document.title = `${labels.physioage_label}: ${currentOrder.panelName} for ${currentVisit ? currentVisit.name : ''}`
                    dispatch(validateAccessionSearch())
                }
                break;
            case constants.visit_labels.result:
                if (Object.keys(currentResult).length && Object.keys(results).length) {
                    dispatch(setPageTitle(labels.visit_page_labels.results_title))
                    breadCrumbs.push(
                        { text: currentVisit ? currentVisit.timePoint : '', path: `/visits/${currentVisit.id}` },
                        { text: currentResult ? currentResult.suiteName : '', path: `/visits/${currentVisit.id}/${safeWeb(currentResult.panelName)}/${currentResult.orderId}` },
                        {
                            text: currentResult && results.createdAt ? `${moment(results.createdAt, "MMM-DD-YYYY").format('YYYY') != "0001" ?
                                moment(returnLocalizedDate(results.createdAt)).format('L') : results.publicationDate != "Not Documented" ? moment(results.publicationDate).format('MM/DD/YYYY') : ""} 
                                ${labels.orders_labels.result}` : '', path: ''
                        }
                    )
                    dispatch(setBreadCrumbs(breadCrumbs))
                }
                break;
            case constants.visit_labels.new_order:
                dispatch(setPageTitle(`${constants.neworder} for ${currentVisit.timePoint} for ${currentVisit ? `${currentVisit.lastname}, ${currentVisit.firstname}` : ''}`))
                breadCrumbs.push({ text: currentVisit ? currentVisit.timePoint : '', path: `/visits/${currentVisit.id}` },
                    { text: constants.neworder, path: '' }
                )
                dispatch(setBreadCrumbs(breadCrumbs))
                document.title = `${labels.physioage_label}: ${constants.neworder} for ${currentVisit.timePoint} for ${currentVisit ? currentVisit.name : ''}`
                break;
            case constants.visit_labels.common_documents:
                dispatch(setPageTitle(`${constants.browse_download_library}`))
                breadCrumbs.push({ text: currentVisit ? currentVisit.timePoint : '', path: `/visits/${currentVisit.id}` },
                    { text: constants.browse_download_library, path: '' }
                )
                dispatch(setBreadCrumbs(breadCrumbs))
                document.title = `${labels.physioage_label}: ${constants.browse_download_library}`
                break;
            case constants.visit_labels.upload_document:
                dispatch(setPageTitle(`${labels.document_labels.upload_document_for} ${currentVisit ? currentVisit.timePoint : ''}`))
                breadCrumbs.push({ text: currentVisit ? currentVisit.timePoint : '', path: `/visits/${currentVisit.id}` },
                    { text: constants.visit_labels.patient_downloads, path: '' }
                )
                dispatch(setBreadCrumbs(breadCrumbs))
                document.title = `${labels.physioage_label}: ${labels.document_labels.upload_document_for} ${currentVisit ? currentVisit.timePoint : ''}`
                break;
            case constants.visit_labels.new_questionnaire:
                dispatch(setPageTitle(`${constants.newquestionnaire} for ${currentVisit.timePoint} for ${currentVisit ? `${currentVisit.lastname}, ${currentVisit.firstname}` : ''}`))
                breadCrumbs.push({ text: currentVisit ? currentVisit.timePoint : '', path: `/visits/${currentVisit.id}` },
                    { text: constants.newquestionnaire, path: '' }
                )
                dispatch(setBreadCrumbs(breadCrumbs))
                document.title = `${labels.physioage_label}: ${constants.newquestionnaire} for ${currentVisit.timePoint} for ${currentVisit ? currentVisit.name : ''}`
                break;
        }
        { isReportDownload ? null : dispatch(handleLoading()) }
    }
}
function validateAccessionSearch() {
    return (dispatch, getState) => {
        let currentVisit = getState().visits.currentVisit
        let currentOrder = getState().visits.currentOrder
        if (currentOrder.panels.length) {
            let suiteName = currentOrder.suiteName
            if (suiteName == constants.visit_labels.cognitive_function || suiteName == constants.visit_labels.bloodwork || suiteName == constants.visit_labels.immune_function || suiteName == constants.visit_labels.dna_methylation || suiteName == constants.visit_labels.igg_Glycosylation) {
                if (currentVisit.acceptsMessages == 1) {
                    dispatch(accessionSearchs())
                }
            }
        }
    }
}
function navigateOrder(history, visitId, panelName, panelId) {
    return (dispatch) => {
        let path = `/visits/${visitId}/${safeWeb(panelName)}/${panelId}`
        history.push(path)
        dispatch(toggleNotification(false))
    }
}
export function addTests(history, suiteNameDisplay, panelNameNotFound) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                multiSelectSuites: '',
                suiteNameList: getState().visits.suitesList,
                suiteNameDisplay: suiteNameDisplay,
                suiteName: '',
                panelName: '',
                panelNameNotFound: panelNameNotFound
            }
        })
        dispatch(toggleNotification(false))
        let path = `/visits/${getState().visits.currentVisit.id}/orders/new`
        history.push(path)
    }
}
function safeWeb(panelName) {
    var newString = panelName.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase().replace(/[^a-zA-Z0-9_]/g, '_').replace(/_{2,}/g, '_');
    return newString;
}
function deleteOrder(history, order_id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let deleteId = getState() && getState().visits && getState().visits.deleteOrderId ? getState().visits.deleteOrderId : order_id ? order_id : 0
        let order = getState().visits.ordersList.find((ol) => ol.id == deleteId)
        let panelName = order && order.panelName ? order.panelName : ""
        let visitId = getState() && getState().visits ? getState().visits.currentVisit.id : 0;
        promisesList.push(axios.delete(`${API_URL.ORDER_URL}/${deleteId}`).then(response => {
            history.push(`/visits/${visitId}`)
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [`${labels.orders_labels.order_success_delete} ${panelName}`]
                , false))
            dispatch(getOrders(visitId))
            dispatch(handleLoading())

        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
        if (!order_id) {
            dispatch(toggleVisitModal())
        }
    }
}

export function getPanels(visitId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.PANELS_DATA}?visitId=${visitId ? visitId : 0}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    panelList: response.data.panelsDTO,
                    panelNameNotFound: true,
                    suitesList: response.data.suitsDTO
                }
            })
            dispatch(toggleLoading(false))
            // dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
export function addNewOrder(history) {
    return (dispatch, getState) => {
        var selectedOrder = null
        var orderError = null
        var visits = getState().visits
        if (visits && (visits.multiSelectOrders || (visits.panelName && visits.suiteName))) {
            let currentVisit = visits.currentVisit
            // selectedOrder = parseInt(visits.selectedOrder)
            // let validatePanel = visits && visits.ordersList && _.head(visits.ordersList.filter(s => s.panelId == selectedOrder))
            //     ? _.head(visits.ordersList.filter(s => s.panelId == selectedOrder)).id : ''
            // if (validatePanel == '') {
            // let PanelName = visits && visits.panelList ? _.head(visits.panelList.filter(s => s.id == selectedOrder)).name : ''
            let PanelName = getState().visits.multiSelectOrders ? getState().visits.multiSelectOrders : getState().visits.panelName ? getState().visits.panelName : ''
            let suitName = getState().visits.multiSelectOrders && visits.multiSelectSuites ? _.head(visits.multiSelectSuites.split(':')) : getState().visits.suiteName ? getState().visits.suiteName : ''
            let siteId = currentVisit.siteId
            let CreateOrderDTO = []
            if (getState().visits.multiSelectOrders) {
                getState().visits.multiSelectOrders.split(",").map(s => CreateOrderDTO.push({ VisitId: currentVisit.id, panelName: s, siteId }))
                if (visits.multiSelectSuites) {
                    visits.multiSelectSuites.split(",").map((t, index) => CreateOrderDTO[index].suitName = _.head(t.split(":")))
                }
            }
            else {
                CreateOrderDTO.push({ VisitId: currentVisit.id, PanelName, suitName, siteId })
            }
            // let params = { VisitId: currentVisit.id, PanelName, suitName, siteId }
            let orderDTO = { CreateOrderDTO }
            dispatch(toggleLoading(true))
            promisesList.push(axios.post(API_URL.ORDER_URL, { ...orderDTO }).then((response) => {
                dispatch(toggleNotification(true, constants.notification_type.success, '',
                    [`${labels.orders_labels.order_success} ${PanelName}`]
                    , false))
                dispatch(setLoggedInUser())
                // dispatch(getOrders(visits.currentVisit.id))
                // dispatch(handleLoading())
                history.push(`/visits/${visits.currentVisit.id}`)
            }).catch(errors => {
                orderError = errors.response && errors.response.data ? errors.response.data : ''
                dispatch(handleErrors(errors))
            }))
        }
        // else {
        //     selectedOrder = 0
        //     orderError = labels.orders_labels.panel_validate
        //     dispatch(toggleNotification(true, constants.notification_type.error, '',
        //         [`${labels.orders_labels.panel_validate}`]
        //         , false))
        // }
        // }
        else {
            if (!visits.panelName) {
                orderError = messages.select_panel
            }
            else {
                orderError = messages.select_suit
            }
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [`${orderError}`]
                , false))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                selectedOrder,
                orderError
            }
        })


    }
}
function hasActiveElk() {
    return (dispatch, getState) => {
        var modalMode = constants.popup_labels.addTest
        var orderError = null
        //No need of checking the credits for add test
        //let elkdate = getState().visits.currentVisit.elkActivatedAt ? getState().visits.currentVisit.elkActivatedAt : ''
        //let date = elkdate ? moment().diff(moment(elkdate), 'months') : '';
        //let hasElkActive = elkdate && date < 6 ? false : true
        //let category = getState().visits.currentVisit.billingStrategy != constants.billing_strategy_recurring ? true : false
        dispatch(getPanels())
        // if (category) {
        //     if (hasElkActive) {
        //         if (getState().visits.currentVisit.credits < 1) {
        //             orderError = labels.visit_page_labels.credits_elk
        //             modalMode = constants.popup_labels.addCredits
        //         }
        //     }
        // }
        dispatch({
            type: SET_PROPS,
            payload: {
                orderError,
                modalMode
            }
        })
    }
}

export function setActiveTab(tab) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let customFieldsError = getState().visits && getState().visits.customFieldsError ? getState().visits.customFieldsError : ''
        if (tab != 'custom_fields' && customFieldsError == labels.custom_fields_labels.no_changes_found) {
            customFieldsError = ''
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                activeTab: tab,
                customFieldsError: customFieldsError
            }
        })
    }
}
export function editPanel(event) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                [event.target.id]: event.target.value
            }
        })


    }
}
export function updateResultStatus(eve, datePickerId) {
    return (dispatch, getState) => {
        let currentOrder = { ...getState().visits.currentOrder }
        let existingPanels = [...currentOrder.panels]
        const format1 = "YYYY-MM-DD";
        let displayDate = eve ? moment(eve).format(format1) : null
        let panel = ''
        if (datePickerId) {
            panel = { id: datePickerId.toString(), value: displayDate };
        }
        else {
            panel = eve.target
        }
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentOrder: Object.assign({}, currentOrder, { panels: [] })
            }
        })
        dispatch(updatedPanels(panel, existingPanels, eve))
    }
}

export function updateUnitsAndEeferenceRange(value, id, key) {
    return (dispatch, getState) => {
        let currentOrder = { ...getState().visits.currentOrder }
        let existingPanels = [...currentOrder.panels]
        let panel = ''
        panel = { value: value, id: id, key: key }
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentOrder: Object.assign({}, currentOrder, { panels: [] })
            }
        })
        dispatch(updatedPanels(panel, existingPanels, ""))
    }
}


function updatedPanels(panel, existingPanels, dateValue) {
    return (dispatch, getState) => {
        var observationError = getState().visits.observationError
        let panels = []
        let currentOrder = { ...getState().visits.currentOrder }
        let updatedPanels = getState().visits.updatedPanels
        let panelRange
        existingPanels.map(p => {
            if (p.panelFieldId == panel.id) {
                var value = panel.value
                if (panel.key && panel.key == 'units') {
                    p.units = value
                }
                else if (panel.key && panel.key == 'referenceRange') {
                    if (p.valueType == "TX") {
                        value = value ? value.map((a) => a["range"]).join(",") : []
                        p.referenceRangesSet = value
                        p.low = null
                        p.high = null
                    }
                    else {
                        panelRange = setRangeInformat({ range: panel.value })
                        p.low = !isNaN(panelRange.low) ? panelRange.low : null
                        p.high = !isNaN(panelRange.high) ? panelRange.high : null
                        p.referenceRangesSet = null
                    }
                }
                else {
                    p.value = value
                }
                if (dateValue && p.valueType == "DT") {
                    p["dateValue"] = dateValue;
                }
                if ((updatedPanels.includes(panel.id)) && p.value && p.value.trim() == '' && !p.orginalValue) {
                    _.remove(updatedPanels, (i) => { return i == panel.id });
                    _.remove(observationError, (i) => { return i == panel.id });
                    panels.push(p)
                    if (p.valueType == "DT" ? ((new Date(value).getTime() == new Date(p.orginalValue).getTime())) : ((value == p.orginalValue || (p.orginalValue === null && value.trim() == ''))) && !(['referenceRange', 'units'].includes(panel.key))) {
                        p.resultStatus = ''
                    }
                    else if (value.trim() == '' && p.orginalValue && !(['referenceRange', 'units'].includes(panel.key))) {
                        p.resultStatus = 'D'
                    }

                    else if (p.valueType == "DT" ? ((new Date(value).getTime() == new Date(p.orginalValue).getTime())) : (p.orginalValue === p.value) && checkOriginalUnitesValue(p) && checkOriginalReferenceValue(p)) {
                        p.resultStatus = ''
                    }
                } else {
                    if (!(updatedPanels.includes(panel.id))) {
                        updatedPanels.push(panel.id)
                    }

                    if (p.valueType == "DT" && p.orginalValue != null && value == null && !(['referenceRange', 'units'].includes(panel.key))) {
                        p.resultStatus = 'D'
                    }

                    else if (p.valueType == "DT" ? ((new Date(value).getTime() == new Date(p.orginalValue).getTime())) : ((value == p.orginalValue || (p.orginalValue === null && value.trim() == ''))) && !(['referenceRange', 'units'].includes(panel.key))) {
                        _.remove(updatedPanels, (i) => { return i == panel.id });
                        p.resultStatus = ''
                    }

                    else if (value.trim() == '' && p.orginalValue && !(['referenceRange', 'units'].includes(panel.key))) {
                        p.resultStatus = 'D'
                    }

                    else if (p.value && ['referenceRange', 'units'].includes(panel.key) && p.valueType !== "TX" && (!checkOriginalUnitesValue(p) || !checkOriginalReferenceValue(p))) {
                        p.resultStatus = 'C'
                    }

                    else if (p.value && p.valueType == "TX" && "referenceRange" == panel.key && !checkOriginalReferenceSetValue(p)) {
                        p.resultStatus = 'C'
                    }

                    else if (['referenceRange', 'units'].includes(panel.key) && p.valueType == "TX" && (checkOriginalUnitesValue(p) && checkOriginalReferenceSetValue(p)) && p.value == p.orginalValue) {
                        p.resultStatus = ''
                    }

                    else if (['referenceRange', 'units'].includes(panel.key) && p.valueType !== "TX" && (checkOriginalUnitesValue(p) && checkOriginalReferenceValue(p)) && p.value == p.orginalValue) {
                        p.resultStatus = ''
                    }

                    else {
                        if (!['referenceRange', 'units'].includes(panel.key)) {
                            p.resultStatus = 'C'
                        }
                    }

                    panels.push({ ...p })
                    if (checkOriginalUnitesValue(p)) {
                        if (p.boundaryRange && p.valueType && (p.valueType == "NM" || p.valueType == "Numeric")) {
                            if (parseFloat(value) > p.boundaryRange.high || parseFloat(value) < p.boundaryRange.low) {
                                if (!(observationError.includes(p.panelFieldId))) {
                                    observationError.push(p.panelFieldId);
                                }

                            } else {
                                _.remove(observationError, (i) => { return i == p.panelFieldId });
                            }
                        }
                    }
                    else {
                        _.remove(observationError, (i) => { return i == p.panelFieldId });
                    }
                }
            } else {
                panels.push(p)
            }
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentOrder: Object.assign({}, currentOrder, { panels }),
                observationError,
                updatedPanels: updatedPanels
            }
        })
        dispatch(toggleLoading(false))
    }
}


function checkOriginalUnitesValue(p) {
    let status = false
    if (p.units == p.original_units) {
        status = true
    }
    else {
        status = false
    }
    return status
}
function checkOriginalReferenceSetValue(p) {
    let status = false
    if (p.oiginal_referenceRangesSet == null && p.referenceRangesSet) {
        status = false
    }
    if (p.oiginal_referenceRangesSet == null && p.referenceRangesSet == "") {
        status = true
    }
    else if (p.oiginal_referenceRangesSet && JSON.stringify(p.referenceRangesSet.split(",")) == JSON.stringify(p.oiginal_referenceRangesSet.split(","))) {
        status = true
    }
    else {
        status = false
    }
    return status
}

function checkOriginalReferenceValue(p) {
    let status = false
    if (p.original_high == p.high) {
        status = true
    }
    else {
        status = false
    }

    if (p.original_low == p.low) {
        status = true
    }
    else {
        status = false
    }
    return status
}
export function saveResults(hist) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let params
        let history = hist
        let updatedPanels = getState().visits.updatedPanels
        let nextOrder = getState().visits.ordersList ? getState().visits.ordersList.filter(s => s.type != 'questionnaire') : []
        let visitId = getState().visits.currentVisit.id
        // updatedPanels = getState().visits.updatedPanels
        let currentOrder = getState().visits.currentOrder
        let OrderId = currentOrder.orderId
        let CreateObservationDTOs = []
        currentOrder.panels.map(p => {
            if (updatedPanels && updatedPanels.length && ['C', 'D'].includes(p.resultStatus)) {
                if (updatedPanels.includes(p.panelFieldId.toString())) {
                    let referenceRange;
                    if (p.valueType == "TX") {
                        referenceRange = p.referenceRangesSet
                    }
                    else {
                        referenceRange = (p.low != null && p.high != null) ? p.low + '-' + p.high :
                            (p.low && !p.high) ? '>' + p.low :
                                (p.high && !p.low) ? '<' + p.high :
                                    ''
                    }
                    let newpanels = {
                        Code: p.code,
                        ValueType: p.valueType,
                        Label: p.primaryLabel != null ? p.primaryLabel : p.secondaryLabel,
                        Value: p.value,
                        Units: p.units,
                        ReferenceRange: referenceRange,
                        AbnormalFlags: null,
                        ResultStatus: p.resultStatus,
                        isUnitChanged: p.units != p.original_units ? 1 : 0
                    }
                    CreateObservationDTOs.push(newpanels)
                }
            }
        })

        // if (CreateObservationDTOs.length > 0) {
        params = { CreateObservationDTOs: CreateObservationDTOs, OrderId, visitId }
        promisesList.push(axios.post(API_URL.RESULT_URL, { ...params }).then((response) => {
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [`${labels.orders_labels.updated_panels} `]
                , false))

            let currentIndex = nextOrder.findIndex(x => x.id == OrderId)
            nextOrder = nextOrder.filter((n, i) => {
                if (i > currentIndex) return i
            })
            nextOrder = nextOrder.length ? _.head(nextOrder) : null

            if (nextOrder) {
                dispatch(setCurrentOrder(nextOrder.visitId, nextOrder.id, history, `/visits/${nextOrder.visitId}/${safeWeb(nextOrder.panelName)}/${nextOrder.id}`))
                dispatch(getOrders(visitId))

            } else {
                history.push(`/visits/${visitId}`)
                dispatch(getOrders(visitId))
            }
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
            // history.push(`/visits/${visitId}`)
            // dispatch(getOrders(visitId))
        }))
        // }
        // else {
        //     dispatch(toggleNotification(true, constants.notification_type.error, '',
        //         [`${labels.orders_labels.no_changes}`]
        //         , false))
        //     dispatch(toggleLoading(false))
        // }
        // dispatch({
        //     type: SET_PROPS,
        //     payload: {
        //         updatedPanels: []
        //     }
        // })
    }
}

export function navigateToReport(history, id, reportType, patientLanguagePref) {
    return (dispatch, getState) => {
        // dispatch(getAllCategories(id, false, reportType, patientLanguagePref, history))
        dispatch(getAllCategories(id, false, reportType, patientLanguagePref, history, reportType == 'physician' ? false : undefined))
        dispatch(toggleNotification(false))
    }
}

function setIsPatientPreview(val) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isPatientPreview: val
            }
        })
    }
}

function setCategoriesData(data) {
    return (dispatch, getState) => {
        // dispatch(handleLoading())
        dispatch({
            type: SET_PROPS,
            payload: {
                category: data
            }
        })
    }
}
function setHistoricCategoryData(data) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                Data: data
            }
        })
    }
}
export function getAllCategories(visitId, isDownload, reportType, reportPref, history, loadHistory, isMobile) {
    return (dispatch, getState) => {
        let currentVisit = getState().visits.currentVisit
        reportPref = reportPref ? reportPref : currentVisit.patientLanguagePref ? currentVisit.patientLanguagePref : constants.language_codes.ENGLISH
        dispatch(toggleLoading(true))
        if (reportType)
            dispatch(toggleReportLoading(true))
        if (isDownload) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    patientReportType: reportType
                }
            })
            dispatch(FollowUpData(currentVisit.patientId, reportPref, reportType == 'patientReportDownloadTemplate' || reportType == 'physicianReportDownloadTemplate' ? visitId : null))
        }
        if (!isDownload && history && reportType != 'physician' && reportType != 'physicianPast') {
            dispatch(setBreadCrumbs([]))
            reportType == "patientPast" ? history.push(`/reports/${visitId}/patientreportpast?locale=${reportPref}`)
                : history.push(`/reports/${visitId}/patientreport?locale=${reportPref}`)
            return
        }
        if (!isDownload && history && (reportType == 'physician' || reportType == 'physicianPast')) {
            dispatch(FollowUpData(currentVisit.patientId, reportPref, reportType == 'physician' ? visitId : null))
        }
        promisesList.push(axios.get(`${API_URL.ANALYTICS_BIO_INFO}?cultureInfo=${reportPref}`).then((category) => {
            // dispatch(handleLoading())
            dispatch(setCategoriesData(category.data))
        }).catch(errors => {
            dispatch(handleErrors(errors));
        }))
        dispatch(toggleLoading(true))
        !getState().visits.isIndividualVisitPage ? dispatch(getTerms(reportPref)) : null;
        //dispatch(getTerms(reportPref))
        !getState().visits.isIndividualVisitPage ? dispatch(getAllSections(reportPref)) : null;

        getState().visits.isIndividualVisitPage ? dispatch(setIsIndividualVisitPage(false)) : null;

        if (reportType) {
            dispatch(setNoumenonReferences(reportPref))
        }

        let query = `cultureInfo=${reportPref}&timestamp=${new Date().getTime()}`
        if ((loadHistory !== undefined && loadHistory === false) || reportType == 'patientReportDownloadTemplate' || reportType == 'patient' || reportType == 'physician' || reportType == 'physicianReportDownloadTemplate') {
            query += '&loadHistory=false'
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                patientReportRecommendations: null,
                patientReportCurrentRecommendations: null,
                patientReportStoppedRecommendations: null,
                patientReportProblems: null,
            }
        })
        promisesList.push(axios.get(`${API_URL.ANALYTICS_HISTORIC_CATEGOREY}/${visitId}?${query}`).then((response) => {
            if (isDownload) {
                if (reportType == "patientReportDownloadTemplate"){
                    dispatch(getPatientReportRecommendations(false, reportType, isMobile));
                    
                }    
                else if (reportType == "patientReportDownloadTemplatePast"){
                    dispatch(getPatientReportRecommendations(true, reportType, isMobile));
                    
                }    
                // document.body.style.overflow = 'hidden'
                else {
                    setTimeout(() => {
                        dispatch(downloadpdf(reportType))
                    })
                }
            } else if (history) {
                dispatch(setBreadCrumbs([]))
                if (reportType == 'physician') {
                    history.push(`/reports/${visitId}/physicianreport?locale=${reportPref}`);
                    dispatch(handleLoading())
                }
                else if (reportType == 'physicianPast') {
                    history.push(`/reports/${visitId}/physicianreportpast?locale=${reportPref}`);
                    dispatch(handleLoading())
                }
                else {
                    reportType == "patientPast" ? history.push(`/reports/${visitId}/patientreportpast?locale=${reportPref}`)
                        : history.push(`/reports/${visitId}/patientreport?locale=${reportPref}`)
                }
                // dispatch(handleLoading())
            }
            else {
                dispatch(handleLoading())
            }
            dispatch(setHistoricCategoryData(response.data))
            // if (response.data != '') {
            dispatch(dataSelector(reportType))
            // }
        }).catch(errors => {
            dispatch(handleErrors(errors));
        }))

    }
}
export function downloadHistoricCategoryData(visitId, reportPref) {
    return (dispatch, getState) => {
        let currentVisit = getState().visits.currentVisit
        reportPref = reportPref ? reportPref : currentVisit.patientLanguagePref ? currentVisit.patientLanguagePref : constants.language_codes.ENGLISH
        dispatch(toggleLoading(true))
        dispatch(toggleReportLoading(true))
        promisesList.push(axios.get(`${API_URL.ANALYTICS_BIO_INFO}?cultureInfo=${reportPref}`).then((category) => {
            // dispatch(handleLoading())
            dispatch(setCategoriesData(category.data))
        }).catch(errors => {
            dispatch(handleErrors(errors));
        }))
        dispatch(toggleLoading(true))
        dispatch(getTerms(reportPref))
        dispatch(getAllSections(reportPref))

        promisesList.push(axios.get(`${API_URL.ANALYTICS_HISTORIC_CATEGOREY}/${visitId}?cultureInfo=${reportPref}&timestamp=${new Date().getTime()}`).then((response) => {
            dispatch(setHistoricCategoryData(response.data))
            if (response.data != '') {
                dispatch(dataSelector('historicdata'))
            }

            let categories = getState().visits.categories
            var final = []
            var allData = []
            var allVisits = []
            let terms = getState().visits.terms
            // let csv = []
            if (categories && categories.length) {
                categories.map(t => t.sections.map(s => { return { [s.name]: s.data } }).map(f => { final.push(f) }))
                if (final && final.length)
                    final.map(f => f[_.head(Object.keys(f))].map(k => _.sortBy(k.historicData, (h) => new Date(h)).map(hd => {
                        allVisits.push(hd.visitDate);
                        allData.push(hd);
                    })))
                allVisits = _.sortBy(_.uniq(allVisits), v => new Date(v))
                allData = _.groupBy(allData, 'label')

                var finalPanelObs = final.map(f => {
                    let fkey = _.head(Object.keys(f))
                    return { [fkey]: f[fkey].map(l => l.label) }
                })
                const headers = [terms['Visit Date']]
                allVisits.map(av => headers.push(moment(av).format(getDateFormat(getState().visits.currentVisit.datePreference))))
                var aoa = [headers]
                // csv.push(`${headers.join()}\n`)
                finalPanelObs.map(fpo => {
                    var fkey = _.head(Object.keys(fpo))
                    // csv = `${csv}${fkey}\n`
                    aoa.push([fkey])
                    fpo[fkey].map(obs => {
                        let obsCsv = []
                        obsCsv.push(obs)
                        allVisits.map(currentVisit => {
                            let availableVal = _.head(allData[obs].filter(indiObs => indiObs.visitDate == currentVisit))
                            if (availableVal) {
                                obsCsv.push(availableVal.value)
                            } else {
                                obsCsv.push('')
                            }
                        })
                        // csv = `${csv}${obsCsv.join()}\n`
                        aoa.push(obsCsv)
                    }
                    )
                    // csv = `${csv}\n`
                    aoa.push([])
                })
                let visits = getState().visits
                let filename = getReportFilename(visits.currentVisit, visits.terms)
                downloadExcelImproved(aoa, terms['Historical Data'], `${terms['Historical Data']}_${filename}.xlsx`)
                // downloadCSV(csv, `${terms['Historical Data']}.csv`)
                dispatch(handleLoading())
            }
            else {
                dispatch(toggleLoading(false))
                dispatch(toggleNotification(true, 'error', '', [labels.visit_page_labels.historical_data_validation], false))
            }
        }).catch(errors => {
            dispatch(handleErrors(errors));
        }))
    }
}

export function getAllSections(cultureInfo) {
    return (dispatch, getState) => {
        //dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.SECTIONS_URL}?cultureInfo=${cultureInfo}`).then((response) => {
            //dispatch(toggleLoading(false))

            // dispatch(handleLoading())
            dispatch(setAllSections(response.data))

        }).catch(errors => {
            dispatch(handleErrors(errors));
        }))
    }
}
function dataSelector(reportType) {
    return (dispatch, getState) => {
        let categoryData = getState().visits.category;
        let data = getState().visits.Data;
        let categories = datasetSelector({ categoryData, data });
        //todo
        // let exceptionalData = categories.map(s => s.sections.map(t => t.data.filter(u => u.visitId == getState().visits.currentVisit.id && u.points >= 3.7))).map(s => s.filter(t => t.length > 0)).filter(u => u.length)
        // let abnormalData = categories.map(s => s.sections.map(t => t.data.filter(u => u.visitId == getState().visits.currentVisit.id && (u.points < 2 || u.points === null)))).map(s => s.filter(t => t.length > 0)).filter(u => u.length)
        // let completeData = categories.map(s => s.sections.map(t => t.data.filter(u => u.visitId == getState().visits.currentVisit.id))).map(s => s.filter(t => t.length > 0)).filter(u => u.length)
        var exceptionalData = [], abnormalData = [], completeData = []
        categories.map(s => s.sections.map(t => t.data.filter(u => {
            if (reportType == "patientPast" || reportType == 'patientReportDownloadTemplatePast' || reportType == 'physicianPast' || reportType == 'physicianReportDownloadTemplatePast' ? true : u.visitId == getState().visits.currentVisit.id) {
                if (u.points >= 3.7) {
                    exceptionalData.push(u)
                }
                if (u.valueType == "NM" && ((u.points != null && u.points <= 1.3) || (u.points < 3.7 && u.referenceRange && u.referenceRange != "" && !IsInRange(u.referenceRange.replace(/ /g, ''), u.value, u)))) {
                    abnormalData.push(u)
                }
                completeData.push(u)
            }
        })))
        let gpa = gpaSelector(categories)
        var individualVisitData = categories
        !reportType ?
            dispatch({
                type: SET_PROPS,
                payload: {
                    individualVisitData
                }
            }) : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                categories, gpa, exceptionalData,
                abnormalData, completeData
            }
        })
    }
}
function findRecentVisitWithBiomarkers(allFollowUpsBiomarkersData) {
    let culturedBiomarkersForPast = {};

    let groupByAges = _.groupBy(allFollowUpsBiomarkersData, 'cultureLabel')
    let ages = Object.keys(groupByAges);
    for (let key of ages) {
        groupByAges[key] = groupByAges[key].filter(s => s.value != "0");
        groupByAges[key] = groupByAges[key].length > 0 ? _.last(groupByAges[key]) : {};
        _.isEmpty(groupByAges[key]) == false ? culturedBiomarkersForPast[groupByAges[key].label] = groupByAges[key].cultureLabel : '';
    }
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                culturedBiomarkersForPast,
                biomarkerAgesData: groupByAges
            }
        })
    }
}
function handleFollowUpData(data, currentFollowUpCount, reportPref) {
    return (dispatch, getState) => {
        if (getState().visits.patientReportType == constants.visit_labels.patient_download_past && data && (Object.keys(_.groupBy(data, 'timePoint')).length > 1 || (getState().visits.currentVisit && getState().visits.currentVisit.timePoint == constants.baseline))) {
            dispatch(findRecentVisitWithBiomarkers(data));
        }
        var reportConstant = report_constants[reportPref ? reportPref : constants.language_codes.ENGLISH].report_labels
        var biomarkerAges = _.uniq(data.filter(r => r.visitId == getState().visits.currentVisit.id && r.value != '0').map(s => s.label))
        var biomarkerAgesForDownload = _.uniq(data.filter(r => r.visitId == getState().visits.currentVisit.id).map(s => s.label))
        let followUpUrl = constants.biographical_labels.artisan_route
        var aggregateAge = [], graphVisitData = [], xAxisLabels = []
        var currentVisit = getState().visits.currentVisit.patientVisitAge - currentFollowUpCount;
        for (let i = 0; i <= currentFollowUpCount; i++) {
            graphVisitData[i] = { x: i, y: currentVisit }
            currentVisit++
            if (i == 0) {
                xAxisLabels[i] = getState().visits.terms ? getState().visits.terms['Baseline'] : ''
            }
            else if (i == 1) {
                xAxisLabels[i] = getState().visits.terms ? `1 ${getState().visits.terms['year']}` : ''
            }
            else {
                xAxisLabels[i] = `${i} ${reportConstant.years}`
            }
        }

        // response.data.map(age => {
        //     if (aggregateAges[age.label])
        //         aggregateAges[age.label] += age.value ? `,${age.value}` : ','
        //     else if (age.label)
        //         aggregateAges[age.label] = age.value ? `${age.value}` : ''
        // }
        // )
        // response.data.map(age => {
        //     if (aggregateAge[age.label])
        //         aggregateAge[age.label] += Math.round(age.value) != 0 ? `,${Math.round(age.value)}` : ' '
        //     else if (age.label)
        //         aggregateAge[age.label] = Math.round(age.value) != 0 ? `${Math.round(age.value)}` : ','
        // }
        // )

        aggregateAge = {};
        dispatch({
            type: SET_PROPS,
            payload: {
                dataForArtisanURL: data
            }
        })
        data.map(age => {
            if (aggregateAge[age.label]) {
                aggregateAge[age.label].push(age.value != 0 ? `${age.value}` : '')
            }
            else {
                aggregateAge[age.label] = []
                aggregateAge[age.label].push(age.value != 0 ? `${age.value}` : '')
            }
        })
        var final = []
        var age = []
        let groupByTimePoint = _.groupBy(data, 'timePoint')
        // age = (_.uniq(response.data.filter(s => s.visitId <= getState().visits.currentVisit.id).map(s => (s.age)))).map(s => Math.round(s));
        age = (Object.keys(groupByTimePoint).map(s => _.head(groupByTimePoint[s]).age)).map(s => roundNumberFixedToOne(s, 1))
        Object.keys(aggregateAge).map(agg => {
            // agg != constants.epigenAge && agg != constants.glycanAge && 
            if (aggregateAge[agg].filter(a => a).length) {
                final.push(`n:${constants.biographical_labels.ageLabels[agg]}^v:${aggregateAge[agg].join()}^b:${age.join()}`)
            }
        })
        followUpUrl = `${followUpUrl}?data=${final.join('|')}`

        let currentFollowUpUrl = constants.biographical_labels.artisan_route
        let currentAggregateAge = {};
        if (getState().visits.currentVisit.timePoint) {
            data.map(age => {
                if (age.value != "0" && age.value != 0 && age.value != "" && age.timePoint == getState().visits.currentVisit.timePoint) {
                    currentAggregateAge[age.label] = []
                    currentAggregateAge[age.label].push(`${age.value}`)
                }
            })
            let currentFinal = []
            let currentAge = []
            currentAge = roundNumber(_.head(groupByTimePoint[getState().visits.currentVisit.timePoint]).age, 1);
            Object.keys(currentAggregateAge).map(agg => {
                // agg != constants.epigenAge && agg != constants.glycanAge && 
                if (currentAggregateAge[agg].filter(a => a).length) {
                    currentFinal.push(`n:${constants.biographical_labels.ageLabels[agg]}^v:${currentAggregateAge[agg].join()}^b:${currentAge}`)
                }
            })
            if (getState().visits.currentVisit.timePoint != constants.baseline) {
                //let tempAggregateAge = [];

                let str = getState().visits.currentVisit.timePoint;
                let startIndex = str.indexOf(" ");
                let endIndex = str.length;
                let visitString = str.substring(startIndex, endIndex);
                let visitNum = parseInt(visitString);
                let tempAggregateAge = {};
                Object.keys(currentAggregateAge).map(age => {
                    if (currentAggregateAge[age].filter(a => a).length) {
                        tempAggregateAge[age] = Array(visitNum).fill("");
                        tempAggregateAge[age].push(currentAggregateAge[age][0]);
                    }
                })
                currentAggregateAge = { ...tempAggregateAge };
            }
            currentFollowUpUrl = `${currentFollowUpUrl}?data=${currentFinal.join('|')}`;
        }
        else {
            currentFollowUpUrl = followUpUrl;
            currentAggregateAge = aggregateAge;
        }

        let biomarkers = {}, prefetchVals = {}
        let biomarkersData = data
        Object.keys(biomarkersData).map(s => {
            if (biomarkersData[s].timePoint == getState().visits.currentVisit.timePoint) {
                biomarkers[biomarkersData[s].label] = biomarkersData[s].value && biomarkersData[s].value.match(/[<>]/) == null ? biomarkersData[s].value : biomarkersData[s].value
                prefetchVals[biomarkersData[s].label] = biomarkersData[s].weight
            }
        })
        var culturedBiomarkers = {}
        data.map(s => { if (s.value != "0" && s.timePoint == getState().visits.currentVisit.timePoint) return culturedBiomarkers[s.label] = s.cultureLabel })
        dispatch({
            type: SET_PROPS,
            payload: {
                followUpData: data,
                followUpUrl,
                currentFollowUpUrl,
                biomarkerAges,
                aggregateAge,
                currentAggregateAge,
                graphVisitData,
                xAxisLabels,
                biomarkerAgesForDownload,
                culturedBiomarkers,
                followUpDataLoaded: true

            }
        })
        // dispatch(handleLoading())
        dispatch(getBiomarkersAgeData(biomarkers, prefetchVals))
    }
}
export function FollowUpData(patientId, patientLanguagePref, currentVisitId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))

        let currentFollowUpCount = getState().visits.currentTimepoint ? getState().visits.currentTimepoint : 0;
        let query = `followUpCount=${currentFollowUpCount}&cultureInfo=${patientLanguagePref}`
        if (currentVisitId) {
            query += `&visitId=${currentVisitId}`
        }
        promisesList.push(axios.get(`${API_URL.FOLLOW_UP_DATA}/${patientId}?${query}`).then((response) => {
            // dispatch(handleLoading())
            dispatch(handleFollowUpData(response.data, currentFollowUpCount, patientLanguagePref))
        }).catch(errors => {
            dispatch(handleErrors(errors));
        }))
    }
}
export function navigateHealthAnalytics(history, path) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: getState().providers,
            }
        })
        dispatch(closeSideBar())
        history.push(path)
    }
}
function setTermsData(data) {
    return (dispatch) => {
        let terms = {}
        data.map(t => { terms[t.key] = t.termTranslationsDTOList.text })
        dispatch({
            type: SET_PROPS,
            payload: {
                terms
            }
        })
    }
}
export function getTerms(locale) {
    return (dispatch) => {
        if (locale) {
            promisesList.push(axios.get(`${API_URL.TERM_TRANSLATIONS}?locale=${locale}`).then(response => {
                dispatch(setTermsData(response.data))
            }).catch(error => dispatch(handleErrors(error))))
        }
    }
}
function getReportFilename(visit, terms) {
    var timepoint = visit.timePoint
    if (timepoint && visit) {
        var name = visit.name.replace(/ /g, '_')
        var date = moment(visit.date).format('YYYY_MM_DD')
        var timepoint;
        if (!visit.patientLanguagePref || visit.patientLanguagePref == constants.language_codes.ENGLISH) {
            if (visit.timePoint == constants.VISIT_TIMEPOINTS.BASELINE)
                timepoint = constants.baseline_report_name
            else {
                timepoint = visit.timePoint.split(' ')
                timepoint = `${_.head(timepoint).split('-').map(n => _.head(n).toUpperCase()).join('')}${_.last(timepoint)}`
            }
        } else {
            let reqTp = visit.timePoint.split(' ')
            timepoint = visit.timePoint == constants.VISIT_TIMEPOINTS.BASELINE
                ? `${terms[_.head(reqTp)]}`
                : `${terms[_.head(reqTp)]}_${_.last(reqTp)}`
        }

        return [name, timepoint, date].join('_')
    }
}
function demoPatientReportDownload() {

    return (dispatch, getState) => {
        // document.body.style.overflow = 'hidden'
        let filename = 'demo_female_patient_report'
        let terms = getState().visits.terms
        let header
        let currentvisit = getState().visits.currentVisit
        header = `
            <div class=\"page-header\" style=\"
            width:100%; 
            display: flex;
            justify-content: space-between;
            border-bottom: 0.5px solid #808080;
            padding: 0px 15px;
            font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
            font-weight: 700;
        \"><span style=\"
            color: #896a48;
            font-weight: 700;
            font-size: 9px;
            display: grid;
            padding-bottom: 10px;
  font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        \"><span>${currentvisit.honorificPrefix ? currentvisit.honorificPrefix : ''} ${currentvisit.name}${currentvisit.honorificSuffix ? `, ${currentvisit.honorificSuffix}` : ''} ${getLocalizedDate(currentvisit.date, currentvisit.patientLanguagePref, currentvisit.datePreference)}</span></span><span style=\"
            color: #008fbd;
            font-weight: 700;
            font-size: 9px;
  font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
  \">${terms[en_labels.report_labels.comprehensive_health_analysis]}</span></div>`
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                [`patientReportDownloadTemplateSection`]: true
            }
        })
        setTimeout(() => {
            if (document.getElementById('patientReportDownloadTemplate')) {
                var htmlMarkup = `<html>
                <head>
                <meta charset="utf-8">
                <link href='https://fonts.googleapis.com/css?family=Alegreya+Sans+SC' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:100,300,400,500,700' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Sarala' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Sarala:400,700' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Crimson+Text' rel='stylesheet' type='text/css'><link href="https://fonts.googleapis.com/css?family=Crimson+Text&display=swap" rel="stylesheet">
                <link href='https://fonts.googleapis.com/css?family=PT+Serif' rel='stylesheet' type='text/css'>
                 <link href='https://fonts.googleapis.com/css?family=Noto+Sans+JP' rel='stylesheet' type='text/css'> 
                <link href='https://fonts.googleapis.com/css?family=EB+Garamond' rel='stylesheet' type='text/css'>
                <link href='https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css' rel='stylesheet' type='text/css'>
                 <style>${reportStyles}</style>
                </head><body>${document.getElementById('patientReportDownloadTemplate').outerHTML}</body></html>`
                axios.post(API_URL.DEMO_REPORT_DOWNLOAD,
                    {
                        htmlMarkup,
                        fileName: `${filename}.pdf`,
                        header,
                        visitId: currentvisit.id,
                        document: 'patient',
                        locale: currentvisit.patientLanguagePref
                    }
                    )
                    .then(response => {
                        dispatch(toggleLoading(false))
                        // var finalData = appendBuffer(response.data.coverPage, response.data.remainingPage)

                        if (isIE()) {
                            let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                            if (window.navigator.msSaveBlob) {
                                window.navigator.msSaveOrOpenBlob(pdfblob, `${filename}.pdf`);
                            }
                        }

                        else {
                            const downloadLink = document.createElement('a');
                            if (!isFirefox()) {
                                let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                downloadLink.href = URL.createObjectURL(pdfblob)
                            } else {
                                downloadLink.href = 'data:application/pdf;base64,' + response.data
                            }

                            downloadLink.className = 'hidden';
                            document.body.appendChild(downloadLink);

                            downloadLink.setAttribute('download', `${filename}.pdf`);
                            downloadLink.click();
                            window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
                        }
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                [`patientReportDownloadTemplateSection`]: false
                            }
                        })
                    }).catch(error => {
                        dispatch(handleErrors(error))
                    })

            }

        }, 4000)
    }
}

export function downloadpdf(ref, isMobile) {
    return (dispatch, getState) => {
        // document.body.style.overflow = 'hidden'
        let visits = getState().visits
        let filename = getReportFilename(visits.currentVisit, visits.terms)
        let terms = visits.terms
        var tocPageCount = 1
        var count = 0
        if (visits.categories && visits.categories.length)
            visits.categories.map(s => s.sections.map(t => t.data.filter(ref == 'patientReportDownloadTemplatePast' || ref == 'physicianReportDownloadTemplatePast' ? u => u : u => u.visitId == visits.currentVisit.id).length > 0)).map(a => {
                count = count + a.filter(v => v == true).length
            })

        if (visits.biomarkers)
            count = count + Object.keys(visits.biomarkers).filter(b => visits.biomarkers[b]).length

        if (count > constants.toc_fixed_count)
            tocPageCount = 2
        let header
        let currentvisit = visits.currentVisit
        if (ref == constants.visit_labels.physician_download || ref == constants.visit_labels.physician_past_download) {
            filename = `${filename}_physician`
            header = `
                    <div class=\"page-header\" style=\"
                    width:100%; 
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 0.5px solid #808080;
                    padding: 0px 15px;
                    font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
                    font-weight: 700;
                \"><span style=\"
                    color: #896a48;
                    font-weight: 700;
                    font-size: 9px;
          font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
                    display: grid;
                \"></span><span style=\"
                    color: #008fbd;
                    font-weight: 700;
                    font-size: 9px;
          font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
          \">${terms[en_labels.report_labels.physiciansreport]}</span></div>`
        } else {
            header = `
                    <div class=\"page-header\" style=\"
                    width:100%; 
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 0.5px solid #808080;
                    padding: 0px 15px;
                    font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
                    font-weight: 700;
                \"><span style=\"
                    color: #896a48;
                    font-weight: 700;
                    font-size: 9px;
                    display: grid;
                    padding-bottom: 10px;
          font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
                \"><span>${currentvisit.honorificPrefix ? currentvisit.honorificPrefix : ''} 
                ${currentvisit.name}${currentvisit.honorificSuffix ? `, ${currentvisit.honorificSuffix}` : ''} <br/>
                ${getLocalizedDate(currentvisit.date, currentvisit.patientLanguagePref, currentvisit.datePreference)}</span>
                </span><span style=\"
                    color: #008fbd;
                    font-weight: 700;
                    font-size: 9px;
          font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
          \">${terms[en_labels.report_labels.comprehensive_health_analysis]}</span></div>`
        }
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                [`${ref}Section`]: true
            }
        })
        setTimeout(() => {
            if (document.getElementById(ref)) {
                // <link href='https://fonts.googleapis.com/css?family=Raleway:800,500' rel='stylesheet' type='text/css'>
                //<link href='https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css' rel='stylesheet' type='text/css'>
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        htmlMarkup: document.getElementById(ref).outerHTML
                    }
                })

                // axiosInstance.post(`https://localhost:44326/api${API_URL.PATIENT_REPORT_DOWNLOAD}`,
                //     {
                //         htmlMarkup,
                //         fileName: `${filename}.pdf`,
                //         header,
                //         visitId: currentvisit.id,
                //         document: (ref == constants.visit_labels.physician_download) ? constants.visit_labels.physician : constants.visit_labels.patient,
                //         locale: currentvisit.patientLanguagePref
                //     })

                setTimeout(() => {
                    var htmlMarkup = `<html>
                <head>
                <meta charset="utf-8">
                <link href='https://fonts.googleapis.com/css?family=Alegreya+Sans+SC' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:100,300,400,500,700' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Sarala' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Sarala:400,700' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Crimson+Text' rel='stylesheet' type='text/css'><link href="https://fonts.googleapis.com/css?family=Crimson+Text&display=swap" rel="stylesheet">
                <link href='https://fonts.googleapis.com/css?family=PT+Serif' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Noto+Sans+JP' rel='stylesheet' type='text/css'> 
                <link href='https://fonts.googleapis.com/css?family=EB+Garamond' rel='stylesheet' type='text/css'>
                <link href='https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css' rel='stylesheet' type='text/css'>
                <link href= rel='stylesheet' type='text/css'>
                 <style>${reportStyles}</style>
                 <style>${visits.graphVisitData && visits.graphVisitData.length <= 10 ?
                            `#historic_data table th.dates,#historic_data table td:not(:first-child){
                        min-width:${90 / visits.graphVisitData.length}%;
                        max-width:${90 / visits.graphVisitData.length}%;
                        text-align: center;
                    }`: visits.graphVisitData && visits.graphVisitData.length > 10 ?
                                `#historic_data table th.dates,#historic_data table td:not(:first-child){
                        min-width:50px;
                        max-width:50px;
                        text-align: center;
                    }
                    #historic_data table th,#historic_data table td{
                        white-space: nowrap;
                    }
                    #historic_data table th:first-child,#historic_data table td:first-child{
                        min-width:200px;
                        max-width:200px;
                        word-break: break-word;
                        white-space: pre-wrap;
                    }
                    #historic_data table th:last-child,#historic_data table td:last-child{
                        min-width:250px;
                        max-width:250px;
                        text-align: center;
                    }`
                                : ''
                        }</style>
                </head><body>${document.getElementById(ref).outerHTML}</body></html>`
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            htmlMarkup
                        }
                    })
                    dispatch(setPatientReportStatus(false))
                    axios.post(API_URL.PATIENT_REPORT_DOWNLOAD,
                        // axiosInstance.post(`https://localhost:44326/api${API_URL.PATIENT_REPORT_DOWNLOAD}`,
                        {
                            htmlMarkup,
                            fileName: `${filename}.pdf`,
                            header,
                            visitId: currentvisit.id,
                            document: (ref == constants.visit_labels.physician_download) || ref == constants.visit_labels.physician_past_download ? constants.visit_labels.physician : constants.visit_labels.patient,
                            locale: currentvisit.patientLanguagePref,
                            tableOfContents: getState().visits.tableOfContents,
                            isMobile: isMobile
                        }
                    )
                        .then(response => {
                            dispatch(toggleLoading(false))
                            // var finalData = appendBuffer(response.data.coverPage, response.data.remainingPage)
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    tableOfContents: [],
                                    patientReportRecommendations: null,
                                    patientReportCurrentRecommendations: null,
                                    patientReportStoppedRecommendations: null,
                                    patientReportProblems: null,
                                }
                            });
                            if (isIE()) {
                                let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                if (window.navigator.msSaveBlob) {
                                    window.navigator.msSaveOrOpenBlob(pdfblob, `${filename}.pdf`);
                                }
                            }

                            else {
                                const downloadLink = document.createElement('a');
                                if (!isFirefox()) {
                                    let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                    downloadLink.href = URL.createObjectURL(pdfblob)
                                } else {
                                    downloadLink.href = 'data:application/pdf;base64,' + response.data
                                }

                                downloadLink.className = 'hidden';
                                document.body.appendChild(downloadLink);

                                downloadLink.setAttribute('download', `${filename}.pdf`);
                                downloadLink.click();
                                window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
                            }
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    [`${ref}Section`]: false,
                                    reportDownloaded: true
                                }
                            })
                            document.body.style.overflow = 'inherit'
                            if(getState().visits.isAnalytics){
                                window.location.reload();
                            }
                        }).catch(error => {
                            if (!ref) {
                                ref = constants.visit_labels.patient_download
                            }
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    [`${ref}Section`]: false,
                                    [`${constants.visit_labels.patient_download_past}Section`]: false,
                                    [`${constants.visit_labels.physician_download}Section`]: false,
                                    [`${constants.visit_labels.physician_past_download}Section`]: false,
                                    tableOfContents: [],
                                    patientReportRecommendations: null,
                                    patientReportCurrentRecommendations: null,
                                    patientReportStoppedRecommendations: null,
                                    patientReportProblems: null,
                                }
                            })
                            document.body.style.overflow = 'inherit'
                            dispatch(handleErrors(error))
                        })
                }, 10000);

            }

        }, 5000)
    }
}
export function populateSearchParam(name, eve) {
    return (dispatch, getState) => {
        let allMessages = getState() && getState().visits.accessionSearch ? getState().visits.accessionSearch : []
        let currentMessages = []
        allMessages && Object.keys(allMessages).length
            ? allMessages.map(a => {
                if (a.patientName.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentMessages.indexOf(a.patientName) == -1) {
                    currentMessages.push(a)
                }
            }) : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                currentMessages,
                name
            }
        })
    }
}
export function searchCustomName(eve) {
    return (dispatch) => {
        let name = eve && eve.target ? eve.target.value : ''
        dispatch(populateSearchParam(name))
    }
}
export function accessionSearchs() {
    return (dispatch, getState) => {
        let searchName = getState().visits.currentVisit.lastname
        let siteId = getState().visits.currentVisit.siteId
        //let dateFormat = getState().visits.currentVisit.datePreference;
        let suiteName = getState().visits.currentOrder.suiteName
        let params = { PatientName: searchName, SuiteName: suiteName, SiteId: siteId }
        dispatch(toggleLoading(true))
        promisesList.push(axios.post(API_URL.ACCESSIONSEARCH, { ...params }).then((response) => {
            let result = response.data
            result = _.groupBy(result, constants.visit_labels.accessionNumber)
            let accessionSearch = Object.keys(result).map(s => (_.head(result[s].map(t => ({
                from: t.from, patientName: t.patientName, messageCount: result[s].length,
                dob: moment(t.dob).format(constants.dateFormats.ymd),
                accessionNumber: t.accessionNumber,
                observationDate: moment(t.observationDate).format(constants.dateFormats.ymd),
                reference: t.reference,
                publicationDate: t.publicationDate,
                status: t.status,
                minPublicationDate: _.head((result[s].map(t => t.publicationDate)).sort()) ? moment(_.head((result[s].map(t => t.publicationDate)).sort())).format(constants.dateFormats.ymd) : '',
                maxPublicationDate: _.last((result[s].map(t => t.publicationDate)).sort()) ? moment(_.last((result[s].map(t => t.publicationDate)).sort())).format(constants.dateFormats.ymd) : '',
                //dob:  moment(t.dob).format(dateFormats[dateFormat] ? dateFormats[dateFormat] : constants.dateFormats.ymd),
                //observationDate: moment(t.observationDate).format(dateFormats[dateFormat] ? dateFormats[dateFormat] : constants.dateFormats.ymd),
                //minPublicationDate: moment(_.head((result[s].map(t => t.publicationDate)).sort())).format(dateFormats[dateFormat] ? dateFormats[dateFormat] : constants.dateFormats.ymd),
                //maxPublicationDate: moment(_.last((result[s].map(t => t.publicationDate)).sort())).format(dateFormats[dateFormat] ? dateFormats[dateFormat] : constants.dateFormats.ymd),
            })))))
            dispatch({
                type: SET_PROPS,
                payload: {
                    accessionSearch,
                    isImporting: false
                }
            })
            dispatch(populateSearchParam(searchName))
            dispatch(handleLoading())
        }).catch(errors => {
            //dispatch(handleStatusCodes(errors))
        }))
    }
}
export function importMessagesbyAccessionNumber(accessionNumber, siteId, history) {
    return (dispatch, getState) => {
        let historyPath = history
        //let site = getState().visits.loggedInUser && getState().visits.loggedInUser.siteId ? getState().visits.loggedInUser.siteId : siteId
        let params = { AccessionNumber: accessionNumber, SiteId: getState().visits.currentVisit.siteId, VisitId: getState().visits.currentVisit.id, PatientId: getState().visits.currentVisit.patientId }
        dispatch(toggleLoading(true))
        axios.post(API_URL.IMPORT, { ...params }).then((response) => {
            let importmessages = response.data
            dispatch({
                type: SET_PROPS,
                payload: {
                    importmessages,
                    isImporting: true
                }
            })
            dispatch(toggleLoading(false))
            if (importmessages.length) {
                setTimeout(() => {
                    dispatch(updatedMessages(historyPath))
                }, 3000);
            }
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isImporting: true
                }
            })
            //dispatch(handleStatusCodes(errors))
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                isSearchable: false
            }
        })
    }
}
export function updatedMessages(history) {
    return (dispatch, getState) => {
        let historyPath = history
        let importMessages = getState().visits.importmessages
        importMessages.map(message => {
            axios.get(`${API_URL.MESSAGE}/${message.id}?lab=${message.from}`).then(response => {
                let importmessages = importMessages.map(x => { x.id == message.id ? x = response.data : ''; return x })
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        importmessages
                    }
                })
            }).catch(errors => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isImporting: true
                    }
                })
                //dispatch(handleStatusCodes(errors))
            })
        });
        let nextOrder = getState().visits.ordersList
        let visitId = getState().visits.currentVisit.id
        let OrderId = getState().visits.currentOrder.orderId
        let check = !(importMessages.filter(i => i.orderId).length == importMessages.length)
        if (check) {
            setTimeout(() => {
                dispatch(updatedMessages(historyPath))
            }, 3000);
        }
        else {
            // nextOrder = nextOrder[(nextOrder.findIndex(x => x.id == OrderId)) + 1]
            // if (nextOrder) {
            //     dispatch(setCurrentOrder(nextOrder.visitId, nextOrder.id, historyPath, `/visits/${nextOrder.visitId}/${safeWeb(nextOrder.panelName)}/${nextOrder.id}`))
            //     dispatch(getOrders(visitId))

            // } else {
            //     console.log(importMessages,"response data")
            //     window.location.href = (`/visits/${visitId}`)
            //     dispatch(getOrders(visitId))
            // }
            dispatch(getOrders(visitId, true, importMessages))
            // let panelName = safeWeb(_.head(getState().visits.ordersList.filter(s=>s.id==_.head(importMessages).orderId)).panelName)
            // window.location.href = (`/visits/${visitId}/${panelName}/${_.head(importMessages).orderId}`)
        }
    }
}

export function downloadVisitRecommendations(history, download, visitId, isMobile) {
    return (dispatch, getState) => {
        let currentVisit = getState().visits.currentVisit;
        let ordersList = getState().visits.ordersList;
        if (ordersList && !ordersList.filter(o => o.orderStatus == constants.filled_status).length && !download) {
            dispatch(toggleNotification(true, 'error', '', [labels.visit_page_labels.no_recommandations_caution], false))
        } else if(download) {
            history.push(`/visits/${currentVisit.id ? currentVisit.id : visitId}/summary.pdf?locale=${currentVisit.patientLanguagePref
                ? currentVisit.patientLanguagePref
                : constants.language_codes.ENGLISH}&download=${download}&isMobile=${isMobile}`)
        }
        else{
            history.push(`/visits/${currentVisit.id}/summary.pdf?locale=${currentVisit.patientLanguagePref
                ? currentVisit.patientLanguagePref
                : constants.language_codes.ENGLISH}`)
        }
    }
}

export function searchPanelName(e) {
    return (dispatch, getState) => {
        var allPanels = getState().panelList;
        let currentPanels = []
        allPanels.map(an => {
            if (an.value.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentPanels.indexOf(an.value) == -1)
                currentPanels.push(an.value)
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPanels,
                panel: { ...getState().panel, body: name },
                recommendationErrors: []
            }
        })
    }
}
export function setVisitDocuments(visitId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.VISIT_DOCUMENTS_URL}?visitId=${visitId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    allVisitDocuments: response.data
                }
            })
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}
export function getCommonDocuments(siteId, visitId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(setHeader())
        promisesList.push(axios.get(`${API_URL.VISIT_COMMON_DOCS_URL}?siteId=${siteId}&visitId=${visitId}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    common_documents: response.data.value ? _.orderBy(response.data.value, ['documentCreatedAt']) : []
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
            dispatch(handleStatusCodes(error))
        }))
    }
}
export function AttachDocument(documentId, history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let visits = getState().visits ? getState().visits.currentVisit : ''
        promisesList.push(axios.post(API_URL.ATTACH_DOCUMENT, {
            documentId: documentId,
            visitId: visits.id, patientId: visits.patientId
        }).then((response) => {
            dispatch(toggleLoading(false))
            history.push(`/visits/${visits.id}`)
        }).catch((error) => {
            dispatch(handleErrors(error))
            dispatch(handleStatusCodes(error))
        }))
    }
}
export function getDocumentsWithVisitId(visitId) {
    return (dispatch, getState) => {
        // dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.DOCUMENT_WITH_GENERIC_ID}?id=${visitId}&ownedBy=visit`).then(response => {
            // dispatch(toggleLoading(false))
            dispatch(setPatientFollowupDocuments(getState().visits.currentVisit.patientId))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentVisitDocuments: response.data.value && response.data.value.length ? response.data.value : []
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))

        }))
    }
}
export function setPatientFollowupDocuments(patientId) {
    return (dispatch, getState) => {
        // dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.PATIENT_DOCUMENTS_VISITS}?patientId=${patientId}`).then(response => {
            // dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentPatientDocuments: response.data.value ? _.orderBy(response.data.value, ['documentCreatedAt']) : []
                }
            })
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}
export function DeleteDistribution(documentId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentVisitId = getState().visits && getState().visits.currentVisit ? getState().visits.currentVisit.id : 0
        promisesList.push(axios.delete(`${API_URL.DELETE_DISTRIBUTION}?visitId=${currentVisitId}&documentId=${documentId}`).then(response => {
            dispatch(getDocumentsWithVisitId(currentVisitId))
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
    }
}
export function deleteDocument(id) {
    return (dispatch, getState) => {
        if (id) {
            dispatch(toggleLoading(true))
            let currentVisitId = getState().visits && getState().visits.currentVisit ? getState().visits.currentVisit.id : 0
            promisesList.push(axios.delete(`${API_URL.DOCUMENT_URL}/${id}`).then(response => {
                dispatch(getDocumentsWithVisitId(currentVisitId))
            }).catch(error => {
                dispatch(handleErrors(error))
                dispatch(handleStatusCodes(errors))
            }))
        }
    }
}
function uploadDocument(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentDoc = { ...getState().visits.currentDoc }
        currentDoc = Object.assign({}, currentDoc,
            {
                visitId: getState().visits ? getState().visits.currentVisit.id : 0,
                patientId: getState().visits ? getState().visits.currentVisit.patientId : 0,
                visible: currentDoc.id ? parseInt(currentDoc.visible) : 1,
                sourceType: 'User',
                sourceId: getState().users ? getState().users.loggedInUser.id : 0,
                publicationDate: currentDoc.id ? (getState().visits.publicationDate ? getState().visits.publicationDate : currentDoc.publicationDate) :
                    currentDoc.publicationDate ? currentDoc.publicationDate : null
            })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc
            }
        })
        if (currentDoc.title && currentDoc.title.length < 4) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    titleError: 'is too short (minimum is 4 characters)'
                }
            })
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                ['Title is too short (minimum is 4 characters)']))
            dispatch(toggleLoading(false))
        }
        else if ((currentDoc.documentFile || currentDoc.id) && currentDoc.title) {
            var params = cloneDeep(getState().patients.currentDoc);
            params.publicationDate = params.publicationDate ? moment(params.publicationDate).format('L') : params.publicationDate;
            promisesList.push(axios[getState().visits.currentDoc.id ? 'put' : 'post'](API_URL.DOCUMENT_URL, { ...params }).then(response => {
                history.push(`/visits/${getState().visits.currentVisit.id}`)
            }).catch(errors => {
                dispatch(handleErrors(errors))
                dispatch(handleStatusCodes(errors))

            }))
        }
    }
}
function getAvatarType(avatarContent) {
    if (avatarContent)
        return avatarContent.split(',')[0].split(';')[0].split(':')[1]
    else return ''
}
export function postFile(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        if (uploadedFile) {
            let doctype = uploadedFile.type
            //if (constants.valid_doc_types_for_documents.indexOf(doctype) != -1) {
            var reader = new FileReader();
            reader.onload = function (e) {
                let avatarContent = e.target.result
                let currentDoc = getState().visits.currentDoc ? getState().visits.currentDoc : {}
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentDoc: Object.assign({}, currentDoc,
                            {
                                documentFile: avatarContent.split(',')[1],
                                fileContentType: doctype ? doctype : getAvatarType(avatarContent),
                                fileFileName: uploadedFile.name,
                                fileFileSize: uploadedFile.size,
                                title: getState().visits.currentDoc && getState().visits.currentDoc.title && getState().visits.currentDoc.title == uploadedFile.name.replace(/\.[^/.]+$/, '') ? getState().visits.currentDoc.title : uploadedFile.name.replace(/\.[^/.]+$/, '')
                            }),
                    }
                })
            }
            reader.readAsDataURL(eve.target.files[0]);
            // }
            // else {
            //     dispatch(toggleNotification(true, constants.notification_type.error, '', "select valid file", false))
            // }
        }
    }
}
export function visitDocumentInput(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentDoc = getState() && getState().visits ? getState().visits.currentDoc : {}
        if (eve.target.type == 'checkbox') {
            eve.target.value = eve.target.checked ? 1 : 0
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc: { ...currentDoc, [eve.target.id]: eve.target.value }
            }
        })
    }
}
export function handlePublicationDate(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentDoc = Object.assign({}, getState().visits.currentDoc, {
            publicationDate: eve
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc,
                publicationDate: eve
            }
        })
    }
}
export function openSectionModal(sectionId) {
    return (dispatch, getState) => {
        let allCategories = getState().visits.categories
        let allSections = []
        allCategories.map(t => t.sections.map(sec => allSections.push(sec)))
        let currentSectionModal = _.head(allSections.filter(a => a.id == sectionId))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSectionModal,
                isSectionModalOpen: true
            }
        })
        // document.body.style.overflow = 'hidden'
    }
}
export function closeSectionModal() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSectionModal: null,
                isSectionModalOpen: false
            }
        })
        // document.body.style.overflow = 'auto'
    }
}

export function addToTableOfContents(id) {
    return (dispatch, getState) => {
        let tableOfContents = getState().visits.tableOfContents ? getState().visits.tableOfContents : [];
        !tableOfContents.includes("##" + id) ? tableOfContents.push("##" + id) : null;
        dispatch({
            type: SET_PROPS,
            payload: {
                tableOfContents: tableOfContents,
            }
        });
    }
}
export function addToTableOfContentsArray(ids) {
    return (dispatch, getState) => {
        let tableOfContents = [];
        ids.map(s => tableOfContents.push("##" + s))
        dispatch({
            type: SET_PROPS,
            payload: {
                tableOfContents: tableOfContents,
            }
        });
    }
}


export function setIsIndividualVisitPage(val) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isIndividualVisitPage: val
            }
        });
    }
}

export function getPatientReportRecommendations(pastData, reportType, isMobile) {
    return (dispatch, getState) => {
        let id = getState().visits && getState().visits.currentVisit && getState().visits.currentVisit.id ? getState().visits.currentVisit.id : '';
        let locale = getState().visits && getState().visits.currentVisit && getState().visits.currentVisit.patientLanguagePref ? getState().visits.currentVisit.patientLanguagePref : "en"
        let patientId = getState().visits && getState().visits.currentVisit && getState().visits.currentVisit.patientId ? getState().visits.currentVisit.patientId : 0
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.RECOMMENDATION_URL}/${id}?locale=${locale}&patientId=${patientId}&loadHistory=${pastData}`).then(async (response) => {
            let patientReportRecommendations = response.data;
            let sortableKey = constants.recommendation_groups;
            patientReportRecommendations = patientReportRecommendations.sort((a, b) => {
                return sortableKey.indexOf(a.group) - sortableKey.indexOf(b.group);
            });
            let patientReportCurrentRecommendations = [];
            let patientReportStoppedRecommendations = [];
            if (patientReportRecommendations) {
                patientReportCurrentRecommendations = patientReportRecommendations.filter((rec) => rec.isStopped == 0);
                patientReportStoppedRecommendations = patientReportRecommendations.filter((rec) => rec.isStopped == 1);
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    patientReportRecommendations: patientReportRecommendations,
                    patientReportCurrentRecommendations: patientReportCurrentRecommendations,
                    patientReportStoppedRecommendations: patientReportStoppedRecommendations
                }
            })
            dispatch(getPatientReportProblems(pastData, reportType))
            // dispatch(handleLoading())
            if (reportType) {
                const response = await axios.get('https://artisan.physioage.com');
                if (response && response.status == 200) {
                    setTimeout(() => {
                        dispatch(downloadpdf(reportType, isMobile))
                    })
                }
                else {
                    dispatch(toggleLoading(false))
                    dispatch(toggleNotification(true, constants.notification_type.error, '',
                        [`Unable to download the report due to artisans are down. Kindly reach out to the PhysioAge team for more information`]
                        , false))
                }
            }
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}

export function getPatientReportProblems(pastData, reportType) {
    return (dispatch, getState) => {
        let id = getState().visits && getState().visits.currentVisit && getState().visits.currentVisit.id ? getState().visits.currentVisit.id : '';
        let locale = getState().visits && getState().visits.currentVisit && getState().visits.currentVisit.patientLanguagePref ? getState().visits.currentVisit.patientLanguagePref : "en"
        let patientId = getState().visits && getState().visits.currentVisit && getState().visits.currentVisit.patientId ? getState().visits.currentVisit.patientId : 0
        dispatch(toggleLoading(true))
        let url = `${API_URL.GET_BY_VISIT_PROBLEMS_URL}?locale=${locale}&visitId=${id}`  
            if (pastData){
                url = `${API_URL.GET_PROBLEMS_URL}?locale=${locale}&patientId=${patientId}`
            }    
        promisesList.push(axios.get(url).then(async (response) => {
            let patientReportProblems = response.data;
            dispatch({
                type: SET_PROPS,
                payload: {
                    patientReportProblems: patientReportProblems
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}

const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    currentPatient: {},
    currentOrder: {},
    currentResult: {},
    biomarkers: {},
    currentSite: {},
    currentPanels: [],
    isAddModal: false,
    isDeleteModal: false,
    updateVisitError: null,
    currentVisit: {},
    panelList: null,
    selectedOrder: null,
    siteList: null,
    orderError: null,
    deleteOrderId: null,
    observationError: [],
    results: {},
    ordersList: [],
    updatedPanels: [],
    accessionSearch: [],
    activeTab: 'manual-entry',
    isChartsLoaded: false,
    selectedVisitDate: null,
    importmessages: null,
    isImporting: false,
    isSearchable: true,
    isNoRecommendations: false,
    tableOfContents: [],
    isIndividualVisitPage: false,
    customFieldsFormValues: [],
    CreateDocumentList: [],
    isCalledSetCurrentOrder: true
}
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}
export function addQuestionnaire(history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                multiSelectQuestionnaire: ''
            }
        })
        dispatch(toggleNotification(false))
        let path = `/visits/${getState().visits.currentVisit.id}/questionnaire/new`
        history.push(path)
        if (getState().visits && getState().visits.currentVisit && Object.keys(getState().visits.currentVisit).length)
            dispatch(setHeader())
    }
}
export function addNewQuestionnaire(history) {
    return (dispatch, getState) => {
        var selectedOrder = null
        var orderError = null
        var visits = getState().visits
        if (visits && visits.multiSelectQuestionnaire) {
            let currentVisit = visits.currentVisit
            // selectedOrder = parseInt(visits.selectedOrder)
            // let PanelName = visits && visits.questionnairesList && visits.questionnairesList.value.filter ? _.head(visits.questionnairesList.value.filter(s => s.id == selectedOrder)).name : ''
            let PanelName = visits.multiSelectQuestionnaire ? visits.multiSelectQuestionnaire : ''
            let CreateOrderDTO = []
            visits.multiSelectQuestionnaire.split(",").map(question => CreateOrderDTO.push({ VisitId: currentVisit.id, panelName: question }))
            let orderDTO = { CreateOrderDTO }
            dispatch(toggleLoading(true))
            promisesList.push(axios.post(API_URL.ORDER_URL, { ...orderDTO }).then((response) => {
                dispatch(toggleNotification(true, constants.notification_type.success, '',
                    [`${labels.questionnaire_labels.questionnaire_success} ${PanelName}`]
                    , false))
                dispatch(setLoggedInUser())
                history.push(`/visits/${visits.currentVisit.id}`)
            }).catch(errors => {
                orderError = errors.response && errors.response.data ? errors.response.data : ''
                dispatch(handleErrors(errors))
            }))
        }
        else {
            orderError = messages.select_questionnaire
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                [`${messages.select_questionnaire}`]
                , false))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                selectedOrder,
                orderError
            }
        })
    }
}
export function setCurrentQuestionnaire(visitId, orderId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.VISIT_URL}/${visitId}/Questionnaire/${orderId}`).then((response) => {
            dispatch(toggleNotification(false))
            var questionnaireDetails = response.data;
            dispatch(toggleLoading(true))
            let currentQuestionnaire = { ...questionnaireDetails }
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentQuestionnaire,
                    isQuestionnaireModalOpen: true
                }
            })
            if (getState().visits && getState().visits.currentVisit
                && Object.keys(getState().visits.currentVisit).length)
                dispatch(setHeader())
            dispatch(toggleLoading(false))
        }).catch((error) => {
            if (error.response && error.response.status == 404) {
                dispatch(handleStatusCodes(error))
            }
            else {
                dispatch(toggleLoading(false))
                dispatch(handleErrors(error))
            }
        }))
    }
}

export function getQuestionnaires(visitId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.QUESTIONNAIRE_LIST}?visitId=${visitId ? visitId : 0}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    questionnairesList: response.data
                }
            })
            dispatch(toggleLoading(false))
        }).catch(error => {
            dispatch(handleErrors(error))
            dispatch(toggleLoading(false))
        }))
    }
}

export function closeQuestionnaireModal() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentQuestionnaireModal: null,
                isQuestionnaireModalOpen: false
            }
        })
    }
}

export function markQuestionnaireCompleted(id, status, questionnaireName, history) {
    return (dispatch, getState) => {
        let params = { id: id, status: status }
        dispatch(toggleLoading(true))
        promisesList.push(axios.put(`${API_URL.ORDER_URL}`, { ...params }).then(() => {
            dispatch(toggleLoading(false))
            dispatch(closeQuestionnaireModal())
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [questionnaireName + messages.marked_completed_status]
                , false))
            history.push('/visits/' + getState().visits.currentVisit.id)
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}


export function editMultiSelectQuestionnaire(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let value = eve && eve.length ? eve.map(e => e.name) : []
        dispatch({
            type: SET_PROPS,
            payload: {
                multiSelectQuestionnaire: value && value.length ? value.join(',') : ''
            }
        })
    }
}
export function editMultiSelectOrders(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let value = eve && eve.length ? eve.map(e => e.suiteName + ':' + e.name) : []
        let panelName = eve && eve.length ? eve.map(e => e.name) : []
        dispatch({
            type: SET_PROPS,
            payload: {
                multiSelectSuites: value && value.length ? value.join(',').trim() : '',
                multiSelectOrders: panelName && panelName.length ? panelName.join(',').trim() : '',
            }
        })
    }
}

export function editSuitName(event) {
    return (dispatch) => {
        dispatch(toggleNotification(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                suiteName: event.target.value
            }
        })
    }
}

export function editPanelName(event) {
    return (dispatch) => {
        dispatch(toggleNotification(false));
        if (event) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    panelName: event.target.value
                }
            })
        }
    }
}

export function toggleOrderSuiteNameForm(suiteNameDisplay, history) {
    return (dispatch) => {
        dispatch(toggleNotification(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                suiteNameDisplay: suiteNameDisplay,
                panelNameNotFound: false,
                multiSelectOrders: ''
            }
        })
        dispatch(addTests(history, suiteNameDisplay, false))
    }
}

export function GetAllNoumenonNames() {
    return (dispatch) => {
        promisesList.push(axios.get(`${API_URL.GET_ALL_NOUMENON_NAMES}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    noumenonNameList: response.data,
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function getUnitsData() {
    return (dispatch) => {
        promisesList.push(axios.get(`${API_URL.UNITS_DATA}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    unitList: response.data,
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function setCustomFieldsFormValues() {
    return (dispatch, getState) => {
        let customFields = getState().visits && getState().visits.customFields ? getState().visits.customFields : []
        let noumenonNameList = getState().visits && getState().visits.noumenonNameList ? getState().visits.noumenonNameList : []
        let customFieldsFormValues = []
        dispatch(toggleLoading(true))
        if (customFields.length > 0) {
            customFields.map(cf => {
                let noumenon = noumenonNameList.find((nm) => nm.code == cf.noumenonCode)
                let disableUnitAndRange = noumenon ? (noumenon.valueType != 'Numeric') ? true : false : false
                let newCustomField = {
                    noumenonCode: cf.noumenonCode,
                    units: cf.units,
                    range: noumenon && noumenon.valueType == "Enumerable" ? {} : cf.rangeSet ? cf.rangeSet : cf.range,
                    disableUnitAndRange: disableUnitAndRange,
                    uuid: setUuid(),
                    noumenonName: noumenon ? noumenon.noumenonName : '',
                    status: constants.EXISTING_STATUS,
                    valueType: noumenon.valueType,
                    enumerableValuesList: noumenon.enumerableValues ? noumenon.enumerableValues.map(a => ({ range: a })) : [],
                    enumerableRange: noumenon && noumenon.valueType == "Enumerable" && cf.rangeSet ? cf.rangeSet : null
                }
                cf.status = constants.EXISTING_STATUS
                cf.range = noumenon && noumenon.valueType == "Enumerable" ? {} : cf.rangeSet ? cf.rangeSet : cf.range
                cf.enumerableRange = noumenon && noumenon.valueType == "Enumerable" && cf.rangeSet ? cf.rangeSet : null

                customFieldsFormValues.push(newCustomField)
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                customFieldsFormValues: [
                    ...customFieldsFormValues,
                ],
                customFields: customFields,
                duplicate_noumenon_name: []
            }
        })
        customFields && customFields.length == 0 ? dispatch(addCustomPanelFields()) : ''
        dispatch(toggleLoading(false))
    }
}

function setUuid() {
    const min = 1;
    const max = 1000;
    const rand = min + Math.random() * (max - min);
    return rand
}

export function addCustomPanelFields() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                customFieldsFormValues: [
                    ...getState().visits.customFieldsFormValues,
                    {
                        noumenonCode: "",
                        units: "",
                        range: '',
                        disableUnitAndRange: false,
                        uuid: setUuid(),
                        status: constants.NEW_STATUS,
                        noumenonName: '',
                        valueType: '',
                        enumerableValuesList: ''
                    }
                ],
                isDisableCustomFieldsBtn: true
            }
        })
    }
}
function fetchDuplicates(array) {
    let valueArr = array.map((item) => {
        return item.noumenonName
    });
    let duplicate_noumenon_name = []
    valueArr.some((item, idx) => {
        if (valueArr.indexOf(item) !== idx) {
            duplicate_noumenon_name.push(item)
        }
    });
    return duplicate_noumenon_name
}

function hasDuplicates(array) {
    let valueArr = array.map((item) => {
        return item.noumenonCode
    });
    let isDuplicate = valueArr.some((item, idx) => {
        return valueArr.indexOf(item) !== idx
    });
    return isDuplicate
}

export function removeCustomPanelFields(uuid) {
    return (dispatch, getState) => {
        let customFieldsFormValues = getState().visits.customFieldsFormValues
        let customFieldsError = getState().visits.customFieldsError ? getState().visits.customFieldsError : ''
        let isDisableCustomFieldsBtn
        let noumenonName
        let noumenonCode
        let duplicate_noumenon_name = getState().visits.duplicate_noumenon_name ? getState().visits.duplicate_noumenon_name : []
        noumenonCode = customFieldsFormValues.find((cf) => cf.uuid == uuid).noumenonCode
        getState().visits.noumenonNameList.find((nl) => nl.code == noumenonCode) ?
            noumenonName = getState().visits.noumenonNameList.find((nl) => nl.code == noumenonCode).noumenonName : ''
        noumenonName && duplicate_noumenon_name.includes(noumenonName) ? duplicate_noumenon_name.pop(noumenonName) : ''

        let deletedCustomField = customFieldsFormValues.find((cf) => cf.uuid == uuid && cf.status == "E")
        if (deletedCustomField) {
            deletedCustomField.status = constants.DELETE_STATUS
        }
        else {
            customFieldsFormValues = customFieldsFormValues.filter((cf) => cf.uuid != uuid)
        }

        if (customFieldsFormValues.length == 0) {
            isDisableCustomFieldsBtn = true
        }

        else if (hasDuplicates(customFieldsFormValues.filter((cf) => cf.status != constants.DELETE_STATUS))) {
            isDisableCustomFieldsBtn = true
        }
        else {
            customFieldsError = ''
            isDisableCustomFieldsBtn = false
        }

        if (customFieldsFormValues.filter((cf) => cf.status != constants.DELETE_STATUS).map(a => { return a.noumenonCode ? true : false }).includes(false) || duplicate_noumenon_name.length > 0) {
            isDisableCustomFieldsBtn = true
        }
        else {
            isDisableCustomFieldsBtn = false
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                customFieldsFormValues: [
                    ...customFieldsFormValues
                ],
                customFieldsError: customFieldsError,
                isDisableCustomFieldsBtn: isDisableCustomFieldsBtn,
                duplicate_noumenon_name: duplicate_noumenon_name
            }
        })
    }
}

export function editCostomPanelField(index, event, recId, uuid) {
    return (dispatch, getState) => {
        let customFieldsFormValues = getState().visits.customFieldsFormValues
        let customFieldsError = getState().visits.customFieldsError ? getState().visits.customFieldsError : ''
        let isDisableCustomFieldsBtn
        let noumenonNameList = getState().visits && getState().visits.noumenonNameList ? getState().visits.noumenonNameList : []
        let duplicate_noumenon_name = getState().visits.duplicate_noumenon_name ? getState().visits.duplicate_noumenon_name : []
        if (recId == 'noumenonName') {
            if (event != null) {
                let noumenon = noumenonNameList.find((nm) => nm.code == event.code)
                if (customFieldsFormValues) {
                    customFieldsFormValues[index].noumenonCode = event.code
                    customFieldsFormValues[index].noumenonName = noumenon ? noumenon.noumenonName : ''
                    customFieldsFormValues[index].valueType = event.valueType
                    customFieldsFormValues[index].enumerableValuesList = noumenon.enumerableValues ? noumenon.enumerableValues.map(a => ({ range: a })) : []

                    let formValue = customFieldsFormValues.find((a) => a.noumenonCode == event.code)
                    if (formValue && formValue.uuid != uuid) {
                        customFieldsError = labels.custom_fields_labels.duplicate_noumenon_name
                        isDisableCustomFieldsBtn = true
                        duplicate_noumenon_name.push(event.noumenonName)
                    }
                    else {
                        if (event.valueType != 'Numeric') {
                            customFieldsFormValues[index].disableUnitAndRange = true
                        }
                        else {
                            customFieldsFormValues[index].disableUnitAndRange = false
                        }
                        duplicate_noumenon_name = fetchDuplicates(customFieldsFormValues)
                        if (duplicate_noumenon_name) {
                            isDisableCustomFieldsBtn = true
                        }
                        else {
                            isDisableCustomFieldsBtn = false
                            customFieldsError = ''
                        }
                    }
                }
            }
            else {
                if (duplicate_noumenon_name.length > 0 && duplicate_noumenon_name.includes(customFieldsFormValues[index].noumenonName)) {
                    let tempCustomFields = customFieldsFormValues.filter((cf) => cf.noumenonCode != customFieldsFormValues[index].noumenonCode)
                    if (!hasDuplicates(tempCustomFields)) {
                        duplicate_noumenon_name.pop(customFieldsFormValues[index].noumenonName)
                        isDisableCustomFieldsBtn = false
                        customFieldsError = ''
                    }
                    else {
                        isDisableCustomFieldsBtn = true
                    }
                }

                customFieldsFormValues[index].noumenonCode = ''
                customFieldsFormValues[index].noumenonName = ''

            }
        }
        else if (recId == 'units') {
            if (event != null) {
                if (customFieldsFormValues) {
                    customFieldsFormValues[index].units = event.units
                }
            }
            else {
                customFieldsFormValues[index].units = ''
            }
        }

        else if (recId == 'referenceRange') {
            if (event != null) {
                if (customFieldsFormValues) {
                    if (customFieldsFormValues[index].valueType == "Enumerable") {
                        let value = event && event.length ? event.map(e => e.range) : []
                        customFieldsFormValues[index].enumerableRange = value && value.length ? value.join(',') : ''
                        customFieldsFormValues[index].range = {}
                    }
                    else {
                        customFieldsFormValues[index].range = event.target.value
                        customFieldsFormValues[index].enumerableRange = null
                    }
                }
            }
            else {
                customFieldsFormValues[index].range = {}
                customFieldsFormValues[index].enumerableRange = null
            }
        }

        if (customFieldsFormValues.filter((cf) => cf.status != constants.DELETE_STATUS).map(a => { return a.noumenonCode ? true : false }).includes(false) || duplicate_noumenon_name.length > 0) {
            isDisableCustomFieldsBtn = true
        }
        else {
            isDisableCustomFieldsBtn = false
        }
        duplicate_noumenon_name = fetchDuplicates(customFieldsFormValues)
        dispatch({
            type: SET_PROPS,
            payload: {
                customFieldsFormValues: [
                    ...customFieldsFormValues
                ],
                customFieldsError: duplicate_noumenon_name.length > 0 ? customFieldsError : "",
                isDisableCustomFieldsBtn: isDisableCustomFieldsBtn,
                duplicate_noumenon_name: duplicate_noumenon_name
            }
        })
    }
}

export function saveCustomPanelField(history) {
    return (dispatch, getState) => {
        var customFieldsError = null
        var visits = getState().visits
        let customFieldDTO = visits && visits.customFieldsFormValues
        let customFields = visits && visits.customFields
        if (customFieldDTO) {
            let currentVisit = visits.currentVisit
            let currentOrder = visits.currentOrder
            let gender = currentVisit.gender
            let panelName = currentOrder.panelName
            let siteId = parseInt(currentVisit.siteId)
            let customFieldDTO = visits.customFieldsFormValues
            customFieldDTO.map((fields) => {
                let existingField = customFields.find((cf) => cf.noumenonCode == fields.noumenonCode)
                if (JSON.stringify(existingField) != JSON.stringify(fields) && fields.status == constants.EXISTING_STATUS) {
                    fields.status = constants.CHANGE_STATUS
                }
            })
            customFieldDTO = customFieldDTO.filter((cfDTO) => cfDTO.status == constants.CHANGE_STATUS || cfDTO.status == constants.DELETE_STATUS || cfDTO.status == constants.NEW_STATUS)
            customFieldDTO.map((fields) => {
                if (!fields.enumerableRange) {
                    if (fields.range != null && typeof (fields.range) != "object")
                        setRangeInformat(fields)
                }
            })
            if (customFieldDTO.length > 0) {
                let CustomPanelFieldDTO = { panelName, siteId, gender, customFieldDTO }
                dispatch(toggleLoading(true))
                promisesList.push(axios.post(API_URL.CUSTOM_ORDER_FIELDS, { ...CustomPanelFieldDTO }).then((response) => {
                    customFieldDTO.map((cf) => delete cf["disableUnitAndRange"])
                    customFieldDTO.map((cf) => delete cf["noumenonName"])
                    customFieldDTO.map((cf) => delete cf["uuid"])
                    customFields.map((cf) => delete cf["rangeSet"])
                    customFieldDTO.map((cf) => delete cf["enumerableValuesList"])
                    customFieldDTO.map((cf) => delete cf["valueType"])
                    dispatch(toggleNotification(true, constants.notification_type.success, '',
                        [`${labels.custom_fields_labels.custom_fields_success} ${panelName}`]
                        , false))
                    dispatch(toggleLoading(false))
                    dispatch(setCustomFieldsFormValues())
                    dispatch(setDisableCustomFieldsBtn(true))
                    dispatch(setCurrentOrder(currentVisit.id, visits.currentOrder.orderId, history, `/visits/${currentVisit.id}/${safeWeb(panelName)}/${visits.currentOrder.orderId}`))

                }).catch(errors => {
                    customFieldsError = errors.response && errors.response.data ? errors.response.data : ''
                    dispatch(handleErrors(errors))
                }))
            }
            else {
                customFieldsError = labels.custom_fields_labels.no_changes_found
                dispatch(setCustomFieldsFormValues())
                dispatch(setDisableCustomFieldsBtn(true))
            }
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                customFieldsError: customFieldsError
            }
        })
    }
}

export function setDisableCustomFieldsBtn(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isDisableCustomFieldsBtn: status
            }
        })
    }
}

function setRangeInformat(fields) {
    if (fields && fields.range) {
        if ((fields.range).includes('<')) {
            fields.range = { low: null, high: fields.range.match(/[+-]?\d+(\.\d+)?/g)[0] }
        }
        else if ((fields.range).includes('>')) {
            fields.range = { low: fields.range.match(/[+-]?\d+(\.\d+)?/g)[0], high: null }
        }
        else if ((fields.range).includes('-')) {
            var ranges = fields.range.split(/(\d+)/g).filter(Boolean)
            var rangesArray = []
            for (let i = 0; i < ranges.length; i++) {
                if (ranges[i] == '.') {
                    rangesArray.push(ranges[i - 1] + '.' + ranges[i + 1])
                    i = i + 1
                }
                else if (ranges[i + 1] != '.') {
                    rangesArray.push(ranges[i])
                }
            }
            var range = []
            for (let j = 0; j < rangesArray.length; j++) {
                if (rangesArray[j] == '-' && (rangesArray.slice(j + 1, rangesArray.length).includes('--') || rangesArray.slice(j + 1, rangesArray.length).includes('-'))) {
                    range.push(parseFloat(rangesArray[j] + rangesArray[j + 1]))
                    j++;
                }
                else if (!isNaN(parseFloat(rangesArray[j]))) {
                    range.push(parseFloat(rangesArray[j]))
                }
                else if (rangesArray[j] == '--') {
                    range.push(parseFloat('-' + rangesArray[j + 1]))
                    j++;
                }
            }
            range.sort((a, b) => a - b)
            fields.range = { low: _.head(range), high: _.last(range) }
        }
    }
    else {
        fields.range = {}
    }
    return fields.range
}



export function getCustomFields() {
    return (dispatch, getState) => {
        var visits = getState().visits
        if (visits) {
            dispatch(toggleLoading(true))
            let currentVisit = visits.currentVisit
            let currentOrder = visits.currentOrder
            let gender = currentVisit.gender
            let panelName = currentOrder.panelName
            let siteId = currentVisit.siteId
            promisesList.push(axios.get(`${API_URL.GET_CUSTOM_Fields}/?siteId=${siteId}&panelName=${panelName}&gender=${gender}`).then(response => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        customFields: response.data,
                    }
                })
                dispatch(setCustomFieldsFormValues())
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
            dispatch(toggleLoading(false))
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    customFields: [],
                }
            })

        }
    }
}

export function setDocumentsFields() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    {
                        fileFileName: '',
                        title: '',
                        publicationDate: '',
                        uuid: setUuid(),
                        titleError: ''

                    }
                ]
            }
        })
    }
}

export function addDocumentFields() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...getState().visits.CreateDocumentList,
                    {
                        fileFileName: '',
                        title: '',
                        publicationDate: '',
                        uuid: setUuid(),
                        titleError: ''

                    }
                ]
            }
        })
    }
}

export function removeDocumentFields(uuid) {
    return (dispatch, getState) => {
        let CreateDocumentList = getState().visits.CreateDocumentList
        CreateDocumentList = CreateDocumentList.filter((cf) => cf.uuid != uuid)
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ]
            }
        })
    }
}

export function postDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        let CreateDocumentList = getState().visits.CreateDocumentList ? getState().visits.CreateDocumentList : []
        if (uploadedFile) {
            let doctype = uploadedFile.type
            var reader = new FileReader();
            reader.onload = function (e) {
                let avatarContent = e.target.result
                let currentCreateDocumentList = []
                currentCreateDocumentList = {
                    ...getState().visits.CreateDocumentList[index],
                    ...{
                        documentFile: avatarContent.split(',')[1],
                        fileContentType: doctype ? doctype : getAvatarType(avatarContent),
                        fileFileName: uploadedFile.name,
                        fileFileSize: uploadedFile.size,
                        title: getState().visits.CreateDocumentList[index] && getState().visits.CreateDocumentList[index].title && getState().visits.CreateDocumentList[index].title == uploadedFile.name.replace(/\.[^/.]+$/, '') ? getState().visits.CreateDocumentList[index].title : uploadedFile.name.replace(/\.[^/.]+$/, '')
                    }
                }
                let fileError = false;
                let titleError = false;
                if (!fileError) {
                    if (!(currentCreateDocumentList.documentFile)) {
                        currentCreateDocumentList.fileError = "File can't be blank"
                        fileError = true
                    }
                    else {
                        currentCreateDocumentList.fileError = ""
                        fileError = false
                    }
                }

                if (!titleError) {
                    if (!currentCreateDocumentList.title) {
                        currentCreateDocumentList.titleError = "Title can't be blank"
                        titleError = true
                    }
                    else {
                        currentCreateDocumentList.titleError = ""
                        titleError = false
                    }
                }

                if (!titleError) {
                    if ((currentCreateDocumentList.title && currentCreateDocumentList.title.length < 4)) {
                        currentCreateDocumentList.titleError = 'Title is too short (minimum is 4 characters)'
                        titleError = true
                    }
                    else {
                        currentCreateDocumentList.titleError = ''
                        titleError = false
                    }
                }
                CreateDocumentList[index] = currentCreateDocumentList
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: [
                            ...CreateDocumentList
                        ]
                    }
                })
                dispatch(addDocumentFields())
            }
            reader.readAsDataURL(eve.target.files[0]);
        }
    }
}

export function handlePublicationDateDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().visits.CreateDocumentList
        CreateDocumentList[index].publicationDate = eve
        if (CreateDocumentList[index].publicationDate && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].fileError = "File can't be blank"
        }
        else {
            CreateDocumentList[index].fileError = ""
        }

        if (CreateDocumentList[index].publicationDate && CreateDocumentList[index].title == "") {
            CreateDocumentList[index].titleError = "Title can't be blank"
        }
        else {
            CreateDocumentList[index].titleError = ""
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
                publicationDate: eve
            }
        })
    }
}

export function handleTitleDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().visits.CreateDocumentList
        CreateDocumentList[index].title = eve.target.value
        if (!CreateDocumentList[index].title) {
            CreateDocumentList[index].titleError = "Title can't be blank"
        }
        else {
            CreateDocumentList[index].titleError = ""
        }

        if ((CreateDocumentList[index].title && CreateDocumentList[index].title.length < 4)) {
            CreateDocumentList[index].titleError = 'Title is too short (minimum is 4 characters)'
        }
        else {
            CreateDocumentList[index].titleError = ''
        }
        if (CreateDocumentList[index].title && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].fileError = "File can't be blank"
        }
        else {
            CreateDocumentList[index].fileError = ""
        }

        if (CreateDocumentList[index].title == "" && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].titleError = ""
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}

function uploadDocumentList(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let CreateDocumentList = getState().visits.CreateDocumentList ? getState().visits.CreateDocumentList : []
        if (CreateDocumentList.length > 0) {
            CreateDocumentList.map((cdl, index) => {
                let currentDocument = []
                currentDocument = Object.assign({}, cdl,
                    {
                        visitId: getState().visits ? getState().visits.currentVisit.id : 0,
                        patientId: getState().visits ? getState().visits.currentVisit.patientId : 0,
                        visible: cdl.id ? parseInt(cdl.visible) : 1,
                        sourceType: 'User',
                        sourceId: getState().users ? getState().users.loggedInUser.id : 0,
                        publicationDate: cdl.id ? (getState().visits.publicationDate ? getState().visits.publicationDate : cdl.publicationDate) :
                            cdl.publicationDate ? cdl.publicationDate : null
                    })
                CreateDocumentList[index] = currentDocument
            })

            let titleError = false;
            let fileError = false;

            CreateDocumentList.map((cdl) => {
                if (!(cdl.documentFile)) {
                    cdl.fileError = "File can't be blank"
                    fileError = true
                }
                else {
                    cdl.fileError = ""
                    fileError = false
                }
                if (!(cdl.title)) {
                    cdl.titleError = "Title can't be blank"
                    titleError = true
                }
                else {
                    cdl.titleError = ""
                    titleError = false
                }

                if (!titleError) {
                    if ((cdl.title && cdl.title.length < 4)) {
                        cdl.titleError = 'Title is too short (minimum is 4 characters)'
                        titleError = true
                    }
                    else {
                        cdl.titleError = ''
                        titleError = false
                    }
                }
            })

            let validatedFile = CreateDocumentList.filter((cf) => cf.titleError == "" && cf.fileError == "")
            let unValidFile = CreateDocumentList.filter((cf) => cf.fileFileName == "" && (cf.title != "" || cf.publicationDate != null))
            let blankRows = CreateDocumentList.filter((cf) => cf.fileFileName == "" && cf.title == "" && cf.publicationDate == null)
            let blankRowUuids = 0;
            if (CreateDocumentList.length > 1 && blankRows.length > 0) {
                blankRowUuids = blankRows.map((row) => row.uuid)
            }

            if ((titleError || fileError) || unValidFile.length > 0) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: CreateDocumentList.filter((cf) => cf.uuid != [blankRowUuids])
                    }
                })
                dispatch(toggleLoading(false))
            }
            else if (!fileError || validatedFile.length > 0 && unValidFile.length == 0) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: validatedFile
                    }
                })
                let CreateDocumentList = cloneDeep(validatedFile)
                let params = { CreateDocumentList }
                params.CreateDocumentList =
                    CreateDocumentList = CreateDocumentList.map(s => {
                        if (s.publicationDate) {
                            s.publicationDate = moment(s.publicationDate).format('L');
                            return s
                        }
                        else { return s }
                    })
                promisesList.push(axios['post'](API_URL.DOCUMENT_URL, { ...params }).then(response => {
                    dispatch(toggleLoading(false))
                    history.push(`/visits/${getState().visits.currentVisit.id}`)
                    dispatch(clearDocumentsFields())
                }).catch(errors => {
                    dispatch(handleErrors(errors))
                    dispatch(handleStatusCodes(errors))
                    dispatch(toggleLoading(false))
                }))
            }
        }
        else {
            dispatch(toggleLoading(false))
        }
    }
}

export function clearDocumentsFields() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: []
            }
        })
    }
}
export function showImage(index) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].showImage = true
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}

export function hideImage(index) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].showImage = false
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}
function setNoumenonReferences(locale) {
    return (dispatch, getState) => {
        axios.get(`${API_URL.NOUMENON_REFERENCES}?locale=${locale}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    noumenonReferences: response.data
                }
            })
        })
    }
}
export function isPatientReportLoaded(loaded) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                patientReportLoaded: loaded
            }
        })
    }
}
export const actionCreators = {
    toggleVisitModal,
    updateVisit,
    editVisit,
    deleteVisit,
    setCurrentVisit,
    AddOrder,
    addNewOrder,
    deleteOrder,
    setCurrentOrder,
    setActiveTab,
    setCurrentResult,
    getOrders,
    editPanel,
    navigateOrder,
    setHeader,
    setHeaderType,
    updateResultStatus,
    saveResults,
    navigateToReport,
    getAllCategories,
    dataSelector,
    FollowUpData,
    navigateHealthAnalytics,
    downloadpdf,
    accessionSearchs,
    importMessagesbyAccessionNumber,
    updatedMessages,
    populateSearchParam,
    searchCustomName,
    getAllSections,
    orderDetails,
    downloadVisitRecommendations,
    getPanels,
    addTests,
    getCommonDocuments,
    setVisitDocuments,
    AttachDocument,
    getDocumentsWithVisitId,
    DeleteDistribution,
    uploadDocument,
    postFile,
    visitDocumentInput,
    handlePublicationDate,
    openSectionModal,
    closeSectionModal,
    downloadHistoricCategoryData,
    addToTableOfContents,
    setIsIndividualVisitPage,
    getPatientReportRecommendations,
    addQuestionnaire,
    addNewQuestionnaire,
    setCurrentQuestionnaire,
    closeQuestionnaireModal,
    getQuestionnaires,
    editMultiSelectOrders,
    editMultiSelectQuestionnaire,
    markQuestionnaireCompleted,
    editSuitName,
    editPanelName,
    toggleOrderSuiteNameForm,
    GetAllNoumenonNames,
    getUnitsData,
    setCustomFieldsFormValues,
    addCustomPanelFields,
    removeCustomPanelFields,
    editCostomPanelField,
    saveCustomPanelField,
    setDisableCustomFieldsBtn,
    updateUnitsAndEeferenceRange,
    getCustomFields,
    setDocumentsFields,
    addDocumentFields,
    removeDocumentFields,
    postDocumentFile,
    handlePublicationDateDocumentFile,
    handleTitleDocumentFile,
    uploadDocumentList,
    clearDocumentsFields,
    showImage,
    hideImage,
    deleteDocument,
    setCalledSetCurrentOrder,
    isPatientReportLoaded,
    addToTableOfContentsArray,
    getPatientReportProblems
}