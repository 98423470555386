import sprite from '../../../assets/images/sprite_base.svg';
import moment from 'moment';
import Sprite from './SpriteIcon';
import { uniformString, getDateFormat } from '../tools/helpers';
import expandMore from '../../../assets/images/expand_more.svg';
import expandLess from '../../../assets/images/expand_less.svg';
import expandMoreLight from '../../../assets/images/expand_more_light.svg';
import expandLessLight from '../../../assets/images/expand_less_light.svg';
import edit from '../../../assets/images/edit.svg'
import editLight from '../../../assets/images/edit_light.svg'
import delete_icon from '../../../assets/images/delete_red.svg'
import React, {useRef,useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import constants from '../../../config/constants'
import labels from '../../../config/localization';
import _ from 'lodash';
import Sortable from 'sortablejs'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { Height } from '@material-ui/icons';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

function RecommendationRowComponent(props) {
  const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false);
  useEffect(() => {
    // componentDidMount //
  return () => {
    // componentWillUnmount() //
  }
}, [])
    
    let [expanded, setExpanded] = React.useState(false);
    let toggleExpansion = () => { setExpanded(!expanded) };
    const saveRecommendation = (metaRec) => {
      props.deleteMetaRecommendation(props.metaRecordIndex);
      props.saveRecommendation(props.history, false, metaRec);
    }
    let renderToggle = (expanded) => {
        if (expanded) {
          return (
            <img className='' src={props.theme == "light-theme" ? expandLess : expandLessLight}></img>
          );
        }
        else {
          return (
            <img className='' src={props.theme == "light-theme" ? expandMore : expandMoreLight}></img>
          );
        }
    }
    let editIcon = () =>{
      return (props.theme == "light-theme" ? edit : editLight)
    }

    let fadeRecommendationRow = (rec) => {
        if (rec.endedAt && moment(Date()).format("YYYY-MM-DD") > moment(rec.endedAt).format("YYYY-MM-DD")) {
          return 'ended'
        }
        else {
          return 'active'
        }
    }
    let renderSubheadingClassName = (rec, props) => {
      var isCurrent;
        if (rec.visitDate) {
            if (new Date(props.updated_lastVisitDate) <= new Date(rec.visitDate) && fadeRecommendationRow(rec) == 'active') {
              isCurrent = true;
            }
            else {
              isCurrent = false;
            }
        }
      return isCurrent
    }  

    let renderBodyRow = (rec) => {
        
        return (
          <div className={`jw-recommendation-card`}>
            {/* {props.loggedInUser &&
              (props.loggedInUser.role == constants.logged_roles.AN
                || props.loggedInUser.role == constants.logged_roles.CG)
              && props.recommendations.length > 1 && rec.isStopped != 1 &&
              <i class="fa fa-align-justify fa-lg my-auto mr-3 handle"></i>} */}
            {/* <div className="jw-icon">
              <Sprite group="recommendations" label={rec.group} sprite={uniformString(rec.group)} />
            </div> */}
            <div className={`jw-recommendation-content row no-gutters pr-2`} >
                  
                {/* <div className='col-lg-12 col-md-12 col-sm-12 col-12'> */}
                  {/* <div className='row pl-15'> */}
                    <div className='col-lg-1 col-md-1 col-sm-1 align-self-center col-2'>
                      <div className="jw-icon d-flex">
                     { props.loggedInUser &&
                      (props.loggedInUser.role == constants.logged_roles.AN
                        || props.loggedInUser.role == constants.logged_roles.CG)
                        && props.recommendations.length > 1 && !readOnly && props.recommendationType == "active" &&( props.history.location.hash == "#treatment_plan" ||  props.history.location.hash == "#medication") ?
                          <DragIndicatorOutlinedIcon className='drag-icon align-self-center' />
                        : ""
                      }
                      <Sprite group="recommendations" label={rec.group} sprite={uniformString(rec.group)} />
                      </div>
                    </div>  
                    <div className={` ${props.recommendationType == "stopped" ? 'col-lg-9 col-md-9 col-sm-9 col-5' : 'col-lg-10 col-md-9 col-sm-9 col-9'} align-self-center pl-0`}>
                        <div className={` d-flex mr-2 ${expanded ? 'recommendation-body-text-expanded' : 'recommendation-body-text'}`}>
                          {rec.body + " " + ( rec.body ? rec.body.includes(rec.strength) ? "" : rec.strength ? rec.strength : "" : "")}
                          { renderSubheadingClassName(rec, props) ? 
                            <span className={`new-label mt-1 ml-2`}>
                              <span className='new-text'>New</span>
                            </span>
                        : "" }
                        </div> 
                            
                        {!props.isMeta ? basedOnText(rec) : ""}
                        { props.isMeta ? <div> {`(based on your ${rec.noumenaList})`} </div> : ""}
                    </div>
                    <div className={`jw-recommendation-toggle d-flex justify-content-end ${props.recommendationType == "stopped" ? 'col-lg-2 col-md-2 col-sm-2 col-5' : 'col-lg-1 align-self-center col-sm-1 col-md-1 col-1'} ${expanded ? '' : ''}`}>
                       <div className='d-flex'>
                          { expanded && !readOnly ? renderActions(rec) : '' }
                          <div className={`${props.isMeta? '' : ""} align-self-center`}>    
                            <div className='expaned-div'>
                                {renderToggle(expanded)}
                            </div>    
                          </div>
                        </div>  

                    </div>
                  {/* </div>   */}
                {/* </div>   */}
               
            </div>   
            <div className='pr-15 pl-15'>
                {expanded ? renderDetailsRow(props.recommendation) : null}
            </div>
          </div>
        );
    }
    let renderActions = (rec) =>{
            { return ( !rec.isStopped ?
                props.isDemo || props.loggedInUser && (props.loggedInUser.role == constants.logged_roles.AN || props.loggedInUser.role == constants.logged_roles.CG)
                ? 
                <div className="d-flex">
                    { props.isMeta ? 
                      <div className="mr-2" title='Edit Recommendation' onClick={() => {
                        props.toggleRecommendationModal(true);
                        props.updateMetaRecommendations(props.index)
                        props.toggleMetaRecommendations(true);
                        props.setMetaRecordIndex(props.index);
                        props.setMetaRecord(rec) }}>
                        <img src={editIcon()}/>
                        </div>
                      : 
                      <div  className="mr-2" title={`Edit ${props.recType}`} onClick={() => {
                        props.toggleRecommendationModal(true);
                        props.updateRecommendations(rec.id)
                        props.toggleMetaRecommendations(false)
                        props.setMetaRecord(null)}}>
                        <img src={editIcon()}/>
                      </div>
                    }
                     {rec.firstRecommendationVisit == props.presentPatient.latestVisitId || props.isDemo ?
                        <img className='mr-3' onClick={() => props.toggleDeleteRecommendationPopup(true, rec.id, rec)} src={delete_icon}></img>
                    : 
                    ''}

                    {rec.firstRecommendationVisit != props.presentPatient.latestVisitId && !props.isDemo && !props.isMeta ?
                        <div className="ml-2 icon md fill clickable stop" title={`Stop ${props.recType}`} onClick={() => {
                            props.toggleStopRecommendationPopup(true, rec.id);
                        }}>
                            <i class="fa fa-stop-circle danger" style={{ fontSize: "21px", lineHeight: "1.7rem" }}></i>
                        </div> 
                    : ''}

                    { props.isMeta ?
                      <div className="icon md fill clickable stop mr-2" title={`Resume ${props.recType}`} onClick={() => {
                          saveRecommendation(rec);
                      }}>
                          <i className="fa fa-check meta-resume-style" aria-hidden="true"></i>
                      </div> 
                    : ''}

                   { props.isMeta ?
                      <div className="mr-2" title={`Ignore ${props.recType}`}>
                        <i className="fa fa-times meta-recommend-ignore-style" aria-hidden="true" onClick={() => { props.deleteMetaRecommendation(props.index, rec); }}></i>
                      </div>
                    : ''}

                </div> : ''
                : 
                <div className='d-flex recommendation-info'>
                  {`Provider has recommended on ` + moment(rec.previousRecommendationDate).format(getDateFormat(props.presentPatient.datePreference)) + ' and stopped on ' + moment(rec.stoppedAt).format(getDateFormat(props.presentPatient.datePreference))}
                  {props.role != 'patient' && !props.isDemo ?
                      <div className="icon md fill clickable mr-2" title={`Resume ${props.recType}`} onClick={() => {
                      props.toggleStopRecommendationPopup(true, rec.id, 'current');
                      }}>
                      <i className="fa fa-play-circle" style={{ fontSize: "21px", lineHeight: "1.7rem" }}></i>
                      </div> : ''}
                </div>
                )
            }
    }

    let  renderDetailsRow = (rec) =>  {
        // var flag = true
        return (
          <div className="jw-recommendation-details" key={rec.id + '-details'} id={rec.id + '-details'}>
            {brand_ingredient(rec)}
            {strength(rec)}
            {recommendationStrengthType(rec)}
            {instruction(rec)}
            {time(rec)}
            {notes(rec)}
            {/* {startedAt(rec)} */}
            {endedAt(rec)}
            {updatedStrength(rec)}
            {updatedInstructions(rec)}
          </div>
        );
    }

    let brand_ingredient = (recommendation) => {
        if (recommendation.brand && recommendation.brand.trim()) {
          return (
            <div className="recommendation-details">
              <span className='heading-1'>{labels.analytics_labels.brand_ingredient}:</span> <span className='text-1'>{recommendation.brand}</span>
            </div>
          )
        }
        else {
          return (null);
        }
    }
    
    let strength = (recommendation) => {
        if (recommendation.strength && recommendation.strength.trim()) {
            return (
            <div className="recommendation-details">
                <span className='heading-1'>Dose:</span><span className='text-1'> {recommendation.strength}</span>
            </div>
            )
        }
        else {
            return (null);
        }
    }

    let instruction = (recommendation)=>{
        if (recommendation.instructions && recommendation.instructions.trim()) {
            return (
            <div className="recommendation-details">
                <span className='heading-1' >Instructions:</span> <span className='text-1'>{recommendation.instructions}</span>
            </div>
            )
        }
        else {
            return (null);
        }
    }
    let time = (rec)=>{
      if (rec.isMidDayRecomendedTime || rec.isMorningRecomendedTime || rec.isNightRecomendedTime) {
          return (
            <div className="recommendation-details">
                <span className='heading-1'>Time: </span>
                <span className='text-1'>
                  {[
                      rec.isMorningRecomendedTime && 'Morning',
                      rec.isMidDayRecomendedTime &&'MidDay',
                      rec.isNightRecomendedTime &&'Night'
                  ].filter(Boolean).join(', ')}
                </span>
            </div>
          )
      }
      else {
          return (null);
      }
    }

    let recommendationStrengthType = (recommendation) =>  {
        if (recommendation.recommendationStrengthType && recommendation.recommendationStrengthType.trim()) {
            return (
            <div className="recommendation-details">
                <span className='heading-1'>{labels.analytics_labels.howStronglyRecommended}:</span><span className='text-1'> {recommendation.recommendationStrengthType}</span>
            </div>
            )
        }
        else {
            return (null);
        }
    }

    let notes = (rec) =>  {
    // remove show_notes flag
        if (rec.notes) {
          let notes = rec.notes
          if (typeof notes === 'string') {
            notes = notes.trim()
            notes = notes.split("\n")
          }
            return (
              <div className="recommendation-details">
                  <strong className='heading-1'>Notes:</strong>
                  &nbsp;
                  {notes.map((note, index) => 
                    index + 1 === 1 ? (
                      <span key={index} className='text-1'>{`${index + 1}. `} {note}</span>
                    ) : (
                      <div key={index} className='text-1 pl-5 ml-2'>{`${index + 1}. `} {note}</div>
                    )
                  )} 
              </div>
          )
        }
        else {
            return (null);
        }
    }

    let startedAt = (recommendation) => {
        if (recommendation.startedAt && recommendation.startedAt.trim()) {
            return (
            <p className="recommendation-details">
                <strong>Start Date:</strong> {moment(recommendation.startedAt).format(getDateFormat(props.presentPatient.datePreference))}
            </p>
            )
        }
        else {
            return (null);
        }
    }

    let getStrongDate = (date) =>  {
        return <strong>{(moment(date).format(getDateFormat(props.presentPatient.datePreference)))}</strong>
    }
    let endedAt = (recommendation) => {
        if (recommendation.startedAt) {
            if (recommendation.endedAt && recommendation.endedAt.trim()) {
            return (
                moment(new Date()).format("YYYY-MM-DD") > moment(recommendation.endedAt).format("YYYY-MM-DD") ?
                <p className="recommendation-details"><div className='text-1'>Started {getStrongDate(recommendation.startedAt)}, Ended {getStrongDate(recommendation.endedAt)}.</div></p> :
                <p className="recommendation-details"><div className='text-1'>Patient is taking up this from {getStrongDate(recommendation.startedAt)} and will be ending up taking this on {getStrongDate(recommendation.endedAt)}.</div></p>
            )
            }
            else {
            return (
                <p className="recommendation-details">
                <div className='text-1'>Started {getStrongDate(recommendation.startedAt)}.</div>
                </p>
            )
            }
        }
        else if (recommendation.endedAt && recommendation.endedAt.trim()) {
            return (
            moment(Date()).format("YYYY-MM-DD") > moment(recommendation.endedAt).format("YYYY-MM-DD") ?
                <p className='recommendation-details'>
                    <div className='text-1'>Ended {getStrongDate(recommendation.endedAt)}</div>
                </p> :
                <p className='recommendation-details'> Patient will be ending up taking this on {getStrongDate(recommendation.endedAt)}</p>
            )
        }
        else {
            return (null);
        }
    }
    let basedOnText = (rec) => {
        let flag = true;
        return(
        <>
        {props.historic_data_chart ? Object.keys(props.historic_data_chart).map(ad => {
          if ((typeof (props.historic_data_chart) == constants.object) && ((constants.biographical_labels.ages[ad] && rec.indicators) ? (constants.biographical_labels.ages[ad].toLowerCase() == _.head(rec.indicators)) : '')) {
            return (
              <React.Fragment>
                <p key="ages" className={`${expanded ? "recommendation-indicator" : "recommendation-indicator"}`} >
                  (based on your {constants.biographical_labels.age_label_lowercase[rec.indicators]} being {constants.biographical_labels.ages[ad] && rec.indicators ?
                    constants.biographical_labels.ages[ad].toLowerCase() == _.head(rec.indicators) ? ` ${props.historic_data_chart[ad].filter(s => s.visitId == rec.visitId).length > 0 ?
                      _.head(props.historic_data_chart[ad].filter(s => s.visitId == rec.visitId)).value : (props.historic_data_chart[ad] && props.historic_data_chart[ad].length ? _.last(props.historic_data_chart[ad]).value : '')} 
                        ${labels.orders_labels.years}` : '' : ''})
                </p>
              </React.Fragment>)
          }
        }) : ''}
        {props.categories ? props.categories.map(t => t.sections.map(sec => sec.data.map(d => {
          if (d.code == _.head(rec.indicators) && flag && !Object.values(constants.biographical_labels.ages).map(s => s.toLowerCase()).includes(d.code)) {
            flag = false;
            return (
              <React.Fragment>
                <p key={d.label} className={`${expanded ? "recommendation-indicator" : "recommendation-indicator"}`} >
                  (based on your {d.label} being {d.visitId == rec.visitId ? d.value : d.historicData
                    && d.historicData.filter(s => s.visitId == rec.visitId).length > 0 ? _.head(d.historicData.filter(s => s.visitId == rec.visitId)).value : d.value} {d.units})
                </p>
              </React.Fragment>)
          }
        }))) : ''}
        {props.categories ? props.categories.map(t => {
          if (t.key == _.head(rec.indicators)) {
            return (<React.Fragment>
              <p key={t.key} className={`${expanded ? "recommendation-indicator" : "recommendation-indicator"}`} >
                (based on your {t.name})
              </p>
            </React.Fragment>)
          }
        }) : ''}
        {props.categories ? props.categories.map(s => s.sections.map(t => {
          if (t.key == _.head(rec.indicators)) {
            return (<React.Fragment>
              <p key={t.key} className={`${expanded ? "recommendation-indicator" : "recommendation-indicator"}`} >
                (based on your {t.name})
              </p>
            </React.Fragment>)
          }
        })) : ''}
        </>
        )
    }

    let updatedStrength = (rec) =>{
        if ((rec.previousStrength && rec.strength && rec.previousStrength != rec.strength) || (rec.strength == "" || rec.strength == null) && rec.previousStrength  ){
          return (
          <div className="recommendation-details">
              { rec.previousStrength && rec.strength && rec.previousStrength != rec.strength ?
                <span className='text-1'>{`We updated the dose from ${rec.previousStrength} to ${rec.strength} on ${moment(rec.createdAt).format(getDateFormat(props.presentPatient.datePreference))}`}</span>
              :
              (rec.strength == "" || rec.strength == null) && rec.previousStrength ?
                <span className='text-1'>{`We removed the dose ${rec.previousStrength} on ${moment(rec.createdAt).format(getDateFormat(props.presentPatient.datePreference))}`}</span>
              : null
              }  
          </div>
          )
        }
    }

    let updatedInstructions = (rec) =>{
      if ((rec.previousInstructions && rec.instructions && rec.previousInstructions != rec.instructions) || (rec.instructions == "" || rec.instructions == null) && rec.previousInstructions  ){
        return (
        <div className="recommendation-details">
            { rec.previousInstructions && rec.instructions && rec.previousInstructions != rec.instructions ?
              <span className='text-1'>{`We updated the instructions from ${rec.previousInstructions} to ${rec.instructions} on ${moment(rec.createdAt).format(getDateFormat(props.presentPatient.datePreference))}`}</span>
            :
            (rec.instructions == "" || rec.instructions == null) && rec.previousInstructions ?
              <span className='text-1'>{`We removed the instructions ${rec.previousInstructions} on ${moment(rec.createdAt).format(getDateFormat(props.presentPatient.datePreference))}`}</span>
            : null
            }  
        </div>
        )
      }
    }

    return (
      <>
        { 
          props.isMeta ?
          <div className={`recommendation-body meta-recommendation-body ${expanded ? 'pt-2 pb-2 active-card' : ""} `} onClick={ ()=>{ toggleExpansion()}}>
              {renderBodyRow(props.recommendation)}
          </div>
        :
          <div onClick={ ()=>{ toggleExpansion()}} className={`recommendation-body ${expanded ? 'pt-2 pb-2 active-card' : ""} ${fadeRecommendationRow(props.recommendation)}`}  key={props.recommendation.id} data-id={props.recommendation.id} style={(props.loggedInUser &&
            (props.loggedInUser.role == constants.logged_roles.AN
              || props.loggedInUser.role == constants.logged_roles.CG)
            && props.recommendations.length > 1 && props.recommendationType == "active" &&( props.history.location.hash == "#treatment_plan" ||  props.history.location.hash == "#medication")) ? { cursor: 'move' } : { '-webkit-user-drag': 'none'}}>
            {renderBodyRow(props.recommendation)}
          </div>
      }
    </>  
    );
}

export default connect()(RecommendationRowComponent);
