import React, {useState, useEffect} from 'react'; 
const onboardingVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/onboarding_video.mp4'
const IntroToAnalyticsVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/intro_to_Analytics_for_providers.mp4';
const SystemOrientationVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/system_orientation.mp4';
const AddaddingPatientVisitVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/adding_patient_visit.mp4';
const DocumentLibraryVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/document_library.mp4';
const ReviewingResultsVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/reviewing_results.mp4';
const CustomizeOptimalRangesVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/customize_optimal_ranges.mp4';
const ImportingLabResultsVideo = 'https://stphysioagedata001.blob.core.windows.net/videos/importing_lab_results.mp4';

function OnboardingVideo(props) {
    const [videoLink, setVideoLink] = useState('');
    let videoSrc = props.match.params && props.match.params.videoSrc ? props.match.params.videoSrc : ""
    
    useEffect(() => {
        if(videoSrc == 'intro_to_analytics'){
            setVideoLink(IntroToAnalyticsVideo)
        }
        else if (videoSrc == 'system_orientation') {
            setVideoLink(SystemOrientationVideo);
        } 
        else if (videoSrc == 'adding_patient_visit') {
            setVideoLink(AddaddingPatientVisitVideo);
        }
        else if (videoSrc == 'document_library') {
            setVideoLink(DocumentLibraryVideo);
        }
        else if (videoSrc == 'reviewing_results') {
            setVideoLink(ReviewingResultsVideo);
        }
        else if (videoSrc == 'customize_optimal_ranges') {
            setVideoLink(CustomizeOptimalRangesVideo);
        }
        else if (videoSrc == 'importing_lab_results') {
            setVideoLink(ImportingLabResultsVideo);
        }
        else {
            setVideoLink(onboardingVideo);
        }
    }, [videoSrc]); 
    return (
        <div className='container onboarding-video'>
            { videoLink ? (
                <video controls >
                    <source src={videoLink} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <p>Loading video...</p>
            )}
        </div>
    );
}

export default OnboardingVideo;
