import moment from 'moment';
import axios from '../../../lib/axios';
import metadataAxios from '../../../lib/metadataAxios'
import API_URL from '../../../config/api';
import { datasetSelector, gpaSelector, timeBoundarySelector, gradeSelector } from '../selectors/datasetSelector';
import { toggleLoading, toggleNotification, handleErrors, setPatientReportStatus, handleStatusCodes, setLoggedInUser, handleLoading, toggleReportLoading, setBreadCrumbs, headerNavigation } from '../../../reducers/global-reducer';
import { setSitePreference } from '../tools/calendar';
import _ from 'lodash';
import constants from '../../../config/constants';
import { logOut } from '../../../reducers/global-reducer';
import labels from '../../../config/localization';
import { isFirefox, b64toBlob, isIE, isSafari, getValidSafariDate } from '../../../utils/download-utils'
import reportlogo from '../../../assets/images/android-icon-144x144.png';
import { roundNumber, uniformString } from '../tools/helpers';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import tru from '../../../../src/tru_visitsummary.svg';
import cloneDeep from 'lodash/cloneDeep';
import { getDateFormat } from '../tools/helpers';
import { setCurrentVisit, setHeaderType } from '../../Visits/modules/visits';
import visitsummaryStyles from '../components/VisitSummaryStyles';
import messages from '../../../config/messages';
import medicationStyles from '../components/MedicationListStyle';
import masthead_blue from '../../../assets/images/analytics-banner-inverted.svg';
import masthead_white from '../../../assets/images/analytics-banner-solid.svg';


// Analytics UI

import demoData from '../demoAnalyticsApi';
import { toast } from 'react-toastify';

const SET_PROPS = 'SET_PROPS';
const requiredFields = ['group', 'body']
const requiredFieldText =
{
    group: 'Group',
    body: 'Body',
}

const problemRequiredFields = ['clinicalStatus', 'verificationStatus', 'category', 'recordedDate']
const problemRequiredFieldText =
{
    clinicalStatus: 'Clinical Status',
    verificationStatus: 'Verification Status',
    category: 'Category',
    recordedDate: 'Recorded Date'
}

const requiredFieldsProviderNotes = ["notes"]

const requiredFieldsProviderNotesText =
{
    notes: 'Notes',
}

const requiredFieldsFeedback = ["rating"]

const requiredFieldsFeedbackText =
{
    feedback_text: 'Rating',
}

const medicationRequiredFields = ['group', 'body']
const medicationRequiredFieldText =
{
    group: 'Group',
    body: 'Body'
}

const notifySuccess = (message) => toast.success(message);
const notifyError = (message) => toast.error(message);

export function setCurrentHealthAnalytics(patientId) {
    return (dispatch) => {
        dispatch(toggleLoading(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                presentPatient: {},
                isDemo: false,
                currentPatientDocumentsList: [],
                Data: null,
                categories: null
            }
        })
        if (patientId) {

            dispatch(setAnalytics(patientId))
        } else {
            axios.get(API_URL.USERS_URL).then((userresponse) => {
                dispatch(setAnalytics(userresponse.data.patientId))
            }).catch(error => {
                dispatch(handleErrors(error))
            })
        }

    }
}

function toDataURL(url, name) {
    return (dispatch) => {

        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        [name]: reader.result
                    }
                })
                return (reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
}
function setNoumenonReferences(locale) {
    return (dispatch, getState) => {
        axios.get(`${API_URL.NOUMENON_REFERENCES}?locale=${locale}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    noumenonReferences: response.data
                }
            })
        })
    }
}

function setAnalyticsData(patientId, isSummary, summaryVisitId, locale, history, isMobile) {
    return (dispatch, getState) => {
        axios.get(`${API_URL.PATIENTS_URL}/${patientId}`).then((response) => {
            let role = getState().global && getState().global.loggedInUser
                && getState().global.loggedInUser.role ? getState().global.loggedInUser.role
                : constants.logged_roles[localStorage.getItem(constants.role)]
            let presentPatient = { ...response.data };
            let updated_lastVisitDate = presentPatient.latestVisitDate ? moment(presentPatient.latestVisitDate).format('L') : 'no-visits'
            let dateOfBirth = (presentPatient.datePreference == constants.safari_invalid_date_format && isSafari())
                ? moment(getValidSafariDate(presentPatient.dob)).format('L')
                : moment(presentPatient.dob).format('L');
            let age = Math.floor(Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true)))
            let age_round = roundNumber((Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true))), 1)
            let patient_age = roundNumber((Math.abs(moment(new Date().toLocaleDateString('en-US')).diff(dateOfBirth, 'years', true))), 1)
            dispatch(getRecommendations(summaryVisitId ? summaryVisitId : presentPatient.latestVisitId, locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : "en", patientId))
            dispatch(getTerms(locale ? locale : response.data.patientLanguagePref, isSummary))
            dispatch(setNoumenonReferences(locale ? locale : response.data.patientLanguagePref))
            // dispatch(getCustomRecommendation(presentPatient.siteId))
            // dispatch(getCustomSigs(presentPatient.siteId))
            dispatch(getProblems(locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : "en", patientId))
            axios.get(`${API_URL.SITE_URL}/${presentPatient.siteId}`).then((siteResponse) => {
                dispatch(getAnalyticsSurveyData(presentPatient.latestVisitId, locale ? locale : response.data.patientLanguagePref))
                let siteData = { ...siteResponse.data };
                let dateFormat = siteData.datePreference;
                let lastVisitDate = presentPatient.latestVisitDate ? setSitePreference(dateFormat, presentPatient.latestVisitDate) : ''
                var yearForRecommendations = moment(lastVisitDate).format('DD-MM-YYYY');
                if (siteData.enableDocuments) {
                    promisesList.push(axios.get(`${API_URL.PATIENT_DOCUMENTS_VISITS}?patientId=${patientId}`).then(response => {
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                currentPatientDocumentsList: (response.data.value && response.data.value.length
                                    && response.data.value.filter(s => s.visible == 1).length) ? response.data.value.filter(s => s.visible == 1) : null
                            }
                        })
                    }).catch(errors => {
                        dispatch(handleErrors(errors))
                        dispatch(handleStatusCodes(errors))
                    }))
                }
                dispatch(toDataURL(reportlogo, 'reportlogo'))
                dispatch(toDataURL(tru, 'tru'))
                dispatch(setSiteTheme(siteData.id))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        presentPatient,
                        age,
                        siteData,
                        lastVisitDate,
                        updated_lastVisitDate,
                        visit_count: presentPatient.visit_count,
                        //biographical_info
                        dateFormat, yearForRecommendations, role,
                        latestVisitInfo: presentPatient.latestVisitDate,
                        isMenuActive: false,
                        age_round,
                        patient_age               
                    }
                })
                dispatch(providerDetails(presentPatient.providerId))
                dispatch(setAllVisitData(presentPatient.id, summaryVisitId, locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : 'en'))
                dispatch(getAllCategories(summaryVisitId ? summaryVisitId : presentPatient.latestVisitId
                    , locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : 'en'
                    , isSummary, history, isMobile))
                // dispatch(setBiomarkersOfAging(presentPatient.id,
                //     locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : 'en'
                //     , summaryVisitId))

            }).catch((error) => {
                dispatch(handleErrors(error))
                dispatch(handleSummaryLoading(false))
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
            dispatch(handleStatusCodes(error))
            dispatch(handleSummaryLoading(false))
        })
    }
}

function setSiteTheme(siteID){
    return (dispatch) => {
        let siteTheme = constants.DEFAULT_THEME
        if (siteID == process.env.REACT_APP_VIAVI_ID){
            siteTheme = constants.VIAVI_THEME
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                siteTheme
            }
        })
    }   
}

function setAnalytics(patientId, isSummary, visitId, locale, history, isMobile) {
    return (dispatch, getState) => {
        dispatch(setLoggedInUser())
        if (isSummary) {
            dispatch(toggleLoading(true))
            promisesList.push(dispatch(setAnalyticsData(patientId, isSummary, visitId, locale, history, isMobile)))
        } else {
            dispatch(setAnalyticsData(patientId, isSummary, visitId, locale))
        }
    }
}

function handleSummaryLoading(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isSummaryLoading: status
            }
        })
    }
}

export function providerDetails(id) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentProvider: {}
            }
        })
        axios.get(`${API_URL.PROVIDERS_URL}/${id}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentProvider: response.data
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        })
    }
}

export function getTerms(locale, isSummary) {
    return (dispatch) => {
        axios.get(`${API_URL.TERM_TRANSLATIONS}?locale=${locale}`).then(response => {
            let terms = {}
            if (isSummary)
                response.data.map(t => { terms[t.key] = t.termTranslationsDTOList.text.replace(/％/g, '').replace(/%/g, '') })
            else
                response.data.map(t => { terms[t.key] = t.termTranslationsDTOList.text })
            dispatch({
                type: SET_PROPS,
                payload: {
                    terms
                }
            })
        }).catch(error => dispatch(handleErrors(error)))
    }
}

function exitAnalytics(patientId, history) {
    return (dispatch, getState) => {
        let role = getState().global && getState().global.loggedInUser
            && getState().global.loggedInUser.role ? getState().global.loggedInUser.role
            : constants.logged_roles[localStorage.getItem(constants.role)]
        if (role == 'patient') {
            dispatch(logOut(history))
        } else {
            history.push(`/patients/${patientId}`)
        }

    }
}

function resetLoader(patientId) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                loadingData: "pending",
                loadingCategories: "pending",
                loadingSections: "pending",
                loadingPatient: "pending",
                loadingRecommendations: "pending",
                loadingMedications: "pending",
                loadingProblemList: "pending",
                presentPatient: {},
                siteData: null,
                historic_data_chart: null,
                categories: null,
                customCategories: null
            }
        })
        dispatch(setCurrentHealthAnalytics(patientId))

    }
}
export function toggleBiomarkerComparePopup(result) {
    return (dispatch, getstate) => {
        let biomarkerComparisonList = getstate().analytics.biomarkerComparisonList ? getstate().analytics.biomarkerComparisonList : []
        if (!result) {
            biomarkerComparisonList = []
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                showBiomarkerCompareModal: result,
                biomarkerComparisonList
            }
        })
    }
}

export function round(value, scale) {
    return Number(Math.round(value + 'e' + scale) + 'e-' + scale).toFixed(scale);
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    visit_count: 0,
    isMenuActive: false,
    age: 0,
    categories: null,
    presentPatient: {},
    currentPatientAging: {},
    currentVisit: {},
    lastVisitDate: null,
    loadingData: "pending",
    loadingPatient: "pending",
    loadingCategories: "pending",
    downloadableFile: null,
    isInternetError: false,
    showPlanModal: false,
    showMedicationModal: false,
    openValueModal: false,
    noumenonValue: null,
    noumenonLabel: null,

    // Anaylitics UI
    showProviderNotes: false,
    showNewNotes: false,
    providerNotesForm: {},
    sectionData: {},
    isCardLoading: false,
    providerNotes: [],
    filterType: "default",
    recommendationType: "active",
    isLeftNavOpen: false,
    isRecommendationList: true,
    drawerType: null,
    ages: null,
    ageName: null,
    biomarkerComparisonList: [],
    recType: "",
    surveySubmitted: false,
    surveyPopup: false,
    showSurveyToaster: false,
    filterRecommendations: [],
    showCategoryDetailsModal: false,
    theme: 'light-theme',
    feedbackForm: {},
    filterProblems: [],
    siteTheme: constants.DEFAULT_THEME,
    customCategories: null,
    siteSpecificOverallGpa: null,
    isPatientDemo: false,
    isMedicationList: false,
    masthead_path_noLogo: masthead_blue,
    masthead_path_logo: masthead_white,
}

// Provider notes //

export function toggleProviderNotes(value) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        dispatch(toggleNewNotes(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                showProviderNotes: value,
                providerNotesErrors: []
            }
        })
    }
}

export function toggleNewNotes(value) {
    return (dispatch, getState) => {
        if (!value) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    providerNotesForm: {}
                }
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                showNewNotes: value,
                providerNotesErrors: []
            }
        })

    }
}

export function getProviderNotes() {
    return (dispatch, getState) => {
        let patient = getState().analytics.presentPatient;
        dispatch({
            type: SET_PROPS,
            payload: {
                stopNotesLoading: false
            }
        })
        axios.get(`${API_URL.GET_PROVIDERS_NOTES}/${patient.id}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    providerNotes: response.data.providerNotesHistoryDTO,
                    patientId: response.data.patientId,
                    stopNotesLoading: true
                }
            })
        }).catch((error) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    providerNotes: [],
                    stopNotesLoading: true
                }
            })
        })
    }
}

export function newProviderNotes() {
    return (dispatch, getState) => {
        let patient = getState().analytics.presentPatient
        let providerNotesForm = {
            "PatientId": patient.id,
            "VisitId": patient.latestVisitId,
            "ProviderId": patient.providerId
        };
        dispatch(toggleNewNotes(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                providerNotesForm
            }
        })
    }
}

export function updateProviderNotes(id) {
    return (dispatch, getState) => {
        let providerNotes = getState().analytics.providerNotes;
        let providerNotesForm = {};
        providerNotesForm = _.head(providerNotes.filter(notes => notes.id == id));
        dispatch(toggleNewNotes(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                providerNotesForm
            }
        })
    }
}

export function deleteProviderNotes(id) {
    return (dispatch, getState) => {
        dispatch(toggleNewNotes(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                providerNotes: [],
                stopNotesLoading: false
            }
        })
        axios.delete(`${API_URL.DELETE_PROVIDER_NOTES}/${id}`).then(response => {
            dispatch(getProviderNotes());
            // notifyError("Provider notes deleted successfully")

        })
    }
}

export function saveProviderNotes(history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                stopNotesLoading: false
            }
        })
        let providerNotesForm = getState().analytics.providerNotesForm ? getState().analytics.providerNotesForm : ''
        if (providerNotesForm) {
            let req = requiredFieldsProviderNotes.filter(rf => !providerNotesForm[rf] || providerNotesForm[rf] == "")
            if (req && req.length) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        providerNotesErrors: req.map(r => `${requiredFieldsProviderNotesText[r]} ${labels.required_label}`),
                        providerNotesForm: providerNotesForm,
                        stopNotesLoading: true
                    }
                })
            }
            else {
                dispatch(toggleNewNotes(false))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        providerNotes: []
                    }
                })
                promisesList.push(axios[providerNotesForm.id ? 'put' : 'post'](providerNotesForm.id ? API_URL.UPDATE_PROVIDER_NOTES : API_URL.CREATE_PROVIDER_NOTES, { ...providerNotesForm }).then(response => {
                    let message
                    if (providerNotesForm.id) {
                        message = "Provider notes updated successfully"
                    }
                    else {
                        message = "Provider notes created successfully"
                    }
                    // notifySuccess(message)
                    dispatch(getProviderNotes())
                }).catch(error => {
                    dispatch(handleErrors(error))
                }))
            }
        }
    }
}

export function editProviderNotes(event) {
    return (dispatch, getState) => {
        let providerNotesForm = { ...getState().analytics.providerNotesForm }
        let key = event.target.id
        let value = event.target.value
        if (getState().analytics.providerNotesForm && getState().analytics.providerNotesForm[key] != value) {
            providerNotesForm = getState().analytics.providerNotesForm ? { ...getState().analytics.providerNotesForm, [key]: value } : { [key]: value }
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                providerNotesForm,
                providerNotesErrors: []
            }
        })
    }
}

export function toggleRecommendationModal(value) {
    return (dispatch, getState) => {
        let groupName = getState().analytics.sectionValues && getState().analytics.sectionValues.groupName ?
            getState().analytics.sectionValues.groupName
            : 'General Instructions'
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm: { group: groupName },
                initialRecommendationForm: { group: groupName },
                recommendation_list: setRecommendationList(getState().analytics, groupName),
                brand_list: setBrandList(getState().analytics, groupName),
                strength_list: [],
                instruction_list: [],
                notes_list: [],
                notes_templates_list: []

            }
        })
        dispatch(showNoFormChangesError(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                showRecommendationModal: value,
            }
        })
    }
}


export function filterCategoryData(type) {
    return (dispatch, getState) => {
        if (type == "byRisk") {
            dispatch(toggleLoading(true))
            dispatch({
                type: SET_PROPS,
                payload: {
                    filterType: type
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    filterType: type
                }
            })
        }
        dispatch(toggleLoading(false))
    }
}

export function getAllRecommendations() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        metadataAxios.get(process.env.REACT_APP_MEDICATION_BLOB_URL).then((response) => {
            let sortableKey = constants.recommendation_groups
            let groups_recommendations = response.data.groupDTO.map((rec) => { return { id: rec.id, value: rec.name } }).sort((a, b) => {
                return sortableKey.indexOf(a.value) - sortableKey.indexOf(b.value);
            });
            dispatch({
                type: SET_PROPS,
                payload: {
                    all_recommendations_data: response.data,
                    groups_recommendations: groups_recommendations,
                    // brand_list: response.data.medicationBrandsDTO.map((brand) => { return { value: brand } }),
                    strengthTypes: response.data.strengthTypesDTO ? [...(constants.blankOption), ...response.data.strengthTypesDTO] : [],
                    loadingMedications: "finished"
                }
            })
            dispatch(getCustomRecommendation())
            dispatch(getCustomSigs())
            dispatch(toggleLoading(false))
            dispatch(toDataURL(masthead_white, "recommendationSiteLogo"))
        }).catch((error) => {
            dispatch(toggleLoading(false))
            dispatch(handleErrors(error))
        })
    }
}

export function getCustomRecommendation(siteId){
    return (dispatch, getState) => {
        if (getState().analytics && !getState().analytics.isDemo){
            dispatch(toggleLoading(true))
            let presentPatient = getState().analytics.presentPatient ? getState().analytics.presentPatient : []
            siteId = presentPatient && presentPatient.siteId 
            if (siteId){
                let all_recommendations_data = getState().analytics.all_recommendations_data && getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
                let recommendationsGroupDTOS = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
                axios.get(`${API_URL.GET_CUSTOM_RECOMMNEDATION}?siteId=${siteId}`).then((response) => {
                    Array.prototype.push.apply(recommendationsGroupDTOS, response.data)
                    recommendationsGroupDTOS = [...new Set(recommendationsGroupDTOS)]
                    all_recommendations_data.recommendationsGroupDTO = recommendationsGroupDTOS
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            all_recommendations_data: all_recommendations_data
                        }
                    })
                dispatch(toggleLoading(false))
                }).catch((error) => {
                    dispatch(toggleLoading(false))
                    dispatch(handleErrors(error))
                })
            }    
        }
    }
}

export function getCustomSigs(siteId){
    return (dispatch, getState) => {
        if (getState().analytics && !getState().analytics.isDemo){
            dispatch(toggleLoading(true))
            let presentPatient = getState().analytics.presentPatient ? getState().analytics.presentPatient : []
            siteId = presentPatient && presentPatient.siteId
            if (siteId){
                let all_recommendations_data = getState().analytics.all_recommendations_data && getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
                let recommendation_SIGs = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []
                axios.get(`${API_URL.GET_CUSTOM_SIG}?siteId=${siteId}`).then((response) => {
                    Array.prototype.push.apply(recommendation_SIGs, response.data)
                    recommendation_SIGs = [...new Set(recommendation_SIGs)]
                    all_recommendations_data.recommendationSigsDTO = recommendation_SIGs
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            all_recommendations_data: all_recommendations_data
                        }
                    })
                dispatch(toggleLoading(false))
                }).catch((error) => {
                    dispatch(toggleLoading(false))
                    dispatch(handleErrors(error))
                })
            }
        }    
    }
}



export function setCurrentPage(history) {
    return (dispatch, getState) => {
        let currentPage = _.last(history.location.hash.split("#"))
        if (getState().analytics.showBiomarkerCompareModal)
            dispatch(toggleBiomarkerComparePopup(false))
        if (getState().analytics.showRecommendationModal)
            dispatch(toggleRecommendationModal(false))
        if (getState().analytics.isMetaRecommendation)
            dispatch(toggleMetaRecommendations(false));
        if (getState().analytics.showProviderNotes)
            dispatch(toggleProviderNotes(false))
        if (getState().analytics.showCategoryDetailsModal)
            dispatch(toggleCategoryDetailsModal(false))    

        dispatch({
            type: SET_PROPS,
            payload: {
                currentPage: currentPage,
            }
        })
    }
}
export function togglePages(navbar, history, curretValue, isDemo, isPatientDemo) {
    return (dispatch, getState) => {
        if (navbar) {
            history.push(`#${navbar}`)
        }
        else {
            if (history.location && history.location.hash) {
                if (isDemo){
                    history.push(`demo_1${history.location.hash}`)
                    navbar = _.last(history.location.hash.split("#"))
                }
                else if (isPatientDemo){
                    history.push(`demo${history.location.hash}`)
                    navbar = _.last(history.location.hash.split("#"))
                }

                else{
                    history.push(`analytics${history.location.hash}`)
                    navbar = _.last(history.location.hash.split("#"))
                }
            }

        }
        let currentCategory = getState().analytics.currentCategory ? getState().analytics.currentCategory : null
        let currentNomenon = getState().analytics.currentNomenon ? getState().analytics.currentNomenon : null

        if (!curretValue) {
            currentCategory = null
            currentNomenon = null
        }
        if (navbar && navbar != "biomarker_of_age"){
            dispatch(setDefaultBiomarkerComparisonList())
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPage: navbar,
                history,
                currentCategory,
                currentNomenon
            }
        })
    }
}


export function editRecommendation(event, recId) {
    return (dispatch, getState) => {
        let recommendationForm = { ...getState().analytics.recommendationForm }
        let brand_list = getState().analytics.brand_list ? getState().analytics.brand_list : []
        let metaRecommendationForm = { ...getState().analytics.metaRecommendationForm }
        let recommendation_list = getState().analytics.recommendation_list ? getState().analytics.recommendation_list : []
        let strength_list = getState().analytics.strength_list ? getState().analytics.strength_list : []
        let instruction_list = getState().analytics.instruction_list ? getState().analytics.instruction_list : []
        let notes_list = getState().analytics.notes_list ? getState().analytics.notes_list : []
        let all_recommendations_data = getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
        let recommendationsGroupDTO = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
        let recommendationSigsDTO = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []
        let recommedationDTO;
        let recommendation_list_option;
        let recommendationStrength;
        let brand_names = []
        let brand_name_list = []
        let notes_templates_list = getState().analytics.notes_templates_list ? getState().analytics.notes_templates_list : []

        if (recId == 'brand') {
            if (event != null && getState().analytics.recommendationForm && getState().analytics.recommendationForm.brand != _.head(event).value) {
                recommendationForm = { ...getState().analytics.recommendationForm, brand: _.head(event).value, body: "", strength: '', recommendationStrengthType: '', instructions: '', notes: '', productType: '', isMorningRecomendedTime: 0, isMidDayRecomendedTime: 0, isNightRecomendedTime: 0 }

                if (recommendationsGroupDTO) {
                    if (recommendationForm.brand) {
                        recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(getState().analytics.recommendationForm.group))
                            .filter((recommendation) => recommendation.brandNames.includes(recommendationForm.brand))
                            .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
                    }
                    else {
                        recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(recommendationForm.group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
                    }
                }
                else {
                    recommendation_list = []
                }
            }
        }
        else if (recId == 'body') {
            if (event != null && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body != _.head(event).value) {
                recommendationForm = { ...getState().analytics.recommendationForm, body: _.head(event).value, strength: '', recommendationStrengthType: '', instructions: '', notes: '',isMorningRecomendedTime: 0, isMidDayRecomendedTime: 0, isNightRecomendedTime: 0}
                if (recommendationsGroupDTO && _.head(event).value && recommendation_list) {
                    recommendation_list_option = recommendation_list.find((r) => r.value == _.head(event).value)
                    
                    if (recommendation_list_option && !recommendation_list_option.isCustom ) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
                        recommendationForm = { ...recommendationForm, productType: recommedationDTO.productType}
                    }

                    else if( recommendation_list_option && recommendation_list_option.isCustom) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.body == recommendation_list_option.value && rec.groupNames.includes(recommendationForm.group) )
                        if (recommendationForm.brand != ""){
                            recommedationDTO = recommendationsGroupDTO.find((rec) => rec.body == recommendation_list_option.value && rec.groupNames.includes(recommendationForm.group) && rec.brandNames.includes(recommendationForm.brand) )
                        }
                    }
                    else
                        strength_list = []    
                    if (recommedationDTO && recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == recommendationForm.productType)){
                        let sigs = [];
                        sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == recommendationForm.productType)
                        instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s.sig, isMorningRecomendedTime: s.isMorningRecomendedTime, isMidDayRecomendedTime: s.isMidDayRecomendedTime, isNightRecomendedTime: s.isNightRecomendedTime } })).flat()
                    }
                    else if(recommedationDTO && !recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == "")){
                        let sigs = [];
                        sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == "")
                        instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s.sig, isMorningRecomendedTime: s.isMorningRecomendedTime, isMidDayRecomendedTime: s.isMidDayRecomendedTime, isNightRecomendedTime: s.isNightRecomendedTime } })).flat()
                    }
                    else
                        instruction_list = []
                    if (recommedationDTO && recommedationDTO.recommendationNoteTemplates)
                        notes_templates_list = recommedationDTO.recommendationNoteTemplates.map((temp) => { return { value: temp} })
                    else
                        notes_templates_list = []
                    
                    if (recommedationDTO && recommedationDTO.recommendationStrengths)
                        strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                    else
                        strength_list = []
                }
                else {
                    strength_list = []
                }
            }
        }

        else if (recId == 'strength') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, strength: _.head(event).value }
                }
                else {
                    let initialRecommendationForm = getState().analytics.initialRecommendationForm;
                    let instructionAndNotes = setInstructionsAndNotes(initialRecommendationForm, _.head(event).value)
                    recommendationForm = { ...getState().analytics.recommendationForm, strength: _.head(event).value, recommendationStrengthType: '' } //, instructions: instructionAndNotes.instructions, notes: instructionAndNotes.notes }
                }
                if (recommendationsGroupDTO && _.head(event).value && recommendation_list) {
                    let recommendation = (metaRecommendationForm && getState().analytics.isMetaRecommendation) ? metaRecommendationForm.body : recommendationForm.body
                    recommendation_list_option = recommendation_list.find((r) => r.value == recommendation)

                    if (recommendation_list_option && recommendation_list_option.id) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
                    }
                    else {
                        notes_list = []
                    }
                    if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                        recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id
                            && event.id ? event.id == rec.strengthId : _.head(event).value == rec.strength)
                    }
                    else {
                        notes_list = []
                    }
                    if (recommendationStrength && recommendationStrength.recommendationInstructions){
                        Array.prototype.push.apply(instruction_list, recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } }))
                    }

                    if (recommendationStrength && recommendationStrength.recommendationNotes)
                        notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
                    else
                        notes_list = []
                }
                else {
                    notes_list = []
                }
            }
        }

        else if (recId == 'recommendationStrengthType') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, recommendationStrengthType: event.target.value }
                }
                else {
                    recommendationForm = { ...getState().analytics.recommendationForm, recommendationStrengthType: event.target.value }
                }
            }
        }
        else if (recId == 'instruction') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, instructions: _.head(event).value, 
                                            isMorningRecomendedTime: _.head(event).isMorningRecomendedTime,
                                            isMidDayRecomendedTime: _.head(event).isMidDayRecomendedTime,
                                            isNightRecomendedTime: _.head(event).isNightRecomendedTime}
                }
                else {
                    recommendationForm = { ...getState().analytics.recommendationForm, instructions: _.head(event).value, 
                                            isMorningRecomendedTime: _.head(event).isMorningRecomendedTime,
                                            isMidDayRecomendedTime: _.head(event).isMidDayRecomendedTime,
                                            isNightRecomendedTime: _.head(event).isNightRecomendedTime}
                }
            }
        }
        else if (recId == 'notes') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, notes: _.head(event).value }
                }
                else {
                    recommendationForm = { ...getState().analytics.recommendationForm, notes: _.head(event).value }
                }
            }
        }
        else if (recId == 'startedAt') {
            if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, startedAt: event }
            }
            else {
                recommendationForm = { ...getState().analytics.recommendationForm, startedAt: event }
            }
        }

        else if (recId == 'endedAt') {
            if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, endedAt: event }
            }
            else {
                recommendationForm = { ...getState().analytics.recommendationForm, endedAt: event }
            }
        }
        else {
            let key = event.target.id
            let value = event.target.value
            if (getState().analytics.recommendationForm && getState().analytics.recommendationForm[key] != value) {
                recommendationForm = getState().analytics.recommendationForm ? 
                                    { ...getState().analytics.recommendationForm, [key]: value, brand: '', body: '', strength: '', 
                                    recommendationStrengthType: '0', instructions: '', notes: '', productType: '', 
                                    isMorningRecomendedTime: 0, isMidDayRecomendedTime: 0, isNightRecomendedTime: 0 } 
                                    : { [key]: value, body: '', strength: '', recommendationStrengthType: '0', instructions: '', 
                                    notes: '', isMorningRecomendedTime: 0, isMidDayRecomendedTime: 0, isNightRecomendedTime: 0 }                     
            }
            else {
                recommendationForm = getState().analytics.recommendationForm
            }
            if (recommendationsGroupDTO) {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(value)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
            }
            
            else {
                recommendation_list = []
            }
            if (recommendation_list) {
                recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(value)).map((rec) => brand_names.push(rec.brandNames))
                brand_name_list = [...new Set(brand_names.flat())]
                if (brand_name_list)
                    brand_list = brand_name_list.map((brand) => { return { value: brand } })
                else {
                    brand_list = []
                }
            }
            else {
                brand_list = []
            }
            strength_list = []
            instruction_list = []
            notes_list = []
            notes_templates_list = []
        }
        dispatch(showNoFormChangesError(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm,
                brand_list,
                recommendation_list: recommendation_list,
                strength_list: strength_list,
                notes_templates_list: notes_templates_list,
                instruction_list: instruction_list,
                notes_list: notes_list,
                recommendationErrors: [],
                metaRecommendationForm
            }
        })
    }
}

function actionType(recommendation, resumeRecommendation) {
    if (recommendation.isStopped == 1)
        return "stopped"
    else if (resumeRecommendation)
        return 'resumed'
    if (recommendation.id)
        return "updated"
    else if (!recommendation.id)
        return "created"
}

export function saveRecommendation(history, isPlanModal, metaRec) {
    return (dispatch, getState) => {
        let rectype = getState().analytics && getState().analytics.recType ? getState().analytics.recType : "Recommendation"
        let recommendations = getState().analytics;
        let recommendForm = recommendations.recommendationForm
        let metaFlag = false;
        let isDemo = getState().analytics.isDemo
        let isPatientDemo = getState().analytics.isPatientDemo
        if (getState().analytics.isMetaRecommendation) {
            recommendForm = getState().analytics && getState().analytics.metaRecommendationForm;
            metaFlag = true;
        }
        if (metaRec) {
            recommendForm = metaRec;
        }

        let groupName = getState().analytics.sectionValues && getState().analytics.sectionValues.groupName ?
            getState().analytics.sectionValues.groupName
            : 'General Instructions'
        let demoPath = window.location.pathname.indexOf('demo_1') == 1 ? 'demo_1' : 'demo_2';
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationsLoading: true,
                recommendationForm: ''
            }
        })
        var currentRecommendation = null;
        if (getState().analytics.drawerType == "biomarker") {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: isDemo || isPatientDemo ? [isPlanModal ? '' : (recommendations ? recommendations.ages : '')] : {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : (recommendations ? recommendations.ages : '')
                }
            }
        }
        else if (getState().analytics.drawerType == "category") {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: isDemo || isPatientDemo ? [isPlanModal ? '' : (recommendations ? recommendations.currentCategory.key : '')] : {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : (recommendations ? recommendations.currentCategory.key : '')
                }
            }
        }
        else if (getState().analytics.drawerType == "section") {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: isDemo || isPatientDemo ? [isPlanModal ? '' : (recommendations ? recommendations.currentSection.key : '')] : {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : (recommendations ? recommendations.currentSection.key : '')
                }
            }
        }
        else {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: isDemo || isPatientDemo ? [isPlanModal ? '' : ((recommendations && recommendations.datumValues) ? recommendations.datumValues.code : '')] : {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : ((recommendations && recommendations.datumValues) ? recommendations.datumValues.code : '')
                }
            }
        }
        currentRecommendation['recommendationId'] = recommendations && recommendForm ? recommendForm.id : 0
        currentRecommendation['isStopped'] = recommendForm.isStopped ? recommendForm.isStopped : 0
        currentRecommendation['priorVisitId'] = recommendations.presentPatient.priorVisitId
        currentRecommendation['patientId'] = recommendations.presentPatient.id
        currentRecommendation['visitId'] = recommendations.presentPatient.latestVisitId
        currentRecommendation['siteId'] = recommendations.presentPatient.siteId
        currentRecommendation['endedAt'] = currentRecommendation.endedAt ? moment(currentRecommendation.endedAt).format('YYYY-MM-DD HH:mm:ss') : null
        currentRecommendation['startedAt'] = currentRecommendation.startedAt ? moment(currentRecommendation.startedAt).format('YYYY-MM-DD HH:mm:ss') : null
        currentRecommendation['isCustomRecommendation'] = getState().analytics.recommendation_list && getState().analytics.recommendation_list.find((r) => r.value == recommendForm.body) || (recommendForm.body == "" || recommendForm.body == null)  ?  'false' : 'true' 
        currentRecommendation['IsCustomSig'] = getState().analytics.instruction_list && getState().analytics.instruction_list.find((r) => r.value == recommendForm.instructions) || (recommendForm.instructions == "" || recommendForm.instructions == null) ?  'false' : 'true' 
        currentRecommendation['notes'] = recommendations && recommendForm && recommendForm.notes && recommendForm.notes.length  ? recommendForm.notes.join('\n') : ""
        let req = requiredFields.filter(rf => (!currentRecommendation[rf] || parseInt(currentRecommendation['group']) == 0))
        if (req && req.length) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationErrors: req.map(r => `${requiredFieldText[r]} ${labels.required_label}`),
                    isRecommendationsLoading: false,
                    recommendationForm: { group: currentRecommendation.group, body: currentRecommendation.body, strength: currentRecommendation.strength, recommendationStrengthType: currentRecommendation.recommendationStrengthType, instructions: currentRecommendation.instructions, notes: currentRecommendation.notes, startedAt: currentRecommendation.startedAt, endedAt: currentRecommendation.endedAt },
                    metaRecommendationForm: { group: currentRecommendation.group, body: currentRecommendation.body, recommendationStrengthType: currentRecommendation.recommendationStrengthType }
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationErrors: [],
                    groupName: groupName
                }
            })
            dispatch(toggleRecommendationModal(false))
            dispatch(toggleMetaRecommendations(false));


            notifySuccess(`${rectype} ${actionType(currentRecommendation, getState().analytics.resumeRecommendation)} successfully`)
            if (!isDemo && !isPatientDemo) {
                axios[currentRecommendation.id ? 'put' : 'post'](API_URL.RECOMMENDATION_URL, { ...currentRecommendation }).then((response) => {
                    dispatch(getRecommendations())
                    // if (isPlanModal)
                    //     dispatch(togglePlanModal(false))
                    if (metaRec)
                        dispatch(updateMetaRecommendationCodes(metaRec));
                    if (metaFlag) {
                        dispatch(updateMetaRecommendationCodes(getState().analytics && getState().analytics.metaRecord ? getState().analytics.metaRecord : []));
                        metaFlag = false;
                    }
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            initialRecommendationForm: { group: groupName },
                            resumeRecommendation: false,
                            recommendationCurrentRow: null,
                            recommendation_list: setRecommendationList(getState().analytics, groupName),
                            brand_list: setBrandList(getState().analytics, groupName)
                        }
                    })
                    dispatch(showNoFormChangesError(false));
                }).catch(errors => {
                    let recError = errors.response && errors.response.data ? errors.response.data : ''
                    if (errors.response && errors.response.status == constants.status_codes.unauthorized)
                        dispatch(handleErrors(errors))
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            recommendationErrors: recError ? typeof (recError) == 'string' ? [recError] : recError.value ? [recError.value] : null : null,
                            isRecommendationsLoading: false,
                            recommendationForm: { group: groupName, body: null },
                            initialRecommendationForm: { group: groupName },
                            resumeRecommendation: false,
                            recommendationCurrentRow: null
                        }
                    })
                    dispatch(showNoFormChangesError(false));
                    if (metaRec) {
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                recommendationErrors: []
                            }
                        })
                    }
                    dispatch(toggleRecommendationModal(false))
                    notifySuccess(`${rectype} ${actionType(currentRecommendation, getState().analytics.resumeRecommendation)} successfully`)
                })
            }
            else {
                let allRecommendations = getState().analytics.recommendations
                    ? getState().analytics.recommendations : demoData.recommendationsInfo[demoPath]

                currentRecommendation = {
                    ...currentRecommendation
                }
                let finalRec = []
                allRecommendations.map(rec => {
                    if (rec.id && rec.id == currentRecommendation.id)
                        finalRec.push(currentRecommendation)
                    else finalRec.push(rec)
                })
                if (!currentRecommendation.id && finalRec.filter(s => s.id === undefined && (_.head(s.indicators) == _.head(currentRecommendation.indicators))).length < 200) {
                    currentRecommendation = {
                        ...currentRecommendation,
                        id: Math.floor((Math.random() * 1000000) + 1)
                    }

                    finalRec.push(currentRecommendation)
                }

                // if (isPlanModal)
                //     dispatch(togglePlanModal(false))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isRecommendationsLoading: false,
                        recommendationForm: { group: groupName, body: null },
                        metaRecommendationForm: { group: groupName, body: null },
                        currentRecommendation: null,
                        // recommendations: finalRec,
                        recommendations: finalRec && finalRec.length ? finalRec.filter(s => s.isStopped != 1) : [],
                        medications: finalRec && finalRec.length ? finalRec.filter((rec) => constants.medication_groups.includes(rec.group)) : [],
                        initialRecommendationForm: { group: groupName },
                        recommendationCurrentRow: null,
                        recommendation_list: setRecommendationList(getState().analytics, groupName),
                        brand_list: setBrandList(getState().analytics, groupName)
                    }
                })
            }
        }
    }
}

export function deleteMetaRecommendation(index, metaRec) {
    return (dispatch, getState) => {
        let metaRecommendations = getState().analytics && getState().analytics.metaRecommendations ? [...getState().analytics.metaRecommendations] : [];
        let metaRecExpanded = getState().analytics && getState().analytics.metaRecExpanded ? [...getState().analytics.metaRecExpanded] : [];
        metaRecommendations.splice(index, 1);
        metaRecExpanded.splice(index, 1);
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecommendations: metaRecommendations,
                metaRecExpanded: metaRecExpanded
            }
        })
        if (metaRec)
            dispatch(updateMetaRecommendationCodes(metaRec));
    }
}

export function updateMetaRecommendationCodes(metaRec) {
    return (dispatch, getState) => {
        let metaRecommendations = getState().analytics && getState().analytics.metaRecommendations ? [...getState().analytics.metaRecommendations] : [];
        let metaRecommendationCodes = getState().analytics && getState().analytics.metaRecommendationCodes ? [...getState().analytics.metaRecommendationCodes] : [];
        metaRec && metaRec.noumenaCodes && metaRec.noumenaCodes.length > 0 ? metaRec.noumenaCodes.forEach((metaCode) => {
            let flag = false;
            metaRecommendations.map((rec) => {
                if (rec.body != metaRec.body) {
                    rec && rec.noumenaCodes && rec.noumenaCodes.length > 0 ? rec.noumenaCodes.map((code) => {
                        if (code == metaCode)
                            flag = true;
                    }) : null;
                }
            })
            if (!flag) {
                let index = metaRecommendationCodes.findIndex((c) => {
                    return c == metaCode;
                });
                if (index != -1) {
                    metaRecommendationCodes.splice(index, 1);
                }
            }
        })
            : null;

        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecommendationCodes: metaRecommendationCodes

            }
        })
    }
}


export function getRecommendations(visitId, locale, patientId) {
    return (dispatch, getState) => {

        let id = visitId ? visitId : getState().analytics.presentPatient.latestVisitId;
        locale = locale ? locale : "en"
        patientId = patientId ? patientId : getState().analytics.presentPatient.id
        let groupName = getState().analytics.groupName ? getState().analytics.groupName : 'General Instructions'
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendations: [],
                isRecommendationsLoading: true
            }
        })
        axios.get(`${API_URL.RECOMMENDATION_URL}/${id}?locale=${locale}&patientId=${patientId}&timestamp=${new Date().getTime()}`).then((response) => {
            
            let recommendations = response.data;
            let sortableKey = constants.recommendation_groups
            recommendations = recommendations.sort((a, b) => {
                return sortableKey.indexOf(a.group) - sortableKey.indexOf(b.group);
            });
            let initialPriorities = recommendations.map(u => {
                return {
                    priority: u.priority,
                    recommendationId: u.id
                }
            })
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendations: recommendations && recommendations.length ? recommendations.filter(s => s.isStopped != 1) : [],
                    currentVisitRecommendations: recommendations && recommendations.length ? recommendations.filter(s => s.visitId == id) : '',
                    stoppedRecommendations: recommendations && recommendations.length ? recommendations.filter(s => s.isStopped == 1) : [],
                    isReorder: false,
                    initialPriorities,
                    isRecommendationsLoading: false,
                    loadingRecommendations: "finished",
                    recommendationForm: { group: groupName },
                    metaRecommendationForm: { group: groupName },
                    recommendationType: "active",
                    filterRecommendations: []
                }
            })
            dispatch(getMedication())
        }).catch((error) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationsLoading: false,
                    loadingRecommendations: constants.notification_type.error
                }
            })
            dispatch(handleErrors(error))
        })
    }
}

export function toggleMetaRecommendations(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isMetaRecommendation: value
            }
        })
    }
}

export function setMetaRecordIndex(metaIndex) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecordIndex: metaIndex
            }
        })
    }
}

export function setMetaRecord(metaRecord) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecord: metaRecord
            }
        })
    }
}

export function showNoFormChangesError(val) {
    return (dispatch, getState) => {
        let recommendationErrors = getState().analytics.recommendationErrors ? [...getState().analytics.recommendationErrors] : [];
        if (val)
            recommendationErrors.push(labels.no_recommandation_changes);
        else {
            let errIndex = recommendationErrors.indexOf(labels.no_recommandation_changes);
            recommendationErrors.splice(errIndex, 1);
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationErrors: recommendationErrors
            }
        })
    }
}

function setRecommendationList(analytics, groupName, brand) {
    let recommendation_list = [];
    if (analytics && analytics.all_recommendations_data && analytics.all_recommendations_data.recommendationsGroupDTO) {
        let recommendation = analytics.all_recommendations_data.recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(groupName))
        if (brand) {
            recommendation_list = recommendation.filter((rec) => rec.brandNames.includes(brand))
                .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom} })
        }
        else {
            recommendation_list = recommendation ? recommendation.map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } }) : []
        }
    }
    return [...new Map(recommendation_list.map(item => [JSON.stringify(item), item])).values()]
    // .slice(0,20);
}

export function setFilterRecommendation() {
    return (dispatch, getState) => {
        let filteredRecommendations = getState().analytics.recommendations ? getState().analytics.recommendations : []
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredRecommendations: filteredRecommendations
            }
        })
    }

}



function setBrandList(analytics, groupName) {
    let brand_list = [];
    let brand_name_list = [];
    let brand_names = [];
    let recommendationsGroupDTO = analytics.all_recommendations_data && analytics.all_recommendations_data.recommendationsGroupDTO ? analytics.all_recommendations_data.recommendationsGroupDTO : []
    if (recommendationsGroupDTO) {
        recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(groupName)).map((rec) => brand_names.push(rec.brandNames))
        brand_name_list = [...new Set(brand_names.flat())]
        if (brand_name_list)
            brand_list = brand_name_list.map((brand) => { return { value: brand } })
        else {
            brand_list = []
        }
    }
    return brand_list
}

function setStrengthList(analytics, recommendation, recommendation_list) {
    let recommendationsGroupDTO = analytics.all_recommendations_data && analytics.all_recommendations_data.recommendationsGroupDTO ? analytics.all_recommendations_data.recommendationsGroupDTO : []
    let recommendation_list_option;
    let recommedationDTO;
    let strength_list;
    if (recommendationsGroupDTO && recommendation_list && recommendation) {
        recommendation_list_option = recommendation_list.find((r) => r.value == recommendation)
        if (recommendation_list_option && recommendation_list_option.id) {
            recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
        }
        else
            strength_list = []
        if (recommedationDTO && recommedationDTO.recommendationStrengths)
            strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
        else
            strength_list = []
    }
    else {
        strength_list = []
    }
    return strength_list
}

function setInstructionsAndNotes(initialForm, value) {
    let instructions;
    let notes;
    if (value == "" && initialForm && initialForm.strength && initialForm.strength == "" && initialForm.instructions) {
        instructions = initialForm.instructions
    }
    else {
        instructions = ""
    }
    if (value == "" && initialForm && initialForm.strength && initialForm.strength == "" && initialForm.notes) {
        notes = initialForm.notes
    }
    else {
        notes = ""
    }
    return { instructions, notes }
}

export function updateRecommendations(id, recomm) {
    return (dispatch, getState) => {
        let updateRecommendation = getState().analytics;
        let recommendationForm = {};
        if (recomm == 'resumeCurrent' && updateRecommendation.stoppedRecommendations)
            recommendationForm = _.head(updateRecommendation.stoppedRecommendations.filter(rec => rec.id == id));
        else if (recomm == 'resumeHistoric' && updateRecommendation.filteredRecommendations)
            recommendationForm = _.head(updateRecommendation.filteredRecommendations.filter(rec => rec.id == id));
        else
            recommendationForm = _.head(updateRecommendation.recommendations.filter(rec => rec.id == id));

        if (recomm) {
            recommendationForm.isStopped = recomm == 'stop' ? 1 : 0
        }

        if (recommendationForm.notes && typeof recommendationForm.notes === 'string') {
            recommendationForm.notes = recommendationForm.notes.split('\n')
        }    

        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm,
                recommendation_list,
                instruction_list,
                notes_list,
                strength_list,
                notes_templates_list
            }
        })
        let brand_name_list = [];
        let brand_names = [];
        dispatch(showNoFormChangesError(false));
        let all_recommendations_data = getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
        let recommendationsGroupDTO = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
        let recommendationSigsDTO = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []
        let brand_list = getState().analytics.brand_list ? getState().analytics.brand_list : []
        // let recommendation_list =  getState().analytics.recommendation_list ? getState().analytics.recommendation_list : []
        let strength_list = getState().analytics.strength_list ? getState().analytics.strength_list : []
        let instruction_list = getState().analytics.instruction_list ? getState().analytics.instruction_list : []
        let notes_list = getState().analytics.notes_list ? getState().analytics.notes_list : []
        let notes_templates_list = getState().analytics.notes_templates_list ? getState().analytics.notes_templates_list : []
        let recommedationDTO;
        let group = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.group ? getState().analytics.recommendationForm.group : 'General Instructions';
        let brand = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.brand ? getState().analytics.recommendationForm.brand : '';
        let body = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body ? getState().analytics.recommendationForm.body : "";
        let strength = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.strength ? getState().analytics.recommendationForm.strength : "";
        let recommendationStrengthType = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.recommendationStrengthType ? getState().analytics.recommendationForm.recommendationStrengthType : "";
        let instructions = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.instructions ? getState().analytics.recommendationForm.instructions : "";
        let notes = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.notes ? getState().analytics.recommendationForm.notes : "";
        typeof notes === 'string' ? notes = notes.split('\n') : null
        let startedAt = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.startedAt ? getState().analytics.recommendationForm.startedAt : "";
        let endedAt = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.endedAt ? getState().analytics.recommendationForm.endedAt : "";
        let recommendation_list_option;
        let recommendationStrength;
        let recommendation_list = setRecommendationList(getState().analytics, group)
        if (group) {
            if (brand) {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group))
                    .filter((recommendation) => recommendation.brandNames.includes(brand))
                    .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
            }
            else {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
            }
        }

        if (recommendation_list) {
            recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => brand_names.push(rec.brandNames))
            brand_name_list = [...new Set(brand_names.flat())]
            if (brand_name_list)
                brand_list = brand_name_list.map((brand) => { return { value: brand } })
            else {
                brand_list = []
            }
        }
        else {
            brand_list = []
        }

        if (group && body && recommendation_list) {
            recommendation_list_option = recommendation_list.find((r) => r.value == body)
            if (recommendation_list_option && recommendation_list_option.id) {
                recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
            }
            else
                strength_list = []

            if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id && getState().analytics.recommendationForm.strength == rec.strength)
            }
            if (recommedationDTO && recommedationDTO.recommendationNoteTemplates)
                notes_templates_list = recommedationDTO.recommendationNoteTemplates.map((temp) => { return { value: temp} })
            else
                notes_templates_list = []

            if (recommedationDTO){
                recommendationForm = { ...recommendationForm, productType: recommedationDTO.productType}
            }

            if (recommedationDTO && recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == recommendationForm.productType)){
                let sigs = [];
                sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == recommendationForm.productType)
                instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s.sig, isMorningRecomendedTime: s.isMorningRecomendedTime, isMidDayRecomendedTime: s.isMidDayRecomendedTime, isNightRecomendedTime: s.isNightRecomendedTime } })).flat()
            }
            else if(recommedationDTO && !recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == "")){
                let sigs = [];
                sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == "")
                instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s.sig, isMorningRecomendedTime: s.isMorningRecomendedTime, isMidDayRecomendedTime: s.isMidDayRecomendedTime, isNightRecomendedTime: s.isNightRecomendedTime }})).flat()
            }

            else {
                strength_list = []
                instruction_list = []
                notes_list = []
            }

            if (recommendationStrength && recommendationStrength.recommendationInstructions)
                Array.prototype.push.apply(instruction_list, recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } }))    
            if (recommendationStrength && recommendationStrength.recommendationNotes)
                notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
            else
                notes_list = []
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm,
                brand_list,
                recommendation_list,
                strength_list,
                instruction_list,
                notes_list,
                notes_templates_list
            }
        })
        if (updateRecommendation && updateRecommendation.recommendationCurrentRow) {
            group = updateRecommendation.recommendationCurrentRow.group ? updateRecommendation.recommendationCurrentRow.group : 'General Instructions';
            brand = updateRecommendation.recommendationCurrentRow.brand ? updateRecommendation.recommendationCurrentRow.brand : "";
            body = updateRecommendation.recommendationCurrentRow.body ? updateRecommendation.recommendationCurrentRow.body : "";
            instructions = updateRecommendation.recommendationCurrentRow.instructions ? updateRecommendation.recommendationCurrentRow.instructions : "";
            notes = updateRecommendation.recommendationCurrentRow.notes ? updateRecommendation.recommendationCurrentRow.notes : ""
            typeof notes === 'string' ? notes = notes.split('\n') : null
            strength = updateRecommendation.recommendationCurrentRow.strength ? updateRecommendation.recommendationCurrentRow.strength : "";
            recommendationStrengthType = updateRecommendation.recommendationCurrentRow.recommendationStrengthType ? updateRecommendation.recommendationCurrentRow.recommendationStrengthType : "";
            startedAt = updateRecommendation.recommendationCurrentRow.startedAt ? updateRecommendation.recommendationCurrentRow.startedAt : "";
            endedAt = updateRecommendation.recommendationCurrentRow.endedAt ? updateRecommendation.recommendationCurrentRow.endedAt : "";
            recommendationForm = { ...recommendationForm, brand: brand, group: group, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt };
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationForm
                }
            })
        }
        let originalNotes = [...notes] 
        dispatch(setInitialRecommendationForm(group, brand, body, strength, recommendationStrengthType, instructions, originalNotes, startedAt, endedAt, recommendationForm.productType));
        if (recomm) {
            dispatch(saveRecommendation())
        }
    }
}

export function deleteRecommendations(id, code, recType) {
    return (dispatch, getState) => {
        let groupName = '';
        if (recType == "Medication") {
            groupName = "Supplement"
        }
        else {
            groupName = getState().analytics.sectionValues && getState().analytics.sectionValues.groupName ?
                getState().analytics.sectionValues.groupName
                : 'General Instructions'

        }
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationsLoading: true,
                recommendationForm: { group: groupName, notes: null, body: null },
                medicationForm: { group: groupName, notes: null, body: null },
                groupName: groupName,
                recommendation_list: setRecommendationList(getState().analytics, groupName),
                brand_list: setBrandList(getState().analytics, groupName)
            }
        })
        let isDemo = getState().analytics.isDemo
        if (!isDemo) {
            let finalDatumResult = getState().analytics.datumValues ? getState().analytics.datumValues.code : ''
            let resCode = code ? code : getState().analytics.ages;
            let datumDrawer = code ? code : finalDatumResult;
            if (resCode && getState().analytics.drawerType == 'biomarker') {
                axios.delete(`${API_URL.RECOMMENDATION_URL}/${id}?code=${resCode}`).then(response => {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            isRecommendationsLoading: false,
                        }
                    })
                    dispatch(getRecommendations());
                })
            }
            else {
                axios.delete(`${API_URL.RECOMMENDATION_URL}/${id}?code=${datumDrawer}`).then(response => {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            isRecommendationsLoading: false,
                        }
                    })
                    dispatch(getRecommendations());
                })
            }
        } else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationsLoading: false,
                    recommendationForm: { group: groupName },
                    MedicationForm: { group: groupName },
                    currentRecommendation: null,
                    recommendation_list: setRecommendationList(getState().analytics, groupName),
                    brand_list: setBrandList(getState().analytics, groupName),
                    recommendations: getState().analytics.recommendations.filter(obj => {
                        if (!code) {
                            if ((obj.id && obj.id != id) || (!obj.id && obj.id != id)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else if (obj.indicators.length && _.head(obj.indicators).toLowerCase() == code.toLowerCase()) {
                            if ((obj.id && obj.id != id) || (!obj.id && obj.id != id)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else {
                            return true;
                        }

                    })
                }
            })

        }
        notifyError(`${recType} deleted successfully`)
    }
}
export function newRecommendation() {
    return (dispatch, getState) => {
        let recType = getState().analytics && getState().analytics.recType ? getState().analytics.recType : "Recommendation"
        let groupName = '';
        if (recType == "Medication") {
            groupName = "Supplement"
        }
        else if (getState().analytics.currentPage == "report_card"){
            groupName = getState().analytics.sectionValues && getState().analytics.sectionValues.groupName ?
                getState().analytics.sectionValues.groupName
                : 'General Instructions'
        }
        else {
            groupName = 'General Instructions'
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm: { group: groupName },
                initialRecommendationForm: { group: groupName },
                recommendation_list: setRecommendationList(getState().analytics, groupName),
                brand_list: setBrandList(getState().analytics, groupName),
                strength_list: [],
                instruction_list: [],
                notes_list: [],
                notes_templates_list: []

            }
        })
        dispatch(showNoFormChangesError(false));
    }
}

export function setInitialRecommendationForm(group, brand, body, strength, recommendationStrengthType, instructions, originalNotes, startedAt, endedAt, productType) {
    return (dispatch, getState) => {
        let initialRecommendationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: originalNotes, startedAt: startedAt, endedAt: endedAt, productType: productType }
        dispatch({
            type: SET_PROPS,
            payload: {
                initialRecommendationForm
            }
        })
    }
}


export function setRecommendationType(type) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationType: type
            }
        })
        dispatch(toggleLoading(false))
    }
}

export function updateMetaRecommendations(index) {
    return (dispatch, getState) => {
        let metaRecommendationForm = getState().analytics && getState().analytics.metaRecommendations && getState().analytics.metaRecommendations[index];
        let recommedationDTO
        let strength_list
        let instruction_list
        let notes_list
        let recommendationsGroupDTO = getState().analytics.all_recommendations_data && getState().analytics.all_recommendations_data.recommendationsGroupDTO ? getState().analytics.all_recommendations_data.recommendationsGroupDTO : []
        let group = metaRecommendationForm && metaRecommendationForm.group ? metaRecommendationForm.group : 'General Instructions';
        let recommendation_list;
        let recommendationStrength;
        let recommendation_list_option;
        let body = metaRecommendationForm && metaRecommendationForm.body ? metaRecommendationForm.body : "";
        if (group)
            recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
        if (group && body && recommendation_list) {
            recommendation_list_option = recommendation_list.find((r) => r.value == body)
            if (recommendation_list_option && recommendation_list_option.id) {
                recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
            }
            else
                strength_list = []

            if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id)
            }
            else {
                strength_list = []
                instruction_list = []
                notes_list = []
            }

            if (recommendationStrength && recommendationStrength.recommendationInstructions)
                instruction_list = recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } })
            else
                instruction_list = []
            if (recommendationStrength && recommendationStrength.recommendationNotes)
                notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
            else
                notes_list = []
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecommendationForm,
                recommendation_list,
                strength_list,
                instruction_list,
                notes_list
            }
        })
    }
}

export function toggleStopRecommendationPopup(value, stopRecommendId, resumeRecommendation, recommendationCurrentRow) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                stopRecommendationPopup: value,
                stopRecommendId: stopRecommendId,
            }
        })
        if (recommendationCurrentRow) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: { ...recommendationCurrentRow }
                }
            })
        }
        if (!value) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: null
                }
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                resumeRecommendation: resumeRecommendation ? resumeRecommendation : false
            }
        })

    }
}

export function toggleDeleteRecommendationPopup(value, deleteRecommendId, recommendationCurrentRow) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                deleteRecommendationPopup: value,
                deleteRecommendId: deleteRecommendId,
            }
        })
        if (recommendationCurrentRow) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: { ...recommendationCurrentRow }
                }
            })
        }
        if (!value) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: null
                }
            })
        }

    }
}
export function getHistoryRecommendations() {
    return (dispatch, getState) => {
        let id = getState().analytics && getState().analytics.presentPatient ? getState().analytics.presentPatient.latestVisitId : '';
        let locale = getState().analytics && getState().analytics.presentPatient ? getState().analytics.presentPatient.languagePreference : "en"
        let patientId = getState().analytics && getState().analytics.presentPatient ? getState().analytics.presentPatient.id : 0
        dispatch(toggleLoading(true))
        axios.get(`${API_URL.RECOMMENDATION_URL}/${id}?locale=${locale}&patientId=${patientId}&loadHistory=${true}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    filteredRecommendations: response.data,
                    historicRecommendations: response.data
                }
            })
            dispatch(toggleLoading(false))
            dispatch(toggleHistoryRecommendationPopup(true));
        }).catch((error) => {
            dispatch(toggleLoading(false))
            dispatch(handleErrors(error))
        })
    }
}

export function toggleHistoryRecommendationPopup(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                historyRecommendationPopup: value,
                currentSearchRecm: {}
            }
        })
    }
}
export function setAllVisitData(patientId, summaryVisitId, locale) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatientAging: {}
            }
        })
        axios.get(API_URL.PATIENTS_VISTS_URL, { params: { patientId, loadAgeData: false } }).then((response) => {
            dispatch(setAggregateDetails(response.data))
            dispatch({
                type: SET_PROPS,
                payload: {
                    allVisitData: response.data ? response.data : '',
                    lastestVisitDataentry: response.data && _.last(response.data) ? _.last(response.data).dataEntry : ''
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        })
        if (summaryVisitId) {
            let currentFollowUpCount = !isNaN(getState().analytics.currentTimepoint) ? getState().analytics.currentTimepoint : 0
            let query = `followUpCount=${currentFollowUpCount}&cultureInfo=${locale}&visitId=${summaryVisitId}`
            //let query = `followUpCount=${currentFollowUpCount}&cultureInfo=${locale}`
            axios.get(`${API_URL.FOLLOW_UP_DATA}/${patientId}?${query}`).then((response) => {
                // dispatch(handleLoading())
                let followupdata = response.data.filter(d => d.visitId == summaryVisitId && d.value && parseInt(d.value))

                // let culturedBiomarkers = {};
                // let groupByAges = _.groupBy(response.data, 'cultureLabel')
                // let ages = Object.keys(groupByAges);
                // for (let key of ages) {
                //     groupByAges[key] = groupByAges[key].filter(s => s.value != "0");
                //     groupByAges[key] = groupByAges[key].length > 0 ? _.last(groupByAges[key]) : {};
                //     _.isEmpty(groupByAges[key]) == false ? culturedBiomarkers[groupByAges[key].label] = groupByAges[key].cultureLabel : '';
                // }
                let historic_data = {}
                // let ageData = {}
                // let arrayObject = [];
                Object.keys(constants.biographical_labels.followup_ages).map(fp => {
                    historic_data[fp] = followupdata.filter(d => d.label == constants.biographical_labels.followup_ages[fp] && d.visitId == summaryVisitId)
                    // ageData = groupByAges[culturedBiomarkers[constants.biographical_labels.followup_ages[fp]]];
                    // arrayObject = [];
                    // ageData ? arrayObject.push(ageData) : '';
                    // historic_data[fp] = arrayObject;
                })
                dispatch(setComparingChartsData(historic_data))
            }).catch(errors => {
                dispatch(handleErrors(errors));
            })
        }
    }
}
// Biomarkers of Aging
export function setBiomarkersOfAging(patientId, locale, summaryVisitId, biomarkers_historic_data) {

    return (dispatch, getState) => {
        // dispatch(toggleLoading(true))
        // dispatch({
        //     type: SET_PROPS,
        //     payload: {
        //         currentPatientAging: {}
        //     }
        // })
        // axios.get(API_URL.PATIENTS_VISTS_URL, { params: { patientId, loadAgeData: false } }).then((response) => {
        //     dispatch(setAggregateDetails(response.data))
        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             allVisitData: response.data ? response.data : '',
        //             lastestVisitDataentry: response.data && _.last(response.data) ? _.last(response.data).dataEntry : ''
        //         }
        //     })
        // }).catch((error) => {
        //     dispatch(handleErrors(error))
        // })
        if (!summaryVisitId) {
            // axios.get(API_URL.AGGREGATE_COMPARING_CHARTS, { params: { id: patientId, cultureInfo: locale } }).then((historic_data) => {
            dispatch(setAggregateGraph(biomarkers_historic_data))
            dispatch(setComparingChartsData(biomarkers_historic_data))

            let data = biomarkers_historic_data;
            let marqueeBioCount = 0;
            if (data) {
                for (let prop in data) {
                    if (Array.isArray(data[prop])) {
                        if (data[prop].length > 0)
                            marqueeBioCount++;
                    }
                }
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    marqueeBioCount: marqueeBioCount
                }
            })

            // }).catch((error) => {
            //     dispatch({
            //         type: SET_PROPS,
            //         payload: {
            //             loadingPatient: constants.notification_type.error
            //         }
            //     })
            //     dispatch(handleErrors(error))
            // })
        }
        // else {
        //     let currentFollowUpCount = !isNaN(getState().analytics.currentTimepoint) ? getState().analytics.currentTimepoint : 0
        //     let query = `followUpCount=${currentFollowUpCount}&cultureInfo=${locale}&visitId=${summaryVisitId}`
        //     axios.get(`${API_URL.FOLLOW_UP_DATA}/${patientId}?${query}`).then((response) => {
        //         let followupdata = response.data.filter(d => d.visitId == summaryVisitId && d.value && parseInt(d.value))

        //         let historic_data = {}

        //         Object.keys(constants.biographical_labels.followup_ages).map(fp => {
        //             historic_data[fp] = followupdata.filter(d => d.label == constants.biographical_labels.followup_ages[fp] && d.visitId == summaryVisitId)

        //         })
        //         dispatch(setComparingChartsData(historic_data))
        //     }).catch(errors => {
        //         dispatch(handleErrors(errors));
        //     })
        // }
    }
}

function setAggregateGraph(data) {
    return (dispatch, getState) => {
        let aggregateAgeUrl = constants.biographical_labels.artisan_route;
        let age = getState().analytics.age_round;
        let res = data ? Object.keys(data).map(ad => {
            if (_.head(data[ad]) != null && _.head(data[ad]).value != null) {
                return `n:${_.head(data[ad]).label}^v:${roundNumber(_.head(data[ad]).value, 1)}^b:${age}`
            }
        }) : ""
        aggregateAgeUrl = res ? `${aggregateAgeUrl}?data=${_.compact(res).join('|')}` : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                aggregateAgeUrl
            }
        })
    }
}

function setAggregateDetails(data) {
    return (dispatch, getState) => {
        let currentSummaryVisit = getState().analytics.currentSummaryVisitId ? _.head(data.filter(d => d.id == getState().analytics.currentSummaryVisitId)) : null
        let currentPatientAging = currentSummaryVisit ? currentSummaryVisit : _.head(data);
        let analyticsAggregateChart = currentPatientAging ? currentPatientAging.ageData : null;
        // let aggregateAgeUrl = constants.biographical_labels.artisan_route;
        // let age = getState().analytics.age;
        let providerGender = getState().providers && getState().providers.currentPatient ? getState().providers.currentPatient.gender : null;
        // let res = analyticsAggregateChart ? Object.keys(analyticsAggregateChart).map(ad => {
        //     if (analyticsAggregateChart[ad] != "" && ad != "incite_trubiohealth_age" && ad != "glycanage" && ad != "epigenage") {
        //         return `n:${ad}^v:${Math.round(analyticsAggregateChart[ad])}^b:${age}`
        //     }
        // }) : ""
        // aggregateAgeUrl = res ? `${aggregateAgeUrl}?data=${_.compact(res).join('|')}` : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatientAging,
                analyticsAggregateChart,
                // aggregateAgeUrl,
                providerGender
            }
        })
    }
}

function setComparingChartsData(data) {
    return (dispatch, getState) => {
        var biomarkerAgesList = _.compact(Object.keys(data).map(s => _.head(data[s]) && _.head(data[s]).code ? _.head(data[s]).code.toLowerCase() : null));
        dispatch({
            type: SET_PROPS,
            payload: {
                historic_data_chart: data,
                loadingPatient: "finished",
                biomarkerAgesList
            }
        })
    }

}

function setInstructionAndNotes(analytics, recommendation, recommendation_list, strength) {
    let recommendationsGroupDTO = analytics.all_recommendations_data && analytics.all_recommendations_data.recommendationsGroupDTO ? analytics.all_recommendations_data.recommendationsGroupDTO : []
    let recommendation_list_option;
    let recommedationDTO;
    let instruction_list = [];
    let notes_list = [];
    let recommendationStrength;

    if (recommendationsGroupDTO && strength && recommendation_list) {
        recommendation_list_option = recommendation_list.find((r) => r.value == recommendation)

        if (recommendation_list_option && recommendation_list_option.id) {
            recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
        }
        else {
            instruction_list = []
            notes_list = []

        }
        if (recommedationDTO && recommedationDTO.recommendationStrengths) {
            recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id
                && strength == rec.strength)
        }
        else {
            instruction_list = []
            notes_list = []
        }
        if (recommendationStrength && recommendationStrength.recommendationInstructions)
            instruction_list = recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } })
        else
            instruction_list = []

        if (recommendationStrength && recommendationStrength.recommendationNotes)
            notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
        else
            notes_list = []
    }
    else {
        instruction_list = []
        notes_list = []
    }
    return [instruction_list, notes_list]
}



export function getAllCategories(visitId, locale, isSummary, history, isMobile) {
    return (dispatch, getState) => {

        let role = getState().global && getState().global.loggedInUser
            && getState().global.loggedInUser.role ? getState().global.loggedInUser.role
            : constants.logged_roles[localStorage.getItem(constants.role)]

        axios.get(`${API_URL.ANALYTICS_BIO_INFO}/?cultureInfo=${locale}`).then((category) => {

            dispatch({
                type: SET_PROPS,
                payload: {
                    category: category.data,
                    loadingCategories: "finished",
                    loadingSections: "finished"
                }
            })
            if (isSummary) {
                dispatch(handleLoading())
            }
        }).catch(errors => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    loadingCategories: constants.notification_type.error,
                    loadingSections: constants.notification_type.error
                }
            })
            dispatch(handleErrors(errors));
            dispatch(handleSummaryLoading(false))
        })

        let query = `cultureInfo=${locale}&timestamp=${new Date().getTime()}`
        if (isSummary) {
            query += '&loadHistory=false&loadInterpretations=true'
        }
        if (!isSummary) {
            if (role != 'patient') {
                query += '&loadRecommendations=true&loadExplanations=true&loadInterpretations=true'
            }
            else {
                query += '&loadExplanations=true&loadInterpretations=true'
            }
        }
        // if (!isSummary && role != 'patient') {
        //     query += '&loadRecommendations=true'
        // }
        axios.get(`${API_URL.ANALYTICS_HISTORIC_CATEGOREY}/${visitId}?${query}`).then((response) => {
            //-------------------------------biomarkers of aging-----------------------------------
            var result = {}
            var biographical_labels = constants.biographical_labels.historic_data_labels;
            Object.keys(response.data).map(s => {
                if (Object.keys(biographical_labels).includes(response.data[s].code)) {
                    result[biographical_labels[response.data[s].code]] = []

                    if (response.data[s].historicData != null && response.data[s].historicData.length) {
                        for (var i = 0; i < response.data[s].historicData.length; i++) {
                            result[biographical_labels[response.data[s].code]].push(response.data[s].historicData[i])
                        }
                    }
                    else {
                        result[biographical_labels[response.data[s].code]].push(response.data[s])
                    }
                    if (response.data[s].noumenonExplanation)
                        _.head(result[biographical_labels[response.data[s].code]]).noumenonExplanation = response.data[s].noumenonExplanation

                    _.last(result[biographical_labels[response.data[s].code]]).historicData = response.data[s].historicData
                }
            });
            var biomarkerCount = 0;
            var sortedResult = {};
            if (result && Object.keys(result).length) {
                Object.keys(result).map(s => result[s].length).map(s => biomarkerCount = biomarkerCount + s);
                Object.keys(biographical_labels).forEach((label) => {

                    sortedResult[biographical_labels[label]] = result[biographical_labels[label]]

                });
                sortedResult = _.pickBy(sortedResult);
            }
            sortedResult.biomarkerCount = biomarkerCount;
            dispatch(setBiomarkersOfAging(getState().analytics.presentPatient.id,
                locale
                , isSummary ? parseInt(visitId) : null, sortedResult))

            //----------------------------biomarkers ends here------------------------------------

            let labelsForBioInfo = constants.labelsForBioInfo;
            var biographicalInformation = {}, bioInformation = {}
            let metaRecommendations = response.data && response.data.length > 0 ? _.head(response.data).metaRecommendations : [];
            let metaRecommendationCodes = response.data && response.data.length > 0 ? _.head(response.data).metaRecommendationCodes : [];
            let interpretations = response.data && response.data.length > 0 ? _.head(response.data).interpretations : [];

            //preparing for Biographical Information
            //Ex: Smoker? : Current


            var finalVals = response.data ? labelsForBioInfo.map(s => {
                return response.data.find(t => t.code == s)
            }) : ''

            //removing undefined or null values using compact
            if (_.compact(finalVals) && _.compact(finalVals).length) {
                _.compact(finalVals).map(t => { biographicalInformation[t.code] = (t.historicData && t.historicData.length ? _.last(t.historicData).value : t.value) })
            }

            //Preparing the biographical information as key value pairs
            //default will be unknown if the value is not specified
            Object.keys(constants.biographical_labels.history_component).map(s => {
                if (Object.keys(biographicalInformation).length && Object.keys(biographicalInformation).indexOf(s) != -1) {
                    return bioInformation[constants.biographical_labels.history_component[s]] = biographicalInformation[s]
                } else {
                    return bioInformation[constants.biographical_labels.history_component[s]] = constants.unknown
                }
            })

            dispatch({
                type: SET_PROPS,
                payload: {
                    Data: response.data,
                    bioInformation,
                    metaRecommendations,
                    metaRecommendationCodes,
                    interpretations
                }
            })
            dispatch(setDefaultMetaExpansions());
            if (isSummary) {
                setTimeout(() => {
                    dispatch(downloadVisitSummary(locale, visitId, history, isMobile))
                    dispatch(toggleLoading(true))
                }, 1000);
            }
            if(!isSummary){
                // Site Specific GPA - PMI-3281
                dispatch(getSiteSpecificGPAData(false, visitId))
            }
            else {
                dispatch(dataSelector(isSummary, visitId))    
            }

        }).catch(errors => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    loadingData: constants.notification_type.error
                }
            })
            dispatch(handleStatusCodes(errors));
            dispatch(handleErrors(errors));
            dispatch(handleSummaryLoading(false))
        })

    }
}

 // Site Specific GPA - PMI-3281
function getSiteSpecificGPAData(isSummary, visitId){
    return (dispatch, getState) => {
        axios.get(`${API_URL.ANALYTICS_SITESPECIFIC_GPA}/${visitId}`).then((response) => {
            response.data && response.data.siteSpecificLineGraphData && response.data.siteSpecificLineGraphData.sort((a,b) => new Date(b.visitDate) - new Date(a.visitDate));
            let siteSpecificOverallGpa = response.data && response.data.siteSpecificLineGraphData ? _.head(response.data.siteSpecificLineGraphData).points : null
            dispatch({
                type: SET_PROPS,
                payload: {
                    siteSpecificOverallGpa: siteSpecificOverallGpa,
                    siteSpecificLineGraphData: response.data ? response.data.siteSpecificLineGraphData && response.data.siteSpecificLineGraphData.map(a => ({ value: a.points, label: "report-grades", ...a })) : []
                }
            })
            if (!isSummary){
                dispatch(dataSelector(isSummary, visitId, siteSpecificOverallGpa))   
            }     
        }).catch((error) => {
            dispatch(handleStatusCodes(error))
            dispatch(handleErrors(error))
        })
    }
}

function dataSelector(isSummary, visitId, siteSpecificOverallGpa) {
    return (dispatch, getState) => {
        let categoryData = getState().analytics.category;
        // let categoriesGradeData = cloneDeep(getState().analytics.category);
        let data = getState().analytics.Data;
        let categories = isSummary ? datasetSelector({ categoryData, data, visitId }) : datasetSelector({ categoryData, data });
        //enable time boundaries when required
        // categories = categories.map((c) => { return { nouenon_list: c.sections.map(s => s.data).flat().sort((a, b) => (a.points > b.points) ? 1 : -1).filter(a => a.points != null).slice(0, 2), ...c } })
        categories = categories.map((c) => {
            return {
                nouenon_list:
                    c.sections.flatMap(s => s.data.map(item => ({ ...item.sectionKey = s.key, ...item })))
                        .sort((a, b) => (a.points > b.points) ? 1 : -1)
                        .filter(a => a.points != null),
                // .slice(0, 2),
                ...c
            }
        })
        let byRiskCategories = categories.slice().sort((a, b) => {
            if (isNaN(a.points) || a.points === undefined) {
                return 1;
            } else if (isNaN(b.points) || b.points === undefined) {
                return -1;
            } else {
                return a.points - b.points;
            }
        });
        let timeBoundaries = !isSummary ? timeBoundarySelector({ data }) : null
        // let gpa = gpaSelector(categories)
        // Site Specific GPA - PMI-3281
        let gpa = getState().analytics.siteData.noumenonCodes.length > 0 ? siteSpecificOverallGpa : gpaSelector(categories)
        let visitList = getState().analytics.allVisitData
        var grade_historic_data = []
        visitList && visitList.length ?
            visitList.map(s => {
                let visitNumber = s.id,
                    dateOfVisit = s.visitDate,
                    categoriesGradeData = cloneDeep(getState().analytics.category)
                let testCat = gradeSelector({ categoriesGradeData, data, visitNumber, dateOfVisit })
                grade_historic_data.push((testCat))
            })
            : ''
        var grading_data =
            grade_historic_data && grade_historic_data.length && grade_historic_data.map(t => {
                if (t && t.length) {
                    return {
                        visitId: _.head(_.head((_.head(t).sections.map(s => s.data)))).visitId,
                        visitDate: _.head(_.head((_.head(t).sections.map(s => s.data)))).visitDate,
                        points: round(_.mean(t.filter(u => !isNaN(u.points)).map(v => v.points)), 2),
                        value: round(_.mean(t.filter(u => !isNaN(u.points)).map(v => v.points)), 2),
                        data: t,
                        label: 'report-grades',
                        type: 'points'
                    }
                }
            }).filter(p => p && !isNaN(p.points))
        var grade_historic_data =
            grading_data && grading_data.length ?
                grade_historic_data =
                {
                    value: _.last(grading_data).value,
                    points: _.last(grading_data).points,
                    visitDate: _.last(grading_data).visitDate,
                    visitId: _.last(grading_data).visitId,
                    historicData: grading_data
                } : null
        dispatch({
            type: SET_PROPS,
            payload: {
                categories, gpa,
                timeBoundaries,
                loadingData: "finished",
                grading_data,
                grade_historic_data,
                byRiskCategories
            }
        })
    }
}

export function setDefaultMetaExpansions() {
    return (dispatch, getState) => {
        let metaRecExpanded = [];
        let metaRecommendations = getState().analytics && getState().analytics.metaRecommendations ? [...getState().analytics.metaRecommendations] : [];
        for (let i = 0; i < metaRecommendations.length; i++)
            metaRecExpanded.push(false);
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecExpanded
            }
        })
    }
}
export function isLeftNavOpenend(isNavOpen) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isLeftNavOpen: isNavOpen
            }
        })
    }
}

export function toggleRecommendationList(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationList: value
            }
        })
    }
}

export function toggleBioAgeOverview(ages, units, dateOfVisit, drawerType, cultureLabel, history, agename, age_data, biomarker_key_name) {
    return (dispatch, getState) => {
        let historic_data_chart = getState().analytics.historic_data_chart
        Object.keys(historic_data_chart).map((ad) => { historic_data_chart[ad].length > 0 ? Object.assign(historic_data_chart[ad], { value: _.last(historic_data_chart[ad]).value }) : "" })
        let data = _.last(historic_data_chart[biomarker_key_name]).historicData
        let noumenonCodes = _.last(data) ? _.last(data).noumenonCodes : []
        let categories = getState().analytics.categories ? getState().analytics.categories : []
        categories = categories.map((c) => {
            return {
                nouenon_lists:
                    c.sections.flatMap(s => s.data.map(item => ({ ...item.sectionKey = s.key, ...item })))
                        .sort((a, b) => (a.points > b.points) ? 1 : -1),
                ...c
            }
        })
        let nomenonInvloved = noumenonCodes ? categories.map((cat) => cat.nouenon_lists.filter((n) => noumenonCodes.includes(n.code) ? n : "")) : []
        nomenonInvloved = nomenonInvloved.length > 0 ? nomenonInvloved.filter(a => a[0]) : ""
        dispatch(clearParamsOfRecommendations())
        dispatch({
            type: SET_PROPS,
            payload: {
                ages: ages,
                units: units,
                drawerType,
                historic_data_chart: historic_data_chart,
                dateOfVisit: dateOfVisit,
                cultureLabel: cultureLabel,
                recommendationErrors: [],
                ageName: agename,
                recommendation_list: setRecommendationList(getState().analytics, 'General Instructions'),
                brand_list: setBrandList(getState().analytics, 'General Instructions'),
                bioAgeRecommendations: getState().analytics.recommendations ?
                    getState().analytics.recommendations.filter(rec => rec.indicators.includes(ages)) : "",
                age_data,
                biomarkerComparisonList: [],
                biomarker_key_name: biomarker_key_name,
                nomenonInvloved: nomenonInvloved ? _.uniqBy(nomenonInvloved.flat(), 'code') : []
            }
        })
        dispatch(setActiveRecommendationToggle(ages))
    }
}
export function setCurrentCategory(category, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCategory: category,
            }
        })
        if (history)
            dispatch(togglePages('report_card', history, 'curretCategory'))
    }
}


export function setCurrentNomenon(category, nomenon, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNomenon: nomenon,
                currentCategory: category,
            }
        })
        if (history)
            dispatch(togglePages('report_card', history, 'currentNomenon'))
    }
}

export function setCurrentSection(category, section, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSection: section,
                currentCategory: category,
            }
        })
        if (history)
            dispatch(togglePages('report_card', history, 'currentsection'))
    }
}
export function toggleCategoryOverview(drawerType, categoryRow, categoryGradesData) {
    return (dispatch, getState) => {
        var categoryGradesHistoric = categoryGradesData && categoryGradesData.length ?
            {
                value: _.last(categoryGradesData).value,
                points: _.last(categoryGradesData).points,
                visitDate: _.last(categoryGradesData).visitDate,
                visitId: _.last(categoryGradesData).visitId,
                historicData: categoryGradesData
            } : null
        dispatch(clearParamsOfRecommendations())
        dispatch({
            type: SET_PROPS,
            payload: {
                drawerType: drawerType,
                recommendationErrors: [],
                recommendation_list: setRecommendationList(getState().analytics, 'General Instructions'),
                brand_list: setBrandList(getState().analytics, 'General Instructions'),
                currentRecommendations: getState().analytics.recommendations ?
                    getState().analytics.recommendations.filter(rec => rec.indicators.includes(categoryRow.key)) : "",
                currentCategory: categoryRow,
                categoryGradesHistoric,
                categoryGradesData,
                currentIndicator: categoryRow.key
            }
        })
        dispatch(setActiveRecommendationToggle(categoryRow.key))
    }
}

export function toggleSectionOverview(categories, drawerType, sectionRow, sectionGradesData) {
    return (dispatch, getState) => {
        dispatch(clearParamsOfRecommendations())
        var sectionGradesHistoric = sectionGradesData && sectionGradesData.length ?
            {
                value: _.last(sectionGradesData).value,
                points: _.last(sectionGradesData).points,
                visitDate: _.last(sectionGradesData).visitDate,
                visitId: _.last(sectionGradesData).visitId,
                historicData: sectionGradesData
            } : null

        let sectionValues = sectionGradesData && sectionGradesData.length ? _.head(sectionGradesData) : []
        let groupName = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.group ? getState().analytics.recommendationForm.group : setGroupName(sectionValues)
        let recommendationForm = getState().analytics && getState().analytics.recommendationForm ? getState().analytics.recommendationForm : { group: groupName }
        let recommendation = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body ? getState().analytics.recommendationForm.body : ""
        let strength = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.strength ? getState().analytics.recommendationForm.strength : ""
        let recommendation_list = setRecommendationList(getState().analytics, 'General Instructions')
        let instructionAndNotes = setInstructionAndNotes(getState().analytics, recommendation, recommendation_list, strength)
        let instruction_list = instructionAndNotes[0]
        let notes_list = instructionAndNotes[1]
        let categoryOfSection = getCategoryNameBySectionName(categories, sectionRow.key)

        dispatch({
            type: SET_PROPS,
            payload: {
                drawerType: drawerType,
                sectionGradesData,
                currentSection: sectionRow,
                sectionGradesHistoric,
                recommendationForm: recommendationForm,
                initialRecommendationForm: getState().analytics.initialRecommendationForm ? getState().analytics.initialRecommendationForm : { group: groupName },
                recommendationErrors: [],
                recommendation_list: recommendation_list,
                brand_list: setBrandList(getState().analytics, 'General Instructions'),
                strength_list: setStrengthList(getState().analytics, recommendation, recommendation_list),
                currentRecommendations: getState().analytics.recommendations ?
                    getState().analytics.recommendations.filter(rec => rec.indicators.includes(sectionRow.key)) : "",
                instruction_list: instruction_list,
                notes_list: notes_list,
                sectionValues,
                currentIndicator: sectionRow.key,
                sectionCategory: categoryOfSection.name,
                currentCategory: categoryOfSection.category
             }
        })
        dispatch(setActiveRecommendationToggle(sectionRow.key))
    }
}

export function toggleDatumOverview(categories, datumValues, sectionValues, drawerType) {
    return (dispatch, getState) => {
        dispatch(clearParamsOfRecommendations())

        let groupName = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.group ? getState().analytics.recommendationForm.group : setGroupName(sectionValues)
        let recommendationForm = getState().analytics && getState().analytics.recommendationForm ? getState().analytics.recommendationForm : { group: groupName }
        let recommendation = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body ? getState().analytics.recommendationForm.body : ""
        let strength = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.strength ? getState().analytics.recommendationForm.strength : ""
        let recommendation_list = setRecommendationList(getState().analytics, 'General Instructions')
        let instructionAndNotes = setInstructionAndNotes(getState().analytics, recommendation, recommendation_list, strength)
        let instruction_list = instructionAndNotes[0]
        let notes_list = instructionAndNotes[1]
        let sectionAndCategory = getSectionAndCatgeoryNameByData(categories, datumValues.code, datumValues.categoryKey, datumValues.sectionKey)
        let dataSection = sectionAndCategory.sectionName
        let sectionCategory = sectionAndCategory.categoryName
        let currentCategory  = sectionAndCategory.category
        dispatch({
            type: SET_PROPS,
            payload: {
                datumValues,
                sectionValues,
                drawerType: drawerType,
                recommendationErrors: [],
                recommendationForm: recommendationForm,
                initialRecommendationForm: getState().analytics.initialRecommendationForm ? getState().analytics.initialRecommendationForm : { group: groupName },
                recommendation_list: recommendation_list,
                brand_list: setBrandList(getState().analytics, 'General Instructions'),
                strength_list: setStrengthList(getState().analytics, recommendation, recommendation_list),
                currentRecommendations: getState().analytics.recommendations ?
                    getState().analytics.recommendations.filter(rec => rec.indicators.includes(datumValues.code)) : "",
                instruction_list: instruction_list,
                notes_list: notes_list,
                currentIndicator: datumValues.code,
                sectionCategory: sectionCategory,
                dataSection: dataSection,
                currentCategory: currentCategory
            }
        })
        dispatch(setActiveRecommendationToggle(datumValues.code))
    }
}

export function clearBioAgeOverviewData() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                drawerType: null,
                ages: null,
                ageName: null
            }
        })
    }
}
export function clearParamsOfRecommendations() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                instruction_list: [],
                notes_list: [],
                strength_list: [],
                notes_templates_list: []
            }
        })
    }
}

export function setBiomarkerComparisonList(age) {
    return (dispatch, getState) => {
        let ageList = getState().analytics && getState().analytics.biomarkerComparisonList ? getState().analytics.biomarkerComparisonList : []
        ageList.includes(age) ? ageList.splice(ageList.indexOf(age), 1) : ageList.push(age)
        dispatch({
            type: SET_PROPS,
            payload: {
                biomarkerComparisonList: ageList
            }
        })
    }
}

export function setDefaultBiomarkerComparisonList() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                biomarkerComparisonList: []
            }
        })
    }
}

export function getMedication() {
    return (dispatch, getState) => {
        let recommendations = getState().analytics && getState().analytics.recommendations ? getState().analytics.recommendations : []
        let stoppedRecommendations = getState().analytics && getState().analytics.stoppedRecommendations ? getState().analytics.stoppedRecommendations : []
        let medications = recommendations ? recommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : []
        let stoppedMedications = stoppedRecommendations ? stoppedRecommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : []
        dispatch({
            type: SET_PROPS,
            payload: {
                medications: medications && medications.length ? medications.filter(s => s.isStopped != 1) : [],
                stoppedMedications: stoppedMedications,
            }
        })
    }

}

export function setRecType(type) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                recType: type
            }
        })
    }
}

export function setRecommendationSearchFilters(eve) {
    return (dispatch, getState) => {
        let key = eve.target.id;
        let value = eve.target.value;
        let currentSearchRecm = getState() && getState().analytics && getState().analytics.currentSearchRecm ? getState().analytics.currentSearchRecm : {};
        currentSearchRecm = { ...currentSearchRecm, [key]: value };
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchRecm
            }
        })
        dispatch(filterHistoryRecommendations());
    }
}

export function filterHistoryRecommendations() {
    return (dispatch, getState) => {
        let filteredRecommendations = getState().analytics && getState().analytics.historicRecommendations ? [...getState().analytics.historicRecommendations] : {};
        let searchName = getState().analytics && getState().analytics.currentSearchRecm && getState().analytics.currentSearchRecm.searchRecm ? getState().analytics.currentSearchRecm.searchRecm.toLowerCase() : '';
        filteredRecommendations = filteredRecommendations.filter(u =>
            (u.group && u.group.toLowerCase().indexOf(searchName) > -1) || (u.body && u.body.toLowerCase().indexOf(searchName) > -1) || (u.culturedNoumena && u.culturedNoumena.toLowerCase().indexOf(searchName) > -1) || (u.visitDate && moment(u.visitDate).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1)
            || (u.notes && u.notes.toLowerCase().indexOf(searchName) > -1) || (u.instructions && u.instructions.toLowerCase().indexOf(searchName) > -1) || (u.timePoint && u.timePoint.toLowerCase().indexOf(searchName) > -1)
            || (u.stoppedAt && moment(u.stoppedAt).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1) || (u.previousRecommendation && u.previousRecommendation.body && u.previousRecommendation.body.toLowerCase().indexOf(searchName) > -1)
            || (u.previousRecommendation && u.previousRecommendation.culturedNoumena && u.previousRecommendation.culturedNoumena.toLowerCase().indexOf(searchName) > -1)
            || (u.previousRecommendation && u.previousRecommendation.notes && u.previousRecommendation.notes.toLowerCase().indexOf(searchName) > -1) || (u.previousRecommendation && u.previousRecommendation.instructions && u.previousRecommendation.instructions.toLowerCase().indexOf(searchName) > -1)
            || (u.previousRecommendation && u.previousRecommendation.stoppedAt && moment(u.previousRecommendation.stoppedAt).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1) || (u.nextRecommendation && u.nextRecommendation.createdAt && moment(u.nextRecommendation.createdAt).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1)
        );
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredRecommendations: filteredRecommendations
            }
        })
    }
}

export function savePriorities() {
    return (dispatch, getState) => {
        let currentState = getState().analytics
        let visitId = currentState && currentState.presentPatient.latestVisitId
        let priorVisitId = currentState.presentPatient.priorVisitId != null ? currentState.presentPatient.priorVisitId : 0
        let patientId = currentState.presentPatient ? currentState.presentPatient.id : 0
        dispatch({
            type: SET_PROPS,
            payload: {
                // isRecommendationsLoading: true
                isPriorityLoader: true
            }
        })

        let priorities = Array.from(simpleList.children)
            .map((ele, index) => {
                return {
                    priority: index + 1,
                    recommendationId: parseInt(ele.getAttribute('data-id'))
                }
            })
        promisesList.push(axios.post(API_URL.RECOMMENDATION_REORDER, {
            visitId,
            // priorVisitId,
            patientId,
            priorities
        }).then(response => {
            Promise.all(promisesList.filter(p => p)).then(function () {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isPriorityLoader: false
                    }
                })
                clearPromises()
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
export function toggleResetPriority() {
    return (dispatch, getState) => {
        let currentState = getState().analytics

        let prioritiesMatch = true

        let currentPriorities = Array.from(simpleList.children)
            .map((ele) => parseInt(_.head(ele.children).getAttribute('data-id')))

        let initialPriorities = currentState && currentState.initialPriorities &&
            currentState.initialPriorities.sort((a, b) => {
                return b.priority < a.priority ? 1 : b.priority > a.priority ? -1 : 0
            }).map(ele => ele.recommendationId)

        currentPriorities.map((ele, index) => {
            if (initialPriorities[index] != ele) {
                prioritiesMatch = false
            }
        })

        dispatch({
            type: SET_PROPS,
            payload: {
                isReorder: prioritiesMatch ? false : true
            }
        })
    }
}

function downloadPatientReport(visitId, history, pastData) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(setPatientReportStatus(true))
        dispatch(setHeaderType(null, false))
        dispatch(setCurrentVisit(visitId, history, true, undefined, undefined, pastData ? true : false, pastData ? "patientReportDownloadTemplatePast" : "patientReportDownloadTemplate", true))
    }
}

function handleDownloadVisitSummary(history) {
    return (dispatch, getState) => {
        let presentPatient = getState().analytics.presentPatient
        let lastestVisitDataentry = getState().analytics.lastestVisitDataentry
        let isDemo = getState().analytics.isDemo
        let isDemo1 = getState().analytics.isDemo1;
        if (!isDemo) {
            if (lastestVisitDataentry && lastestVisitDataentry.indexOf(true) == -1) {
                if (getState().analytics.role == constants.logged_roles.PT) {
                    history.push(`/analytics`)
                }
                else {
                    history.push(`/visits/${presentPatient.latestVisitId}`)
                }
                setTimeout(() => {
                    dispatch(toggleNotification(true, 'error', '', [labels.visit_page_labels.no_recommandations_caution], true))
                }, 2000);
            } else {
                history.push(`/visits/${presentPatient.latestVisitId}/summary.pdf?locale=${presentPatient.patientLanguagePref ? presentPatient.patientLanguagePref : 'en'}`)
            }
        } else {
            isDemo1 ? history.push('/sample_data/demo_1_visit_summary.pdf')
                : history.push('/sample_data/demo_2_visit_summary.pdf')
        }
    }
}

function setVisitSummary(visitId, locale, history, isMobile) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(toggleReportLoading(true))
        let isDemo = getState().analytics.isDemo
        let isDemo1 = getState().analytics.isDemo1
        dispatch({
            type: SET_PROPS,
            payload: {
                downloadableFile: null,
                isSummaryLoading: true
            }
        })
        if (!isDemo && !isNaN(parseInt(visitId))) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentSummaryVisitId: visitId,
                    isVisitSummary: true
                }
            })
            axios.get(`${API_URL.VISIT_URL}/${visitId}`).then((response) => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentTimepoint: parseInt(response.data.timePoint.replace(/^\D+/g, ''))
                    }
                })
                dispatch(setAnalytics(response.data.patientId, true, visitId, locale, history, isMobile))
            }).catch((error) => {
                dispatch(handleStatusCodes(error))
                dispatch(handleErrors(error))
            })
        } else if (isDemo) {
            isDemo1 ? dispatch(toDataURL(demoSummary, 'downloadableFile'))
                : dispatch(toDataURL(demo2Summary, 'downloadableFile'))

            dispatch(handleSummaryLoading(false))
            dispatch(toggleLoading(false))
        }
    }
}

function downloadVisitSummary(locale, visitId, history, isMobile) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isVisitSummary: true
            }
        })
        dispatch(toggleLoading(true))
        dispatch(getSiteSpecificGPAData(true, visitId))
        setTimeout(() => {
            let analytics = getState().analytics
            let isDemo = analytics.isDemo
            let visitSubtitle = analytics.terms['visit_summary_subtitle'].replace(/％/g, '').replace(/%/g, '')
            let pagetitleSuffix = visitSubtitle.replace('{patient_name}', getFullName(analytics.presentPatient)).replace('{month_and_year}'
                , analytics.currentPatientAging
                    ? moment(new Date(analytics.currentPatientAging.visitDate))
                        .locale(locale).format(getFormat(locale)) : '')
            let header = `<div class=\"page-header\" style=\"display: flex; width: 100%;margin-bottom: 5%;justify-content: center;word-break: break-word;${pagetitleSuffix.length > 35 ? 'margin-top:0; padding-top:0;' : ''}\">
            <span style=\"font-size: 18px; display: inline-flex;\">
            <div style=\"color:black;display: inline-flex;\">
            <img src=${analytics.reportlogo} style=\"width: 25px;
            height: 25px;
            padding: 0;
            padding: 0 5px;\"></img></div> <div className='report-title' 
            style=\" font-style: italic;
            color: #0093c2;
            font-size: 14px;
            \"><span style="color: black;font-size: 18px!important;font-style: normal;">${analytics.terms['Visit Summary']}</span> ${pagetitleSuffix}
            </div></span> </div>`
            if (document.getElementById('visitSummaryTemplate')) {
                var htmlMarkup = `<html>
                <head>
                <meta charset="utf-8">
                <link href='https://fonts.googleapis.com/css?family=Sarala' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Sarala:400,700' rel='stylesheet' type='text/css'>
                 <style>${visitsummaryStyles}</style>
                </head><body>${document.getElementById('visitSummaryTemplate').outerHTML}</body></html>`
                dispatch(toggleLoading(true))
                let postUrl = isDemo ? API_URL.DEMO_REPORT_DOWNLOAD : API_URL.DOWNLOAD_VISIT_SUMMARY
                axios.post(postUrl,
                    {
                        htmlMarkup,
                        fileName: '',
                        header,
                        visitId: 0,
                        document: '',
                        locale: '',
                        isMobile: isMobile
                    }).then(response => {
                        let urlParams = new URLSearchParams(history.location.search);
                        let download = urlParams.get('download')
                        let siteLogo = getState().analytics && getState().analytics.siteData && getState().analytics.siteData.logoFileName && getState().analytics.siteData.logoFileName.length > 0 ? true : false;
                        if (window.innerWidth <= 768 || siteLogo || download) {
                            if (isIE()) {
                                let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                if (window.navigator.msSaveBlob) {
                                    window.navigator.msSaveOrOpenBlob(pdfblob, "summary.pdf");
                                }
                            }
                            else {
                                const downloadLink = document.createElement('a');
                                if (!isFirefox()) {
                                    let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                    downloadLink.href = URL.createObjectURL(pdfblob)
                                } else {
                                    downloadLink.href = 'data:application/pdf;base64,' + response.data
                                }
                                downloadLink.className = 'hidden';
                                document.body.appendChild(downloadLink);
                                downloadLink.setAttribute('download', "summary.pdf");
                                downloadLink.click();
                                window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
                            }
                            dispatch(toggleLoading(false))
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    isSummaryLoading: false,
                                    currentSummaryVisitId: null
                                }
                            })
                            setTimeout(() => {
                                let previousUrl = localStorage.getItem('previousurl')
                                let redirectTo = localStorage.getItem('redirectTo')
                                if (download) {
                                    history.location = {}
                                }
                                else if (history) {
                                    if (!previousUrl && !redirectTo) {
                                        if (localStorage.getItem('currenturl')) {
                                            history.goBack()
                                        }
                                        else {
                                            if (analytics.role == constants.logged_roles.PT && siteLogo) {
                                                history.push(`/analytics`)
                                            }
                                            else {
                                                history.push(`/visits/${visitId}`)
                                            }
                                        }
                                    }
                                    else if (((previousUrl && previousUrl.indexOf('analytics') != -1) ||
                                        (redirectTo && redirectTo.indexOf('analytics') != -1))
                                        && analytics.presentPatient) {
                                        if (analytics.role == constants.logged_roles.PT && siteLogo) {
                                            history.push(`/analytics`)
                                        }
                                        else {
                                            history.push(`/patients/${analytics.presentPatient.id}/analytics`)
                                        }
                                    }
                                    else {
                                        if (analytics.role == constants.logged_roles.PT && siteLogo) {
                                            history.push(`/analytics`)
                                        }
                                        else {
                                            history.push(`/visits/${visitId}`)
                                        }
                                    }
                                }
                                else {
                                    window.location.href = '/patients/list'
                                }
                            }, 1000);
                        }
                        else {
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    downloadableFile: 'data:application/pdf;base64,' + response.data,
                                    isSummaryLoading: false,
                                    currentSummaryVisitId: null
                                }
                            })
                            document.title = 'summary.pdf'
                            dispatch(toggleLoading(false))
                        }
                    }).catch(error => {
                        dispatch(handleErrors(error));
                        dispatch(handleSummaryLoading(false))
                    })
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    isVisitSummary: false
                }
            })
        }, 8000);
    }
}
function getFullName(detail) {
    return detail ? `${detail.honorificPrefix ? `${detail.honorificPrefix} ` : ''}${detail.firstname} ${detail.lastname}${detail.honorificSuffix ? `, ${detail.honorificSuffix}` : ''}` : null
}
function getFormat(locale) {
    if (locale == 'en' || locale == 'ro' || locale == "pl")
        return 'MMMM, YYYY'
    else if (locale == 'es')
        return 'MMMM YYYY'
    else if (locale == 'ja')
        return 'YYYY年MMMM'
    else
        return 'MMMM, YYYY'
}
function setDemoStatus(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isDemo: status
            }
        })
    }
}
export function getCategoryNameBySectionName(categories, sectionKey) {
    for (const category of categories) {
        const sectionIndex = category.sections.findIndex(s => { return (s.key === sectionKey) });
        if (sectionIndex !== -1) {
            return  {name: category.name,
                     category: category
                    }
        }
    }
    return '';
}

export function getSectionAndCatgeoryNameByData(categories, code, categoryKey, sectionKey) {
    for (const category of categories) {
        if(category.key == categoryKey){
            for (const section of category.sections) {
                if (section.data && section.codes.indexOf(code) !== -1 && section.key == sectionKey) {
                    return {
                        categoryName: category.name,
                        sectionName: section.name,
                        category: category
                    };
                }
            }
        }
    }
}
export function loadDemoData() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                loadingData: "pending",
                loadingCategories: "pending",
                loadingSections: "pending",
                loadingPatient: "pending",
                loadingRecommendations: "pending",
                loadingMedications: "pending",
                loadingProblemList: "pending",
                presentPatient: {},
                siteData: null
            }
        })
        setTimeout(() => {
            dispatch(setDemoHealthAnalytics())
        }, 500)
    }
}

function setDemoHealthAnalytics() {
    return (dispatch) => {
        let demoPath = window.location.pathname.indexOf('demo_1') == 1 ? 'demo_1' : 'demo_2';
        let presentPatient = demoData.patientInfo[demoPath]
        let siteData = demoData.siteData[demoPath]
        dispatch(toggleTheme('dark-theme'))
        dispatch(setSiteTheme(siteData.id))
        let terms = {}
        demoData.termTranslations.map(t => { terms[t.key] = t.termTranslationsDTOList.text })
        dispatch({
            type: SET_PROPS,
            payload: {
                presentPatient,
                loadingPatient: "finished",
                isDemo: true,
                isDemo1: window.location.pathname.indexOf('demo_1') == 1 ? true : false,
                isDemo2: window.location.pathname.indexOf('demo_2') == 1 ? true : false
            }
        })
        let updated_lastVisitDate = presentPatient.latestVisitDate ? moment(presentPatient.latestVisitDate).format('L') : 'no-visits'
        let dateOfBirth = (presentPatient.datePreference == constants.safari_invalid_date_format && isSafari())
            ? moment(getValidSafariDate(presentPatient.dob)).format('L')
            : moment(presentPatient.dob).format('L');
        let age = Math.floor(Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true)))
        let age_round = roundNumber((Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true))), 1)
        let dateFormat = siteData.datePreference;
        let lastVisitDate = presentPatient.latestVisitDate ? setSitePreference(dateFormat, presentPatient.latestVisitDate) : ''
        var yearForRecommendations = moment(lastVisitDate).format('DD-MM-YYYY');
        let bioInfo = constants.historyComponent.bioInfo;
        var bioInformation = {}
        let patient_age = roundNumber((Math.abs(moment(new Date().toLocaleDateString('en-US')).diff(dateOfBirth, 'years', true))), 1)
        window.location.pathname.indexOf('demo_1') == 1 ?
            bioInformation = {
                'SMOKER': 'Past',
                'HYERTENSION INTERVENTION': 'Current',
                'HIGH CHOLESTEROL INTERVENTION': 'No',
                'GLUCOSE TELERANSE STATUS': 'No'
            } :
            bioInformation = {
                smoker: 'Past',
                hypertension_intervention: 'No',
                high_cholesterol_intervention: 'No',
                glucose_tolerance_status: 'No'
            }
        dispatch({
            type: SET_PROPS,
            payload: {
                presentPatient: demoData.patientInfo[demoPath],
                age,
                siteData,
                lastVisitDate,
                visit_count: presentPatient.visit_count,
                bioInfo, bioInformation, dateFormat, yearForRecommendations,
                role: constants.logged_roles.CG,
                latestVisitInfo: presentPatient.latestVisitDate,
                isMenuActive: false,
                currentProvider: demoData.providerData[demoPath],
                terms,
                updated_lastVisitDate,
                age_round,
                patient_age
            }
        })
        let recommendations = demoData.recommendationsInfo[demoPath].filter(s => s.isStopped != 1)
        let stoppedRecommendations = demoData.recommendationsInfo[demoPath].filter(s => s.isStopped == 1)
        let problems = demoData.problemsInfo[demoPath]
        let activeProblems = problems && problems.length ? problems.filter(s => ['active', 'recurrence','relapse'].includes(s.clinicalStatus)) : []
        let inactiveProblems =  problems && problems.length ? problems.filter(s => ['inactive', 'remission','resolved'].includes(s.clinicalStatus)) : []
        let unknownProblems = problems && problems.length ? problems.filter(s => ['unknown'].includes(s.clinicalStatus)) : []

        dispatch(toggleLoading(true))
        metadataAxios.get(process.env.REACT_APP_MEDICATION_BLOB_URL).then((response) => {
            dispatch(toggleLoading(false))
            let sortableKey = constants.recommendation_groups
            let groups_recommendations = response.data.groupDTO.map((rec) => { return { id: rec.id, value: rec.name } }).sort((a, b) => {
                return sortableKey.indexOf(a.value) - sortableKey.indexOf(b.value);
            });
            dispatch({
                type: SET_PROPS,
                payload: {
                    all_recommendations_data: response.data,
                    groups_recommendations: groups_recommendations,
                    brand_list: setBrandList({ all_recommendations_data: response.data }, 'General Instructions'),
                    recommendation_list: setRecommendationList({ all_recommendations_data: response.data }, 'General Instructions'),
                    strengthTypes: response.data.strengthTypesDTO ? [...(constants.blankOption), ...response.data.strengthTypesDTO] : [],
                    loadingMedications: "finished",
                }
            })
        }).catch((error) => {
            dispatch(toggleLoading(false))
            dispatch(handleErrors(error))
        })

        dispatch({
            type: SET_PROPS,
            payload: {
                recommendations: recommendations,
                stoppedRecommendations: stoppedRecommendations,
                medications: recommendations ? recommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : [],
                stoppedMedications: stoppedRecommendations ? stoppedRecommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : [],
                isRecommendationsLoading: false,
                loadingRecommendations: "finished",
                recommendationForm: { group: 'General Instructions' },
                // all_recommendations_data: demoData.allRecommendationsInfo ? demoData.allRecommendationsInfo : [],
                // groups_recommendations: demoData.allRecommendationsInfo.value.groupDTO.map((rec) => { return { id: rec.id, value: rec.name } })
                activeProblems,
                inactiveProblems,
                unknownProblems,
                problems,
                loadingProblems: "finished",
                filterProblems: []
            
            }
        })
        dispatch(setAggregateDetails(demoData.visitsData[demoPath]))
        dispatch(setBiomarkersDemoData(demoData.historicCategoryInfo))
        // dispatch(setComparingChartsData(demoData.comparingChartsData[demoPath]))
        dispatch({
            type: SET_PROPS,
            payload: {
                category: demoPath == 'demo_1' ? demoData.analyticsInfo : demoData.analyticsInfo2,
                loadingCategories: "finished",
                loadingSections: "finished",
                Data: demoPath == 'demo_1' ? demoData.historicCategoryInfo : demoData.historicCategoryInfo2,
                allVisitData: demoData.visitsData['demo_1'],
                currentPatientDocumentsList: demoData.documentsInfo[demoPath],
                metaRecommendations: demoPath == 'demo_1' ? _.head(demoData.historicCategoryInfo).metaRecommendations : [],
                noumenonReferences: demoData.noumenonReferenceInfo
            }
        })

        dispatch(dataSelector())
    }
}

export function setBiomarkersDemoData(historicObject) {
    return (dispatch, getState) => {
        var result = {}
        var biographical_labels = constants.biographical_labels.historic_data_labels;
        Object.keys(historicObject).map(s => {
            if (Object.keys(biographical_labels).includes(historicObject[s].code)) {
                result[biographical_labels[historicObject[s].code]] = []

                if (historicObject[s].historicData != null && historicObject[s].historicData.length) {
                    for (var i = 0; i < historicObject[s].historicData.length; i++) {
                        result[biographical_labels[historicObject[s].code]].push(historicObject[s].historicData[i])
                    }
                }
                else {
                    result[biographical_labels[historicObject[s].code]].push(historicObject[s])
                }
                if (historicObject[s].noumenonExplanation)
                    _.head(result[biographical_labels[historicObject[s].code]]).noumenonExplanation = historicObject[s].noumenonExplanation
            }
        });
        var biomarkerCount = 0;
        var sortedResult = {};
        if (result && Object.keys(result).length) {
            Object.keys(result).map(s => result[s].length).map(s => biomarkerCount = biomarkerCount + s);
            Object.keys(biographical_labels).forEach((label) => {

                sortedResult[biographical_labels[label]] = result[biographical_labels[label]]

            });
            sortedResult = _.pickBy(sortedResult);
        }
        sortedResult.biomarkerCount = biomarkerCount;
        dispatch(setComparingChartsData(sortedResult))
    }
}

// Survey //

export function toggleSurveyToaster(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                showSurveyToaster: value
            }
        })
    }
}
export function isPatientReportLoaded(loaded) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                patientReportLoaded: loaded
            }
        })
    }
}
export function addToTableOfContentsArray(ids) {
    return (dispatch, getState) => {
        let tableOfContents = [];
        ids.map(s => tableOfContents.push("##" + s))
        dispatch({
            type: SET_PROPS,
            payload: {
                tableOfContents: tableOfContents,
            }
        });
    }
}


export function toggleSurveyPopup(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                surveyPopup: value
            }
        })

        if (!value && getState().analytics.surveySubmitted) {
            window.location.reload();
        }
    }
}

export function editAnalyticsSurveyData(eve, surveyNo, quesNo) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false));
        let surveyData = getState().analytics && getState().analytics.surveyData ? getState().analytics.surveyData : [];
        let resultDTO = getState().analytics && getState().analytics.resultDTO ? getState().analytics.resultDTO : [];
        let validSurveyFields = getState().analytics && getState().analytics.validSurveyFields ? getState().analytics.validSurveyFields : {}
        let value = eve.target.value;
        if (resultDTO[surveyNo] && resultDTO[surveyNo].CreateObservationDTOs && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo)) {
            if (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).originalValue == null)
                if (value == "")
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = false
                else
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = true
            else
                if (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).originalValue != value)
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = true
                else
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = false
            resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value = value
            if (surveyData[surveyNo].questionsDTO && surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo) && surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO && (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low || surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high)) {
                if (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low && !surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) {
                    if ((resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value < surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low) && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value != "") {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = false;
                    }
                    else {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = true;
                    }
                }
                else if (!surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low && surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) {
                    if ((resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value > surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value != "") {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = false;
                    }
                    else {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = true;
                    }
                }
                else {
                    if ((resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value < surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low || resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value > surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value != "") {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = false;
                    }
                    else {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = true;
                    }
                }
            }
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                resultDTO,
                validSurveyFields
            }
        })
    }
}

export function editQuestionnairePageCount(change) {
    return (dispatch, getState) => {
        let surveyData = getState().analytics && getState().analytics.surveyData ? getState().analytics.surveyData : [];
        let questionnairePageCount = getState().analytics && getState().analytics.questionnairePageCount ? getState().analytics.questionnairePageCount : 1;
        let validSurveyFields = getState().analytics && getState().analytics.validSurveyFields ? getState().analytics.validSurveyFields : {};
        let surveyName = questionnairePageCount > surveyData.length ? surveyData[questionnairePageCount - 2].name : surveyData[questionnairePageCount - 1].name;
        let flag = true;
        validSurveyFields[surveyName].map((val) => {
            if (val.value == false)
                flag = false;
        })
        if (flag) {
            if (change == "add")
                questionnairePageCount += 1;
            else if (change == "sub")
                questionnairePageCount -= 1;
            else
                questionnairePageCount = change;
            dispatch({
                type: SET_PROPS,
                payload: {
                    questionnairePageCount: questionnairePageCount
                }
            })
        }
    }
}

export function submitAnalyticsSurveyForm(status) {
    return (dispatch, getState) => {
        let resultDTO = getState().analytics && getState().analytics.resultDTO ? _.cloneDeep(getState().analytics.resultDTO) : [];
        let surveyData = getState().analytics && getState().analytics.surveyData ? _.cloneDeep(getState().analytics.surveyData) : [];

        resultDTO = resultDTO.filter((survey) => {
            survey.CreateObservationDTOs = survey.CreateObservationDTOs.filter((ques) => {
                return ques.changed;
            })
            return true
        })
        let exixtingSurveyData = surveyData.filter((sData) => {
            return sData.resultId
        })
        let filledObservation = resultDTO.filter((survey) => {
            return survey.CreateObservationDTOs.length > 0
        })

        if (resultDTO.length > 0 && (exixtingSurveyData.length > 0 || filledObservation.length > 0)) {
            dispatch(toggleSurveyPopup(false));
            dispatch(toggleLoading(true))
            if (status == null)
                resultDTO = surveyData.map((sdata, sindex) => {
                    let statusArr = [];
                    sdata.questionsDTO.map((question) => {
                        if (resultDTO[sindex].CreateObservationDTOs.find((aa) => aa.code == question.noumenonDTO.code && aa.id == question.noumenonDTO.id))
                            if (resultDTO[sindex].CreateObservationDTOs.find((aa) => aa.code == question.noumenonDTO.code && aa.id == question.noumenonDTO.id).value != "")
                                statusArr.push(null)
                            else
                                statusArr.push(constants.visit_labels.pending)
                        else
                            if (question.value == null || question.value == "")
                                statusArr.push(constants.visit_labels.pending)
                            else
                                statusArr.push(null)
                    })
                    if (statusArr.includes(constants.visit_labels.pending))
                        status = constants.visit_labels.pending
                    else
                        status = null
                    if (resultDTO[sindex])
                        return {
                            ...resultDTO[sindex],
                            ...{ status: status },
                            ...{ resultId: surveyData.find((sData) => sData.orderId == resultDTO[sindex].orderId).resultId }
                        }
                })
            else
                resultDTO = resultDTO.map(survey => {
                    return {
                        ...survey,
                        ...{ status: status },
                        ...{ resultId: surveyData.find((sData) => sData.orderId == survey.orderId).resultId }
                    }
                })
            let survey = { resultDTO: resultDTO }
            let params = survey
            promisesList.push(axios.post(`${API_URL.QUESTIONNAIRE}`, { ...params }).then(() => {
                dispatch(handleLoading())
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        resultDTO: resultDTO,
                        surveySubmitted: true,
                        status: status
                    }
                })
                dispatch(toggleSurveyPopup(true));
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        }
    }
}

export function getAnalyticsSurveyData(visitId, locale) {
    return (dispatch, getState) => {
        if (visitId) {
            promisesList.push(axios.get(`${API_URL.QUESTIONNAIRE_QUESTIONS}?visitId=${visitId ? visitId : 0}&locale=${locale}`).then((response) => {
                let resultDTO = [];
                let validSurveyFields = {};
                response.data.map((obj, index) => {
                    let survey = {
                        orderId: obj.orderId,
                        visitId: visitId,
                        name: obj.name,
                        CreateObservationDTOs: []
                    }
                    validSurveyFields[obj.name] = [];
                    let CreateObservationDTOs = [];
                    obj.introductionsDTO ? obj.introductionsDTO.map((i) => {
                        i.questionsDTO.map((ques, index) => {
                            validSurveyFields[obj.name].push({ id: ques.id, value: true })
                            let observation = {
                                id: ques.id,
                                code: ques.noumenonDTO && ques.noumenonDTO.code ? ques.noumenonDTO.code : "",
                                valueType: ques.noumenonDTO && ques.noumenonDTO.valueType == "Numeric" ? "NM" : (ques.noumenonDTO.valueType == "Enumerable" ? "TX" : null),
                                identifier: "",
                                subIdentifier: "",
                                label: ques.question ? ques.question : "",
                                questionnaireLabel: ques.noumenonDTO && ques.noumenonDTO.name ? ques.noumenonDTO.name : "",
                                value: "",
                                units: ques.dataRangesDTO && ques.dataRangesDTO.units ? ques.dataRangesDTO.units : null,
                                referenceRange: ques.noumenonDTO && ques.noumenonDTO.valueType == "Enumerable" ? null : (ques.dataRangesDTO && ques.dataRangesDTO ? (ques.dataRangesDTO.low && ques.dataRangesDTO.high ? ques.dataRangesDTO.low + "-" + ques.dataRangesDTO.high : (ques.dataRangesDTO.low && !ques.dataRangesDTO.high ? '>' + ques.dataRangesDTO.low : (ques.dataRangesDTO.high && !ques.dataRangesDTO.low) ? '<' + ques.dataRangesDTO.high : "")) : ""),
                                abnormalFlags: "",
                                resultStatus: "C",
                                isSurvey: true,
                                observationId: ques.observationId,
                                resultId: obj.resultId,
                                changed: false
                            }
                            CreateObservationDTOs.push(observation);
                        })
                    }) : null
                    obj.questionsDTO ? obj.questionsDTO.map((ques, index) => {
                        validSurveyFields[obj.name].push({ id: ques.id, value: true })
                        let observation = {
                            id: ques.id,
                            code: ques.noumenonDTO && ques.noumenonDTO.code ? ques.noumenonDTO.code : "",
                            valueType: ques.noumenonDTO && ques.noumenonDTO.valueType == "Numeric" ? "NM" : (ques.noumenonDTO.valueType == "Enumerable" ? "TX" : null),
                            identifier: "",
                            subIdentifier: "",
                            label: ques.question ? ques.question : "",
                            questionnaireLabel: ques.noumenonDTO && ques.noumenonDTO.name ? ques.noumenonDTO.name : "",
                            value: "",
                            units: ques.dataRangesDTO && ques.dataRangesDTO.units ? ques.dataRangesDTO.units : null,
                            referenceRange: ques.noumenonDTO && ques.noumenonDTO.valueType == "Enumerable" ? null : (ques.dataRangesDTO && ques.dataRangesDTO ? (ques.dataRangesDTO.low && ques.dataRangesDTO.high ? ques.dataRangesDTO.low + "-" + ques.dataRangesDTO.high : (ques.dataRangesDTO.low && !ques.dataRangesDTO.high ? '>' + ques.dataRangesDTO.low : (ques.dataRangesDTO.high && !ques.dataRangesDTO.low) ? '<' + ques.dataRangesDTO.high : "")) : ""),
                            abnormalFlags: "",
                            resultStatus: "C",
                            isSurvey: true,
                            observationId: ques.observationId,
                            resultId: obj.resultId,
                            changed: false
                        }
                        CreateObservationDTOs.push(observation);
                    }) : null
                    survey.CreateObservationDTOs = CreateObservationDTOs;
                    resultDTO.push(survey);
                })
                response.data.map((res) => res.questionsDTO ? "" : res.questionsDTO = [])
                response.data.map((res) => res.introductionsDTO && res.introductionsDTO ? res.questionsDTO.push(res.introductionsDTO.map((i) => i.questionsDTO)) : '')
                response.data.map((res) => res.questionsDTO = res.questionsDTO.flat(2))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        surveyData: response.data,
                        resultDTO,
                        validSurveyFields
                    }
                })
                response.data && response.data.length ? dispatch(toggleSurveyToaster(true)) : ''
            }).catch(error => {
                dispatch(toggleSurveyToaster(false))
                dispatch(handleErrors(error))
            }))
        }
    }
}
export function setActiveRecommendationToggle(indicator, type) {
    return (dispatch, getState) => {
        if (!type) {
            let currentRecommendations = indicator && getState().analytics.recommendations ? getState().analytics.recommendations.filter(s => s.indicators.includes(indicator)) : []
            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationOverViewList: currentRecommendations && currentRecommendations.length,
                    isMoreInfoToggle: currentRecommendations && currentRecommendations.length ? false : true,
                    isInterpretationToggle: false
                }
            })
        }
        else if (type == 'more-info') {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isMoreInfoToggle: true,
                    isInterpretationToggle: false,
                    isRecommendationOverViewList: false
                }
            })
        }
        else if (type == 'interpretations') {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isInterpretationToggle: true,
                    isMoreInfoToggle: false,
                    isRecommendationOverViewList: false
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationOverViewList: true,
                    isMoreInfoToggle: false,
                    isInterpretationToggle: false,
                }
            })
        }
    }
}

export function setDrawerType(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                drawerType: value,
                datumValues: null
            }
        })
    }
}

export function unMountingLoadingComponent(val) {
    return (dispatch, getState) => {
        if (val) {
            setTimeout(() => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        unMountLoadingComponent: val
                    }
                })
            }, 600);
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    unMountLoadingComponent: val
                }
            })
        }
    }
}

export function navigateHealthAnalytics(history, path) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: getState().providers,
            }
        })
        history.push(path)
    }
}
export function collapseAll() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNomenon: null,
                currentCategory: null
            }
        })
    }
}

export function updateLoadingStatus(isInternetError) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isInternetError
            }
        })
    }
}
function returnUniqueNoumenon(noumena) {
    var resArr = [];
    noumena && noumena.length && noumena.filter(function (item) {
        var i = resArr.findIndex(x => (x.value == item.value && x.type == item.type));
        if (i <= -1) {
            resArr.push(item);
        }
    });
    return resArr;
}
export function setNoumenonList() {
    return (dispatch, getState) => {
        let categories = getState().analytics.categories ? getState().analytics.categories : []
        let noumenonList = []
        categories.map(cat => noumenonList.push({ value: cat.name, name: cat.name, key: cat.key, code: cat.code, type: "category", category: null, section: null, noumenon: null }))
        categories.map(cat => cat.sections.map(section => noumenonList.push({ value: section.name, name: section.name, key: section.key, code: section.code, type: "section", category: cat.key, section: null, noumenon: null })))
        categories.map(cat => cat.sections.map(section => section.data.map(d => noumenonList.push({ value: d.label, key: d.key, type: "datum", code: d.code, label: d.label, category: cat.key, section: section.key, noumenon: d.label }))))
        dispatch({
            type: SET_PROPS,
            payload: {
                noumenonList: returnUniqueNoumenon(noumenonList ? noumenonList.sort((a, b) => (a.value > b.value) ? 1 : -1) : [])
            }
        })
    }
}

export function editNoumenonSearch(event, history) {
    return (dispatch, getState) => {
        let category;
        let section;
        let datum;
        if (event) {
            if (event.type == "category") {
                category = getState().analytics.categories.find((cat) => cat.key == event.key)
                dispatch(toggleCategoryOverview('category', category, getCategoryGrades(getState().analytics.grading_data, category)))
            }
            else if (event.type == "section") {
                category = getState().analytics.categories.find((cat) => cat.key == event.category)
                section = category.sections.find(section => section.key == event.key)
                dispatch(toggleSectionOverview(getState().analytics.categories, 'section', section, getSectionGradeData(getState().analytics.grading_data, section)))
            }
            else if (event.type == "datum") {
                category = getState().analytics.categories.find((cat) => cat.key == event.category)
                section = category.sections.find(section => section.key == event.section)
                datum = section.data.find(data => data.code == event.code)
                dispatch(toggleDatumOverview(getState().analytics.categories, datum, section, 'datum'))
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentCategory: category,
                    currentSection: section,
                    currentNomenon: datum
                }
            })
            dispatch(setSearchField(true))
        }
    }
}

function getCategoryGrades(gradeData, category) {
    var gradeArray = []
    gradeData && gradeData.length ?
        gradeData.map(s => {
            return s.data.map(t => {
                if (t.key == category.key) {
                    t.value = t.value ? round(t.value.toString(), 2) : t.value
                    gradeArray.push(t)
                }
            })
        }) : ''
    if (gradeArray && gradeArray.length)
        return gradeArray.filter(s => s.value);
}


function getSectionGradeData(grade_data, section) {
    var sectionGrades = []
    grade_data && grade_data.length ?
        grade_data.map(s => {
            s.data.map(t => {
                t.sections.map(u => {
                    if (u.key == section.key) {
                        u.value = u.value ? round(u.value.toString(), 2) : u.value
                        return sectionGrades.push(u)
                    }
                })
            })
        }) : ''
    if (sectionGrades && sectionGrades.length)
        return sectionGrades.filter(s => s.value)
}

export function setSearchField(value) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isSearchField: value
            }
        })
    }
}

export function toggleNomenonDetails(value) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                openNomenonDetails: value
            }
        })
    }
}

export function editRecommendationSearch(event, history, recType, type) {
    return (dispatch, getState) => {
        let filterRecommendations;
        if (event) {
            if (recType == "Recommendation"){
                if (type== "meta"){
                    filterRecommendations = getState().analytics.metaRecommendations.find((recommendation) => recommendation.id == event.id)
                }
                else if (type == "stopped"){
                    filterRecommendations = getState().analytics.stoppedRecommendations.find((recommendation) => recommendation.id == event.id)
                }
                else{
                    filterRecommendations = getState().analytics.recommendations.find((recommendation) => recommendation.id == event.id)
                }
            }
            else {
                if (type == "stopped"){
                    filterRecommendations = getState().analytics.stoppedMedications.find((recommendation) => recommendation.id == event.id)
                }
                else{
                    filterRecommendations = getState().analytics.medications.find((recommendation) => recommendation.id == event.id)
                }

            }    
            dispatch({
                type: SET_PROPS,
                payload: {
                    filterRecommendations : [filterRecommendations]
                }
            })
        }
    }
}

export function resetRecommendationFilter() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                filterRecommendations : []
            }
        })
    }
}

export function toggleRecommendFormWarning(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendFormWarning: value
            }
        })
    }
}
export function toggleTheme(value) {
    return (dispatch, getState) => {
        let theme = value
        if (theme == "light-theme") {
            theme = "dark-theme"
        } else {
            theme = "light-theme"
        }
        let isDarkMode = theme == 'light-theme' ? 0 : 1
        let userThemePreference = getState().global.loggedInUser.isDarkMode
        if (userThemePreference != isDarkMode){
            dispatch(UpdateThemePreference(isDarkMode))
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                theme: theme
            }
        })
    }
}

// PMI-3542
export function UpdateThemePreference(isDarkMode){
    return (dispatch, getState) => {
        let isDemo = getState().analytics.isDemo 
        if (!isDemo){
            dispatch(toggleLoading(true))
            axios.put(`${API_URL.SET_THEME_PREFERENCE}?isDarkMode=${isDarkMode}`).then((response) => {
                dispatch(toggleLoading(false))
                let loggedInUser = getState().global.loggedInUser
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        loggedInUser: { ...loggedInUser, isDarkMode: isDarkMode}
                    }
                })
            }).then(error => {
                dispatch(handleErrors(error))
            })
            dispatch(toggleLoading(false))
        }
    }

}

export function getCustomCategories() {
    return (dispatch, getState) => {
        let categories = getState().analytics && getState().analytics.categories ? getState().analytics.categories : []
        let customCategories = categories.map(cat => {
            if (cat.sections.length === 1 && cat.name === cat.sections[0].name) {
                let updatedCategory = { data: cat.sections[0].data, ...cat }
                updatedCategory['sections'] = [];
                return updatedCategory;
            } else {
                return cat;
            }
        });
        dispatch({
            type: SET_PROPS,
            payload: {
                customCategories: customCategories
            }
        })
    }
}

export function toggleCategoryDetailsModal(value, row) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                showCategoryDetailsModal: value,
            }
        })
        if (row) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentCategory: row,
                }
            })
        }

    }
}
// Feedback
export function toggleFeedbackModel(value) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                showFeedbackModel: value,
            }
        })
        if (value){
            dispatch({
                type: SET_PROPS,
                payload: {
                    feedbackForm: {},
                    feedbackErrors: []
                }
            })
        }
    }
}

function submitFeedback(history) {
    return (dispatch, getState) => {
        
        let feedbackForm = getState().analytics.feedbackForm ? getState().analytics.feedbackForm : ''
        if (feedbackForm) {
            let req = requiredFieldsFeedback.filter(rf => !feedbackForm[rf] || feedbackForm[rf] == "")
            if (req && req.length) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        feedbackErrors: req.map(r => `${requiredFieldsFeedbackText[r]} ${labels.required_label}`),
                        feedbackForm: feedbackForm,
                    }
                })
            }
            else {
                dispatch(toggleLoading(true))
                dispatch(toggleFeedbackModel(false))
                let feedbackParams = [];
                feedbackParams.Comment = feedbackForm.feedback_text ? feedbackForm.feedback_text.replace(new RegExp(/\n/, 'g'),'<br/>' ) : ""
                feedbackParams.Rating = feedbackForm.rating
                feedbackParams.Source = 'Analytics'
                feedbackParams.UserID = getState().analytics.loggedInUser.id
                axios.post(`${API_URL.FEEDBACK}`, { ...feedbackParams }).then(response => {
                    dispatch(toggleLoading(false))
                    let message = labels.feedback_labels.feedback_success
                    notifySuccess(message)
                }).then(error => {
                    dispatch(handleErrors(error))
                })
                dispatch(toggleLoading(false))
                // let message = labels.feedback_labels.feedback_success
                // notifySuccess(message)
            }  
        }      
        else {
            dispatch(toggleLoading(true))
            let error = messages.feedback_validation
            notifyError(error)
            dispatch(toggleLoading(false))
        }    
    }
}

export function editFeedbackForm(event) {
    return (dispatch, getState) => {
        let feedbackForm = { ...getState().analytics.feedbackForm }
        let key = event.target.id
        let value = event.target.value
        if (getState().analytics.feedbackForm && getState().analytics.feedbackForm[key] != value) {
            feedbackForm = getState().analytics.feedbackForm ? { ...getState().analytics.feedbackForm, [key]: value } : { [key]: value }
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                feedbackForm,
                feedbackErrors: []
            }
        })
    }
}
// Problem List

export function getSnomedCtCode() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        metadataAxios.get(process.env.REACT_APP_SNOMED_CT_BLOB_URL).then((response) => {
            let code_list = response.data.value.clinicalFinding.map((code) => { return { value: code.term, label: code.term} })
            let custom_code_list = response.data.value.customClinicalFinding ? response.data.value.customClinicalFinding.map((code) => { return { value: code.term, label: code.term} }) : []
            
            if (custom_code_list.length > 0){
                code_list = [...code_list, ...custom_code_list];
            }

            let bodySite_list = response.data.value.bodyStructure.map((bodysite) => { return { value: bodysite.term, label: bodysite.term} })
            let custom_bodySite_list = response.data.value.customBodyStructure ? response.data.value.customBodyStructure.map((bodysite) => { return { value: bodysite.term, label: bodysite.term} }) : []

            if (custom_bodySite_list.length > 0){
                bodySite_list = [...bodySite_list, ...custom_bodySite_list];
            }

            dispatch({
                type: SET_PROPS,
                payload: {
                    code_list: code_list,
                    bodySite_list: bodySite_list,
                    loadingProblemList: "finished"
                }
            })
            dispatch(toggleLoading(false))
        }).catch((error) => {
            dispatch(toggleLoading(false))
            dispatch(handleErrors(error))
        })
    }
}

export function toggleProblemModal(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                problemForm: {category: 'problem-list-item', recordedDate: new Date() },
                initialProblemForm: {category: 'problem-list-item', recordedDate: new Date() },
            }
        })
        dispatch(showNoProblemFormChangesError(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                showProblemModal: value,
            }
        })
    }
}

export function newProblem() {
    return (dispatch, getState) => {
    
        dispatch({
            type: SET_PROPS,
            payload: {
                problemForm: { category: 'problem-list-item', recordedDate: new Date() },
                initialProblemForm: {category: 'problem-list-item', recordedDate: new Date() },
            }
        })
        dispatch(setProblemNotes())
        dispatch(showNoProblemFormChangesError(false));
    }
}

export function editProblem(event, recId) {
    return (dispatch, getState) => {
        let problemForm = { ...getState().analytics.problemForm }        
        if (['clinicalStatus', 'verificationStatus', 'category', 'severity', 'onSetType', 'abatementType',].includes(recId)) {
            if (event != null) {
                problemForm = { ...getState().analytics.problemForm, [recId]: event.value }
                if (recId ==  "onSetType"){
                    problemForm = { ...getState().analytics.problemForm, [recId]: event.value,  onSetValue2: "", onSetValue1: "" }
                }
                else if (recId ==  "abatementType"){
                    problemForm = { ...getState().analytics.problemForm, [recId]: event.value,  abatementValue2: "", abatementValue1: "" }
                }
                else{
                    problemForm = { ...getState().analytics.problemForm, [recId]: event.value }
                }
            }
            else{
                 problemForm = { ...problemForm, [recId]: null }
            }
        }
        else if (['code', 'bodySite'].includes(recId)) {
            if (event != null) {
                problemForm = { ...getState().analytics.problemForm, [recId]: _.head(event).value }
            }
            else {
                 problemForm = { ...problemForm, [recId]: null }
            }
        }
        else if (['abatementValue1', 'abatementValue2', 'onSetValue2', 'onSetValue1', 'recordedDate'].includes(recId)) {
            if (event != null) {
                problemForm = { ...getState().analytics.problemForm, [recId]: event }
            }
            else {
                problemForm = { ...problemForm, [recId]: null }
            }
        }

        else {
            let key = event.target.id
            let value = event.target.value
            if (key == "notes" && getState().analytics.problemForm && getState().analytics.problemForm[key] != value){
                problemForm = getState().analytics.problemForm ? { ...getState().analytics.problemForm, [key]: value.split('\n'), } : { [key]: value.split('\n')}
            }
            else if (getState().analytics.problemForm && getState().analytics.problemForm[key] != value) {
                problemForm = getState().analytics.problemForm ? { ...getState().analytics.problemForm, [key]: value, } : { [key]: value}
            }
            else {
                problemForm = getState().analytics.problemForm
            }
            if (['onSetType'].includes(key)){
                problemForm = { ...problemForm, onSetValue1: "", onSetValue2: "" }
            }
            if (['abatementType'].includes(key)){
                problemForm = { ...problemForm, abatementValue1: "", abatementValue2: "" }
            }
        }
        dispatch(showNoProblemFormChangesError(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                problemForm,
                problemErrors: []
            }
        })
    }
}

export function toggleProblemFormWarning(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                problemFormWarning: value
            }
        })
    }
}

export function showNoProblemFormChangesError(val) {
    return (dispatch, getState) => {
        let problemErrors = getState().analytics.problemErrors ? [...getState().analytics.problemErrors] : [];
        if (val)
            problemErrors.push(labels.no_problem_changes);
        else {
            let errIndex = problemErrors.indexOf(labels.no_problem_changes);
            problemErrors.splice(errIndex, 1);
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                problemErrors: problemErrors
            }
        })
    }
}

export function saveProblem(history) {
    return (dispatch, getState) => {
        let problemFormData = getState().analytics.problemForm
        let isDemo = getState().analytics.isDemo
        let isPatientDemo = getState().analytics.isPatientDemo
       
        let demoPath = window.location.pathname.indexOf('demo_1') == 1 ? 'demo_1' : 'demo_2';
        dispatch({
            type: SET_PROPS,
            payload: {
                isProblemsLoading: true,
                problemForm: ''
            }
        })
        var currentProblem = null;

        currentProblem = {
            ...problemFormData
        }
        currentProblem['id'] = problemFormData ? problemFormData.id : 0
        currentProblem['patientId'] = getState().analytics.presentPatient.id
        currentProblem['visitId'] = currentProblem && currentProblem.visitId ? currentProblem.visitId : problemFormData && problemFormData.visitID ? problemFormData.visitID : getState().analytics.presentPatient.latestVisitId 
        currentProblem['siteId'] = getState().analytics.presentPatient.siteId
        currentProblem['category'] = constants.ProblemDefaultCategory
        currentProblem['isCustomCode']  = getState().analytics.code_list && getState().analytics.code_list.find((r) => r.value == problemFormData.code) || (problemFormData.code == "" || problemFormData.code == null)  ?  'false' : 'true' 
        currentProblem['isCustomBodySite']  = getState().analytics.bodySite_list && getState().analytics.bodySite_list.find((r) => r.value == problemFormData.bodySite) || (problemFormData.bodySite == "" || problemFormData.bodySite == null)  ?  'false' : 'true'     
        currentProblem['recordedDate'] = currentProblem.recordedDate ? moment(currentProblem.recordedDate).format('YYYY-MM-DD HH:mm:ss') : null
    
        let req = problemRequiredFields.filter(rf => (!currentProblem[rf]))
        if (req && req.length) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    problemErrors: req.map(r => `${problemRequiredFieldText[r]} ${labels.required_label}`),
                    isProblemsLoading: false,
                    problemForm: { id: currentProblem.id, clinicalStatus: currentProblem.clinicalStatus, verificationStatus: currentProblem.verificationStatus, 
                        category: currentProblem.category, severity: currentProblem.severity, code: currentProblem.code,
                        bodySite: currentProblem.bodySite, onSetType: currentProblem.onSetType, onSetValue1: currentProblem.onSetValue1, onSetValue2: currentProblem.onSetValue2,  
                        abatementType: currentProblem.abatementType, abatementValue1: currentProblem.abatementValue1, abatementValue2: currentProblem.abatementValue2,
                        recordedDate: currentProblem.recordedDate, note: currentProblem.note, visitId: currentProblem.visitId },
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    problemErrors: [],
                }
            })
            dispatch(showNoProblemFormChangesError(false));
            dispatch(toggleProblemModal(false))
            if (!isDemo && !isPatientDemo) {
                let uri = currentProblem.id ? `${API_URL.PROBLEMS_URL}` : API_URL.PROBLEMS_URL
                axios[currentProblem.id ? 'put' : 'post']( uri, { ...currentProblem }).then((response) => {
                    dispatch(getProblems())   
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            initialProblemForm: { },
                        }
                    })
                   
                    notifySuccess(`Problem ${actionType(currentProblem, getState().analytics.resumeRecommendation)} successfully`)
                }).catch(errors => {
                    let recError = errors.response && errors.response.data ? errors.response.data : ''
                    if (errors.response && errors.response.status == constants.status_codes.unauthorized)
                        dispatch(handleErrors(errors))
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            problemErrors: recError ? typeof (recError) == 'string' ? [recError] : recError.value ? [recError.value] : null : null,
                            isProblemsLoading: false,
                            problemForm: currentProblem,
                            initialProblemForm: currentProblem,
                        }
                    })
                    // dispatch(showNoProblemFormChangesError(false));
                    // dispatch(toggleProblemModal(false))
                }) 
            } else {
                dispatch(setProblemType('active'))
                let allProblems = getState().analytics.problems
                    ? getState().analytics.problems : demoData.problemsInfo[demoPath]

                currentProblem = {
                    ...currentProblem
                }
                let finalRec = []
                allProblems.map(rec => {
                    if (rec.id && rec.id == currentProblem.id)
                        finalRec.push(currentProblem)
                    else finalRec.push(rec)
                })
                if (!currentProblem.id && finalRec.filter(s => s.id === undefined && (_.head(s.indicators) == _.head(currentProblem.indicators))).length < 200) {
                    currentProblem = {
                        ...currentProblem,
                        id: Math.floor((Math.random() * 1000000) + 1)
                    }

                    finalRec.push(currentProblem)
                }
                let problems = finalRec && finalRec.length ? finalRec.filter(s => s.isStopped != 1) : []
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isProblemsLoading: false,
                        problemForm: { },
                        currentProblem: null,
                        initialProblemForm: { },
                        problems,
                        activeProblems: problems && problems.length ? problems.filter(s => ['active', 'recurrence','relapse'].includes(s.clinicalStatus)) : [],
                        inactiveProblems: problems && problems.length ? problems.filter(s => ['inactive', 'remission','resolved'].includes(s.clinicalStatus)) : [],
                        unknownProblems: problems && problems.length ? problems.filter(s => ['unknown'].includes(s.clinicalStatus)) : [],
                    }
                })
            }
        }
    }
}

export function getProblems(locale, patientId) {
    return (dispatch, getState) => {

        locale = locale ? locale : "en"
        patientId = patientId ? patientId : getState().analytics.presentPatient.id
        dispatch({
            type: SET_PROPS,
            payload: {
                problems: [],
                isProblemsLoading: true,
            }
        })
        axios.get(`${API_URL.GET_PROBLEMS_URL}?locale=${locale}&patientId=${patientId}&timestamp=${new Date().getTime()}`).then((response) => {
            let problems = response.data;
            dispatch({
                type: SET_PROPS,
                payload: {
                    problems,
                    activeProblems: problems && problems.length ? problems.filter(s => ['active', 'recurrence','relapse'].includes(s.clinicalStatus)) : [],
                    inactiveProblems: problems && problems.length ? problems.filter(s => ['inactive', 'remission','resolved'].includes(s.clinicalStatus)) : [],
                    unknownProblems: problems && problems.length ? problems.filter(s => ['unknown'].includes(s.clinicalStatus)) : [],
                    isReorder: false,
                    // initialPriorities,
                    isProblemsLoading: false,
                    loadingProblems: "finished",
                    problemForm: { },
                    problemType: "active",
                    filterProblems: []
                }
            })
            if (problems){
                let code_list = getState().analytics && getState().analytics.code_list ? getState().analytics.code_list : []
                let bodySite_list = getState().analytics && getState().analytics.bodySite_list ? getState().analytics.bodySite_list : []
                let updatedCodeList = [...code_list];
                let updatedBodySite_list = [...bodySite_list];
                problems.forEach(problem => {
                    if (!updatedCodeList.find(code => code.value === problem.code)) {
                        updatedCodeList.push({ value: problem.code, label: problem.code });
                    }
                    if (!updatedBodySite_list.find(bodySite => bodySite.value === problem.bodySite)) {
                        updatedBodySite_list.push({ value: problem.bodySite, label: problem.bodySite });
                    }
                });
                let seen = {};
                let uniqueCodeList = [];
                for (let bodySite of updatedCodeList) {
                    if (!seen[bodySite.value]) {
                        uniqueCodeList.push(bodySite);
                      seen[bodySite.value] = true;
                    }
                }

                seen = {};
                let uniqueBodySiteList = [];
                for (let bodySite of updatedBodySite_list) {
                    if (!seen[bodySite.value]) {
                      uniqueBodySiteList.push(bodySite);
                      seen[bodySite.value] = true;
                    }
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                    code_list: uniqueCodeList,
                    bodySite_list: uniqueBodySiteList
                    }
                });
            }
        }).catch((error) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isProblemsLoading: false,
                    loadingProblems: constants.notification_type.error
                }
            })
            dispatch(handleErrors(error))
        })         
    }
}
export function setProblemType(type) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                problemType: type
            }
        })
        dispatch(toggleLoading(false))
    }
}

export function updateProblem(id, recomm) {
    return (dispatch, getState) => {
        let updateProblem = getState().analytics;
        let problemForm = {};
        problemForm = _.head(updateProblem.problems.filter(rec => rec.id == id));
        let notesList = [];

        if (recomm) {
            problemForm.isStopped = recomm == 'stop' ? 1 : 0
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                problemForm
            }
        })
        
        dispatch(showNoProblemFormChangesError(false));
        let clinicalStatus = getState().analytics.problemForm && getState().analytics.problemForm.clinicalStatus ? getState().analytics.problemForm.clinicalStatus : '';
        let verificationStatus = getState().analytics.problemForm && getState().analytics.problemForm.verificationStatus ? getState().analytics.problemForm.verificationStatus : '';
        let category = getState().analytics.problemForm && getState().analytics.problemForm.category ? getState().analytics.problemForm.category : '';
        let severity = getState().analytics.problemForm && getState().analytics.problemForm.severity ? getState().analytics.problemForm.severity : '';
        let code = getState().analytics.problemForm && getState().analytics.problemForm.code ? getState().analytics.problemForm.code : '';
        let bodySite = getState().analytics.problemForm && getState().analytics.problemForm.bodySite ? getState().analytics.problemForm.bodySite : '';
        let onSetType = getState().analytics.problemForm && getState().analytics.problemForm.onSetType ? getState().analytics.problemForm.onSetType : '';
        let onSetValue1 = getState().analytics.problemForm && getState().analytics.problemForm.onSetValue1 ? getState().analytics.problemForm.onSetValue1 : '';
        let onSetValue2 = getState().analytics.problemForm && getState().analytics.problemForm.onSetValue2 ? getState().analytics.problemForm.onSetValue2 : '';
        let abatementType = getState().analytics.problemForm && getState().analytics.problemForm.abatementType ? getState().analytics.problemForm.abatementType : '';
        let abatementValue1 = getState().analytics.problemForm && getState().analytics.problemForm.abatementValue1 ? getState().analytics.problemForm.abatementValue1 : '';
        let abatementValue2 = getState().analytics.problemForm && getState().analytics.problemForm.abatementValue2 ? getState().analytics.problemForm.abatementValue2 : '';
        let recordedDate = getState().analytics.problemForm && getState().analytics.problemForm.recordedDate ? getState().analytics.problemForm.recordedDate : [];
        let notes = getState().analytics.problemForm && getState().analytics.problemForm.notes ? getState().analytics.problemForm.notes : {};
        notesList = notes.map(note => 
            ({
                ID: note.id,
                ProblemID: note.problemID,
                note: note.note,
                uuid: setUuid(),
            })
        )
        if (notesList.length == 0){
            notesList = 
                [{
                    ID: 0,
                    ProblemID: 0,
                    note: "",
                    uuid: setUuid()
                }]
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                notesList
            }
        })

        if (updateProblem && updateProblem.problemCurrentRow) {
            clinicalStatus = updateProblem.problemCurrentRow.clinicalStatus ? updateProblem.problemCurrentRow.clinicalStatus : '';
            verificationStatus = updateProblem.problemCurrentRow.verificationStatus ? updateProblem.problemCurrentRow.verificationStatus : '';
            category = updateProblem.problemCurrentRow.category ? updateProblem.problemCurrentRow.category : '';
            severity = updateProblem.problemCurrentRow.severity ? updateProblem.problemCurrentRow.severity : '';
            code = updateProblem.problemCurrentRow.code ? updateProblem.problemCurrentRow.code : '';
            bodySite = updateProblem.problemCurrentRow.bodySite ? updateProblem.problemCurrentRow.bodySite : '';
            onSetType = updateProblem.problemCurrentRow.onSetType ? updateProblem.problemCurrentRow.onSetType : '';
            onSetValue1 = updateProblem.problemCurrentRow.onSetValue1 ? updateProblem.problemCurrentRow.onSetValue1 : '';
            onSetValue2 = updateProblem.problemCurrentRow.onSetValue2 ? updateProblem.problemCurrentRow.onSetValue2 : '';
            abatementType = updateProblem.problemCurrentRow.abatementType ? updateProblem.problemCurrentRow.abatementType : '';
            abatementValue1 = updateProblem.problemCurrentRow.abatementValue1 ? updateProblem.problemCurrentRow.abatementValue1 : '';
            abatementValue2 = updateProblem.problemCurrentRow.abatementValue2 ? updateProblem.problemCurrentRow.abatementValue2 : '';
            recordedDate = updateProblem.problemCurrentRow.recordedDate ? updateProblem.problemCurrentRow.recordedDate : '';
            notes = updateProblem.problemCurrentRow.note ? updateProblem.problemCurrentRow.notes : [];
            notesList = []
            notesList = notes.map(note => 
                ({
                    ID: note.id,
                    ProblemID: note.problemID,
                    note: note,
                    uuid: setUuid(),
                })
            )
            if (notesList.length == 0){
                notesList = 
                    [{
                        ID: 0,
                        ProblemID: 0,
                        note: "",
                        uuid: setUuid()
                    }]
            }    
            problemForm = {...problemForm, clinicalStatus: clinicalStatus, verificationStatus: verificationStatus, 
                category: category, severity: severity, code: code,
                bodySite: bodySite, onSetType: onSetType, onSetValue1: onSetValue1, onSetValue2: onSetValue2,  
                abatementType: abatementType, abatementValue1: abatementValue1, abatementValue2: abatementValue2,
                recordedDate: recordedDate, notes: notes},            
            dispatch({
                type: SET_PROPS,
                payload: {
                    problemForm,
                    notesList
                }
            })
        }

        dispatch(setInitialProblemForm(clinicalStatus, verificationStatus, category, severity, code, bodySite, onSetType, onSetValue1, onSetValue2, abatementType, abatementValue1, abatementValue2, recordedDate, notes));
    }
}

export function setInitialProblemForm(clinicalStatus, verificationStatus, category, severity, code, bodySite, onSetType, onSetValue1, onSetValue2, abatementType, abatementValue1, abatementValue2, recordedDate, notes) {
    return (dispatch, getState) => {
        let initialProblemForm = { clinicalStatus: clinicalStatus, verificationStatus: verificationStatus, 
            category: category, severity: severity, code: code,
            bodySite: bodySite, onSetType: onSetType, onSetValue1: onSetValue1, onSetValue2: onSetValue2,  
            abatementType: abatementType, abatementValue1: abatementValue1, abatementValue2: abatementValue2,
            recordedDate: recordedDate, notes: notes }
        dispatch({
            type: SET_PROPS,
            payload: {
                initialProblemForm
            }
        })
    }
}

export function toggleDeleteProblemPopup(value, deleteProblemId, problemCurrentRow) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                deleteProblemPopup: value,
                deleteProblemId: deleteProblemId,
            }
        })
        if (problemCurrentRow) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    problemCurrentRow: { ...problemCurrentRow }
                }
            })
        }
        if (!value) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    problemCurrentRow: null
                }
            })
        }

    }
}

export function deleteProblem(id) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isProblemsLoading: true,
                problemsForm: {  },
            }
        })
        let isDemo = getState().analytics.isDemo
        if (!isDemo) {
            axios.delete(`${API_URL.PROBLEMS_URL}/${id}`).then(response => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isProblemsLoading: false,
                    }
                })
                dispatch(getProblems());
            })
        } else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isProblemsLoading: false,
                    problemForm: { group: groupName },
                    currentProblem: null,
                }
            })
        }
        notifyError(`Problem deleted successfully`)
    }
}

function setUuid() {
    const min = 1;
    const max = 1000;
    const rand = min + Math.random() * (max - min);
    return rand
}

export function setProblemNotes() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                notesList: [
                    {
                        ID: 0,
                        ProblemID: 0,
                        note: "",
                        uuid: setUuid(),
                    }
                ]
            }
        })
    }
}

export function newProblemNotes() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                notesList: [
                    ...getState().analytics.notesList,
                    {
                        ID: 0,
                        ProblemID: 0,
                        note: "",
                        uuid: setUuid(),
                    }
                ]
            }
        })
    }
}

export function removeProblemNotes(uuid) {
    return (dispatch, getState) => {
        let notesList = getState().analytics.notesList
        notesList = notesList.filter((cf) => cf.uuid != uuid)
        let problemForm = { ...getState().analytics.problemForm }
        problemForm = {...problemForm,  notes: notesList }
        dispatch({
            type: SET_PROPS,
            payload: {
                notesList,
                problemForm
            }
        })
    }
}
export function addProblemNotes(index, event, uuid) {
    return (dispatch, getState) => {
        let problemForm = { ...getState().analytics.problemForm }
        let notesList = getState().analytics.notesList

        if (uuid != null) {
            notesList.find(note => note.uuid == uuid).note = event.target.value
            problemForm = {...problemForm,  notes: notesList }
        }
        else{
            notesList.find(note => note.uuid == uuid).note = ""
            problemForm = {...problemForm,  notes: notesList }
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                notesList: notesList,
                problemForm
            }
        })

    }
}       

export function editProblemSearch(event, history, type) {
    return (dispatch, getState) => {
        let filterProblems;
       
        if (event) {
            if (type== "active"){
                filterProblems = getState().analytics.activeProblems.filter((problem) => problem.id == event.id)
            }
            else if (type == "inactive"){
                filterProblems = getState().analytics.inactiveProblems.filter((problem) => problem.id == event.id)
            }
            else{
                filterProblems = getState().analytics.unknownProblems.filter((problem) => problem.id == event.id)
            }
        }
        else{
            return([])
        }
        
        dispatch({
            type: SET_PROPS,
            payload: {
                filterProblems
            }
        })
        }
}

export function resetProblemFilter() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                filterProblems : []
            }
        })
    }
}

export function setPatientDemo(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isPatientDemo : value
            }
        })
    }
}

export function setCurrentUser() {
    return (dispatch, getState) => {
        let loggedInUser;
        let userParam = getState().global && getState().global.loggedInUser
            && getState().global.loggedInUser.id ? getState().global.loggedInUser.id : null
        dispatch(toggleLoading(true))

        if (getState().analytics.isDemo){
            loggedInUser = getState().analytics && getState().analytics.presentPatient
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentUser: loggedInUser,
                }
            })
        }
        else if (userParam) {
            promisesList.push(axios.get(`${API_URL.USERS_URL}?id=${userParam}`).then((response) => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentUser: response.data,
                    }
                })
                dispatch(handleLoading())
            }).catch(error => {
                if (error.response.status == 403) {
                    getState().users.history.push('/statuscode/403')
                }
                else {
                    dispatch(handleErrors(error))
                }
            }))
        }    
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentUser: {}
                }
            })
        }
    }
}

export function setPrimaryMFA(mfaMode){
    return (dispatch, getState) => {
      let currentUser = getState().analytics && getState().analytics.currentUser ? getState().analytics.currentUser : {}  
      dispatch(toggleLoading(true))
      promisesList.push(axios.put(`${API_URL.SET_PRIMARY_MFA}?userId=${currentUser.id}&MFAMode=${mfaMode}`).then((response) => {
        if (response.data && response.data) {
            currentUser = getState().analytics && getState().analytics.currentUser ? getState().analytics.currentUser : {}
            currentUser.userMFA.map(mfa => {
                if (mfa.mfaMode == mfaMode){ mfa.isPrimary = 1 }
                else { mfa.isPrimary = 0 }
            })
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentUser: currentUser
                }
            })
            dispatch(toggleLoading(false))  
        } else {
          dispatch(handleLoading())
          dispatch(toggleLoading(false))
        }
      }).catch((error) => {
        dispatch(handleErrors(error))
      }))
    }
} 

export function removeRecommendationNotes(index, isMeta) {
    return (dispatch, getState) => {
        if (isMeta){
            let metaRecommendationForm = getState().analytics.metaRecommendationForm
            let recommendationNotes =  metaRecommendationForm.notes ? metaRecommendationForm.notes : []
            let newArray = recommendationNotes.filter((item, i) => i !== index);
            metaRecommendationForm = {...metaRecommendationForm,  notes: newArray }
            dispatch({
                type: SET_PROPS,
                payload: {
                    metaRecommendationForm
                }
            })
        }
        else {
            let recommendationForm = getState().analytics.recommendationForm
            let recommendationNotes =  recommendationForm.notes ? recommendationForm.notes : []
            let newArray = recommendationNotes.filter((item, i) => i !== index);
            recommendationForm = {...recommendationForm,  notes: newArray }
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationForm
                }
            })
        }    
    }
}
export function editRecommendationNotes(value, index, isMeta) {
    return (dispatch, getState) => {
        if(isMeta){
            let metaRecommendationForm = getState().analytics.metaRecommendationForm
            let recommendationNotes = metaRecommendationForm.notes ? metaRecommendationForm.notes : []
            // recommendationNotes = recommendationNotes.find((cf) => cf.index == index)
            recommendationNotes[index] = value
            metaRecommendationForm = {...getState().analytics.metaRecommendationForm,  notes: recommendationNotes }
            dispatch({
                type: SET_PROPS,
                payload: {
                    metaRecommendationForm
                }
            })
        }
        else{
            let recommendationForm = getState().analytics.recommendationForm
            let recommendationNotes = recommendationForm.notes ? recommendationForm.notes : []
            // recommendationNotes = recommendationNotes.find((cf) => cf.index == index)
            recommendationNotes[index] = value
            recommendationForm = {...getState().analytics.recommendationForm,  notes: recommendationNotes }
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationForm
                }
            })
        }
    }
}
export function addRecommendationNotes(value, isMeta) {
    return (dispatch, getState) => {
        if (isMeta) {
            let metaRecommendationForm = { ...getState().analytics.metaRecommendationForm}
            let recommendationNotes = metaRecommendationForm.notes ? metaRecommendationForm.notes : []
            recommendationNotes.push(value)
            metaRecommendationForm = {...metaRecommendationForm,  notes: recommendationNotes }
            dispatch({
                type: SET_PROPS,
                payload: {
                    metaRecommendationForm
                }
            })
        }
        else{    
            let recommendationForm = { ...getState().analytics.recommendationForm }
            let recommendationNotes = recommendationForm.notes ? recommendationForm.notes : []
            recommendationNotes.push(value)
            recommendationForm = {...recommendationForm,  notes: recommendationNotes }
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationForm
                }
            })
        }    

    }
}    

export function updateRecommendationForm(updatedRecommendation, isMeta){
    return (dispatch, getState) => {
        if (isMeta) {
            let metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, ...updatedRecommendation}
            dispatch({
                type: SET_PROPS,
                payload: {
                    metaRecommendationForm: metaRecommendationForm
                }
            })
        }
        else {
            let recommendationForm = { ...getState().analytics.recommendationForm, ...updatedRecommendation };
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationForm: recommendationForm
                }
            })
        }
    }
}

function activeRec(rec) {
    if (rec.endedAt && moment(Date()).format("YYYY-MM-DD") > moment(rec.endedAt).format("YYYY-MM-DD")) {
      return false
    }
    else {
      return true
    }
}

export function toggleRecommendationListModal(value) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let redOnlyRecommendations =  getState().analytics && getState().analytics.recommendations ? getState().analytics.recommendations : []
        redOnlyRecommendations = redOnlyRecommendations.filter(rec => activeRec(rec))
        dispatch({
            type: SET_PROPS,
            payload: {
                showRecommendationListModel: value,
                redOnlyRecommendations: redOnlyRecommendations,
            }
        })
    }
}    

export function downloadRecommendationList() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(toggleReportLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                isMedicationList: true
            }
        })
        let file_name = getFullName(getState().analytics.presentPatient)
        setTimeout(() => {
            if (document.getElementById('medicationList')) {
                var htmlMarkup = `<html>
                <head>
                <meta charset="utf-8">
                <link href='https://fonts.googleapis.com/css?family=Sarala' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Sarala:400,700' rel='stylesheet' type='text/css'>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
                <style>${medicationStyles}</style>
                </head><body>${document.getElementById('medicationList').outerHTML}</body></html>`
                dispatch(toggleLoading(true))
                let postUrl = API_URL.DOWNLOAD_VISIT_SUMMARY
                axios.post(postUrl,
                {
                    htmlMarkup,
                    fileName: '',
                    header:"",
                    visitId: 0,
                    document: '',
                    locale: '',
                    isMobile: false
                }).then(response => {
                    let siteLogo = getState().analytics && getState().analytics.siteData && getState().analytics.siteData.logoFileName && getState().analytics.siteData.logoFileName.length > 0 ? true : false;
                    if (window.innerWidth <= 768 || siteLogo || true) {
                        if (isIE()) {
                            let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                            if (window.navigator.msSaveBlob) {
                                window.navigator.msSaveOrOpenBlob(pdfblob, `medication_list_${file_name}.pdf`);
                            }
                        }
                        else {
                            const downloadLink = document.createElement('a');
                            if (!isFirefox()) {
                                let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                downloadLink.href = URL.createObjectURL(pdfblob)
                            } else {
                                downloadLink.href = 'data:application/pdf;base64,' + response.data
                            }
                            downloadLink.className = 'hidden';
                            document.body.appendChild(downloadLink);
                            downloadLink.setAttribute('download', `medication_list_${file_name}.pdf`);
                            downloadLink.click();
                            window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
                        }
                        dispatch(toggleLoading(false))
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                isSummaryLoading: false,
                                currentSummaryVisitId: null
                            }
                        })
                    }
                }).catch(error => {
                    dispatch(handleErrors(error));
                    dispatch(handleSummaryLoading(false))
                })
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isMedicationList: false
                    }
                })
                console.log(htmlMarkup)
            }
            else {
                console.log("Data not avilable")
                dispatch(toggleLoading(false))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isSummaryLoading: false,
                        currentSummaryVisitId: null,
                        isMedicationList: false
                    }
                })
            }
        },2000); 
    }
}


export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}

export const actionCreators = {
    setCurrentHealthAnalytics,
    providerDetails,
    getAllCategories,
    exitAnalytics,
    resetLoader,
    getTerms,
    // Analytics UI
    toggleProviderNotes,
    toggleNewNotes,
    getProviderNotes,
    updateProviderNotes,
    deleteProviderNotes,
    editProviderNotes,
    newProviderNotes,
    saveProviderNotes,
    toggleRecommendationModal,
    filterCategoryData,
    editRecommendation,
    showNoFormChangesError,
    getAllRecommendations,
    saveRecommendation,
    getRecommendations,
    updateRecommendations,
    setInitialRecommendationForm,
    deleteRecommendations,
    setBiomarkersOfAging,
    dataSelector,
    setDefaultMetaExpansions,
    setRecommendationType,
    toggleMetaRecommendations,
    deleteMetaRecommendation,
    updateMetaRecommendationCodes,
    setMetaRecordIndex,
    setMetaRecord,
    updateMetaRecommendations,
    newRecommendation,
    togglePages,
    setBreadCrumbs,
    isLeftNavOpenend,
    toggleRecommendationList,
    setFilterRecommendation,
    toggleBiomarkerComparePopup,
    toggleBioAgeOverview,
    clearParamsOfRecommendations,
    clearBioAgeOverviewData,
    setBiomarkerComparisonList,
    toggleStopRecommendationPopup,
    toggleHistoryRecommendationPopup,
    getHistoryRecommendations,
    toggleDeleteRecommendationPopup,
    getMedication,
    setRecType,
    filterHistoryRecommendations,
    setRecommendationSearchFilters,
    toggleCategoryOverview,
    toggleSectionOverview,
    toggleDatumOverview,
    round,
    savePriorities,
    toggleResetPriority,
    downloadPatientReport,
    handleDownloadVisitSummary,
    setVisitSummary,
    downloadVisitSummary,
    setDemoStatus,
    setCurrentPage,
    loadDemoData,
    toggleSurveyToaster,
    toggleSurveyPopup,
    submitAnalyticsSurveyForm,
    getAnalyticsSurveyData,
    editQuestionnairePageCount,
    editAnalyticsSurveyData,
    isPatientReportLoaded,
    addToTableOfContentsArray,
    setCurrentCategory,
    setCurrentSection,
    setCurrentNomenon,
    setActiveRecommendationToggle,
    setDrawerType,
    unMountingLoadingComponent,
    navigateHealthAnalytics,
    collapseAll,
    updateLoadingStatus,
    setNoumenonList,
    editNoumenonSearch,
    setSearchField,
    toggleNomenonDetails,
    setDefaultBiomarkerComparisonList,
    editRecommendationSearch,
    resetRecommendationFilter,
    getCustomRecommendation,
    getCustomSigs,
    toggleRecommendFormWarning,
    toggleTheme,
    toggleCategoryDetailsModal,
    setAllVisitData,
    getCustomCategories,
    toggleFeedbackModel,
    editFeedbackForm,
    submitFeedback,
    toggleProblemModal,
    newProblem,
    editProblem,
    getSnomedCtCode,
    toggleProblemFormWarning,
    showNoProblemFormChangesError,
    saveProblem,
    getProblems,
    setProblemType,
    updateProblem,
    setInitialProblemForm,
    toggleDeleteProblemPopup,
    deleteProblem,
    newProblemNotes,
    addProblemNotes,
    removeProblemNotes,
    setProblemNotes,
    editProblemSearch,
    resetProblemFilter,
    setCurrentUser,
    headerNavigation,
    setPrimaryMFA,
    setPatientDemo,
    addRecommendationNotes,
    editRecommendationNotes,
    removeRecommendationNotes,
    updateRecommendationForm,
    toggleRecommendationListModal,
    downloadRecommendationList

}