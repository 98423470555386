import './email-verification-style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react';
import FormControl from '../FormControls';
import Notification from '../Notification';
import CheckIcon from '@mui/icons-material/Check';
import Loader from '../Loader';
import labels from '../../config/localization';

const InterOpt = (props) => {
    let inputRef = useRef(null);
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []); 
  return (
    <div id="myModal" className="modal fade show" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pl-4">
            <h4 className="modal-title">{labels.user_labels.two_factor_auth_required}</h4>
            <button type="button" className="close" onClick={ () => props.onClose()}>
              &times;
            </button>
          </div>
          <div className="modal-body pl-2 pb-4">
              <div className='col-12 no-gutters pb-4'>
                <div className='label-1'> {labels.user_labels.send_6_digit_code}</div>
                <div className='label-2'>{props.email} </div>
              </div>

              <div className='col-12 no-gutters'>
                  {props.isNotify && props.notifications.title == 'VerifyOTP' && <div className="col-8"><Notification {...props.notifications} /> </div>}
                  <div>
                    <input
                        ref={inputRef}
                        autoComplete="on"
                        type='text'
                        className='form-control col-8'
                        id='otp'
                        placeholder={'Enter the code'}
                        value={props.otp} 
                        onChange={(e) => { props.setOTP(e.target.value); props.toggleNotification(false)}}
                        required={true}
                    />
                  </div>
                  {props.canResend ? (
                    <button type="button" className="btn link" onClick={() => props.resendOTP()}>
                      {labels.user_labels.resend_code}
                    </button>
                    ) : (  
                    <div className='btn link'>{`${labels.user_labels.resend_code_in} ${props.counter} ${labels.user_labels.seconds}`}</div>
                  )}
              </div>
          </div>
          <div className="modal-footer d-flex justify-content-end">
            <button type="button" className="btn btn-primary" onClick={() => props.onValidateOTP()}>
              {labels.user_labels.done}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Success = (props) => {
  return (
    <div id="myModal" className="modal fade show" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pl-4">
            {/* <button type="button" className="close" onClick={ () => props.onClose()}>
              &times;
            </button> */}
          </div>
          <div className="modal-body">
            <div className="pt-5 pb-5 col-12 no-gutters d-flex justify-content-center align-items-center">
                <div className='text-center'>
                    <CheckIcon sx={{
                          color: 'green',
                          fontSize: '50px',
                          paddingBottom: '10px'
                        }} />
                    <div className='label-3'>
                      {labels.user_labels.email_verification_successfully_done}
                    </div>  
                </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={() =>{ props.handleSuceessDone(); props.onDone()}}>
              {labels.user_labels.done}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmailVerification = (props) => {
  const [otp, setOTP] = useState('');
  const [email, setEmail] = useState('');
  const [counter, setCounter] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    // props.toggleNotification(false)
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    if (props.currentUser && props.currentUser.email){
      setEmail(props.currentUser.email)
    }
    else {  
      setEmail(storedUserData && storedUserData.userName && storedUserData.userName)
    }
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCanResend(true);
    }
    const keyPressHandler = (event) => handleKeyPress(event);
    document.addEventListener('keydown', keyPressHandler);
    return () => { clearTimeout(timer);
                  document.removeEventListener('keydown', keyPressHandler)
                }
  }, [counter, otp]);
  
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      onValidateOTP()
    }
  };

  const onValidateOTP = () => {
      props.validateOTP('email', otp, true, false)
  };

  const resendOTP = () => {
    props.sendOTP('email', email, false)
    setCounter(30);
    setCanResend(false);
  }



  return (
    <div className='verification'>
      {props.isLoading ? <Loader /> : null}
      { !props.verification && email && <InterOpt {...props} setOTP={setOTP} otp={otp} email={email} onValidateOTP={onValidateOTP} resendOTP={resendOTP} counter={counter} canResend={canResend} />}
      { props.verification && <Success {...props} />}
    </div>
  );
};

export default EmailVerification;
